import { ActiveFormFieldType, ActiveFormIonGroup } from '@/hooks/useActiveForm';
import { fieldGroup, fieldsUniter } from '../lib/field-group';

export const useCommon = () => fieldsUniter('common', [
  {
    key: 'debt_notification_threshold',
    field: 'debt_notification_threshold',
    type: ActiveFormFieldType.range,
    options: {
      min: 0,
      max: 1000000,
      withManualInput: true,
    },
    ionFieldGroup: fieldGroup('common') as ActiveFormIonGroup,
  },
]);
