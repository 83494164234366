import {
  computed, ref, Ref,
} from 'vue';
import { PretrialAutomationModel } from '../../@x/pretrial-automation-model';
import { useFields } from '../fields';
import { VoiceTabKey } from '../../@x/tabs';
import { VoiceSettingsActiveFormScheme } from '../../@x/forms';
import { VoiceSettingsModel } from '@/hooks/useCompanies';

const id = 'modal_automation_pretrial_voice_tab';

export const useVoiceTabs = (
  settingsModel: Ref<PretrialAutomationModel>,
  voiceModel: Record<VoiceTabKey, Ref<VoiceSettingsModel>>,
) => {
  const allFields = useFields(settingsModel, voiceModel);
  const activeTab = ref<VoiceTabKey>(allFields.value.chapters.voice.default);

  const tabs = ref([
    {
      id: `${id}_${VoiceTabKey.Informing}`,
      key: VoiceTabKey.Informing,
      label: 'Информирование',
    },
    {
      id: `${id}_${VoiceTabKey.PaymentControl}`,
      key: VoiceTabKey.PaymentControl,
      label: 'Контроль платежа',
    },
  ]);

  const tabFieldsMap = computed(() => {
    const tabFields = {} as Record<VoiceTabKey, VoiceSettingsActiveFormScheme>;

    for (const tab of tabs.value) {
      tabFields[tab.key] = allFields.value.chapters.voice.fields.filter((x) => x?.ionFieldGroup?.key === tab.key) as VoiceSettingsActiveFormScheme;
    }

    return tabFields;
  });

  const tabFields = computed(() => tabFieldsMap.value[activeTab.value]);

  return {
    activeTab,
    tabs,
    tabFields,
    tabFieldsMap,
  };
};
