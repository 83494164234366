import { useLocalI18n } from '@/hooks/useLocalI18n';
import { useInjectDebtorDialog } from '@/components/dialog/dialogs/debtor/useInjectDebtorDialog';
import { useDefaultCompanyPermissions } from '@/hooks/useDefaultCompanyPermissions';
import {
  computed, h, onBeforeUnmount, ref, useCssModule, watch,
} from 'vue';
import {
  Tenant, TenantInheritanceCase, TenantSource, useDebtors,
} from '@/hooks/useDebtors';
import { useProtectedInject } from '@/hooks/useProtectedInject';
import { IsIonicApp, PhoneNumberStatusComponentKey } from '@/symbols';
import {
  ActionType,
  ActiveTableAction, ActiveTableColumn, ActiveTableColumnFormat,
  ActiveTableColumnStick,
  useActiveTable,
} from '@/components/activeTable/useActiveTable';
import { parseDate } from '@/utils/dateFns';
import { ActiveFormFieldType } from '@/hooks/useActiveForm';
import { DictType, useDicts } from '@/hooks/useDicts';
import { useErrors } from '@/hooks/useErrors';
import { formatDate } from '@/utils/date';
import { getRandomString } from '@/utils/string';
import {
  ClientTenant,
  HasPhones,
  isPassportNumber,
  registrationOptions,
} from '@/components/dialog/dialogs/debtor/tabs/common/tabs/tenants/utils';
import { useTenantModelDialog } from '@/components/dialog/dialogs/debtor/tabs/common/tabs/tenants/useTenantModelDialog';
import { usePreventLeaveTrigger } from '@/hooks/usePreventLeave';
import TenantSourceIcon from '@/components/dialog/dialogs/debtor/tabs/common/tabs/tenants/TenantSourceIcon.vue';
import { ElTooltip } from 'element-plus';
import { parseDateStringWithDefaultFormat as pDSWDF } from '@/components/dateInputNext/utils';
import { IToastLevel, useToast } from '@/hooks/useToast';
import { RouterKey } from '@core/symbols';
import { IDialogComponent, useDialog } from '@/hooks/useDialog';
import { useDebtorTenantsSave } from '@/components/dialog/dialogs/debtor/tabs/common/useDebtorTenantsSave';
import { sortPhones } from '@/components/dialog/dialogs/debtor/tabs/common/tabs/main/utils';
import { getBankruptLabel } from '@/store/modules/debtors/icons/bankruptData';
import {
  isPersonDebtor,
} from '@/components/dialog/dialogs/debtor/isOrganization';

export const useTenantsTab = () => {
  const leaveController = usePreventLeaveTrigger();
  const isIonicApp = useProtectedInject(IsIonicApp);
  const $style = useCssModule();
  const { t } = useLocalI18n('debtor.common.tenants');
  const { debtor, productionType } = useInjectDebtorDialog();
  const { hasWritePermission } = useDefaultCompanyPermissions(productionType);
  const { closeDialogByComponent } = useDialog();
  const router = useProtectedInject(RouterKey);
  const { showToast, showPureSuccessToast, showPureDangerToast } = useToast();
  const { confirmDialog } = useDialog();

  const editingItem = ref<Tenant['id'] | null>(null);
  const toRemove = ref<Array<Tenant['id']>>([]);
  const { removeTenant } = useDebtors();
  const { showCreateTenantDialog, showUpdateTenantDialog } = useTenantModelDialog();
  const { save, remove } = useDebtorTenantsSave();
  const { getDict, getDictMap } = useDicts();
  const { errorsMap, clearErrors, setErrors } = useErrors<any>();
  const PhoneNumberStatusComponent = useProtectedInject(PhoneNumberStatusComponentKey);

  const initialColumns = ref<ActiveTableColumn<Tenant>[]>(
    ([
      {
        key: 'full_name',
        width: 1.5,
        stick: ActiveTableColumnStick.left,
        is_show: true,
        toggleVisibility() { this.is_show = !this.is_show; },
        getValue: (record: Tenant) => {
          return h('div', [h('div', {
            style: {
              display: 'flex', opacity: 0.7, marginBottom: '3px', gap: '3px',
            },
          }, (() => {
            const sources = (Object.entries(record.sources) || []).map(
              ([k, v]) => h(
                ElTooltip, {
                  popperClass: 'my-popper',
                  content: [
                    t(`sources.${k}`),
                    v && `(дата выгрузки: ${v})`,
                  ].filter(Boolean).join(' '),
                },
                h(v ? TenantSourceIcon : 'div', { source: k as keyof TenantSource }),
              ),
            );
            if (record.is_bankrupt !== null) {
              let content = 'Не является банкротом';
              if (record.is_bankrupt) {
                content = getBankruptLabel(record);
              }
              sources.push(
                h(
                  ElTooltip, { popperClass: 'my-popper' },
                  {
                    content: h('div', { style: 'text-align: left' }, content),
                    default: h(TenantSourceIcon, { source: 'bankrupt', class: $style[record.is_bankrupt ? '_red' : '_green'] }),
                  },
                ),
              );
            }
            if (record.inheritance_cases?.length > 0) {
              sources.push(h(
                ElTooltip, { popperClass: ['my-popper', 'popper-left'] },
                {
                  content: record.inheritance_cases.map(
                    (e: TenantInheritanceCase) => `Наследственное дело №${e.case_number}${e.death_date ? `, дата смерти ${pDSWDF(e.death_date)}` : ''}${e.notary_name ? `\nНотариус: ${e.notary_name}` : ''}`,
                  ).join('\n'),
                  default: h(TenantSourceIcon, { source: 'inheritance_cases', class: $style._red }),
                },
              ));
            }

            if (record.enrichment_sources?.fns) {
              sources.push(h(
                ElTooltip, { popperClass: ['my-popper'] },
                {
                  content: `${t('enrichment.source.fns')} (${record.enrichment_sources.fns})`,
                  default: h(TenantSourceIcon, { source: 'fns', class: $style._gray }),
                },
              ));
            }
            if (record.enrichment_sources?.efrsb) {
              sources.push(h(
                ElTooltip, { popperClass: ['my-popper'] },
                {
                  content: `${t('enrichment.source.efrsb')} (${record.enrichment_sources.efrsb})`,
                  default: h(TenantSourceIcon, { source: 'efrsb', class: $style._gray }),
                },
              ));
            }
            return sources;
          })()), record.full_name]);
        },
      },
      { is_show: true, key: 'birth_date', format: ActiveTableColumnFormat.date },
      { is_show: true, key: 'birth_place', width: 1.5 },
      { is_show: true, key: 'num_of_passport', width: 1.5 },
      {
        is_show: true, key: 'inn', width: 1.5, allowEmpty: true,
      },
      { is_show: true, key: 'snils', width: 1.5 },
      {
        key: 'phone_numbers',
        is_show: true,
        width: 2,
        getValue: (record: Tenant) => {
          return h('div', sortPhones(record.phone_numbers).map(
            // @ts-ignore untyped component
            ({ number, is_valid }) => h(PhoneNumberStatusComponent, { number, isValid: is_valid }),
          ));
        },
      },
      { is_show: true, key: 'id', width: 1.5 },
      { is_show: true, key: 'date_of_passport_issue', format: ActiveTableColumnFormat.date },
      { is_show: true, key: 'passport_issued_by', width: 2 },
      { is_show: true, key: 'code_division', width: 1.2 },
      { is_show: true, key: 'registration_address_st', width: 2 },
      {
        key: 'registration',
        is_show: true,
        width: 1.5,
        format: (value: number) => registrationOptions.value.find((v) => v.value === value)?.label ?? value,
      },
      {

        is_show: true, key: 'registration_date', width: 1.5, format: ActiveTableColumnFormat.date, allowEmpty: true,
      },
      {
        is_show: true, key: 'deregistration_date', width: 1.5, format: ActiveTableColumnFormat.date, allowEmpty: true,
      },
      {
        is_show: true, key: 'where_left', width: 1.2, allowEmpty: true,
      },
      // @ts-ignore
      productionType.value !== 'executive' && {

        is_show: true,
        key: 'relationships',
        width: 1.4,
        format: (
          value: string[]|null,
          // @ts-ignore
        ) => (value ? value.map((v) => relationshipsMap.value[v]).join(', ') : null),
      },
      {
        is_show: true,
        key: 'contact',
        width: 1.4,
      },
      {
        is_show: true, key: 'gender', width: 0.5, format: (value: any) => (value ? t(`gender.${value}`) : null),
      },
      { is_show: true, key: 'place_of_work', width: 1 },
      { is_show: true, key: 'position', width: 1 },
    ].filter(Boolean) as ActiveTableColumn<Tenant>[]).map((column) => ({
      width: 1,
      ...column,
      field: column.key,
      label: t(`column.${column.key}`),
    })),
  );

  const unsubs: Array<(() => void)> = [];
  onBeforeUnmount(() => {
    unsubs.forEach((unsub) => unsub());
    unsubs.splice(0, unsubs.length);
  });

  const saveOne = async (silent = false) => {
    if (!editingItem.value) {
      return;
    }
    clearErrors();
    const record = records.value.find(({ id }) => id === editingItem.value)!;
    const { errors, response: tenant } = await save(record, productionType.value, debtor.value!.debtor.pk);
    if (errors.length === 0) {
      record.id = tenant.id;
      await reset();
      updateTenants();
      if (!silent) {
        await showPureSuccessToast({
          duration: 1000,
          params: { message: 'Данные сохранены' },
        });
      }
    } else {
      setErrors(errors);
    }
    return errors;
  };

  function updateTenants(tenants?: Tenant[]) {
    if (isPersonDebtor(debtor.value)) {
      debtor.value._tenants = tenants ?? records.value;
    }
  }

  const add = async () => {
    const id = `new-${getRandomString()}`;
    const tenant = ({
      id,
      full_name: '',
      birth_date: null,
      birth_place: '',
      num_of_passport: '',
      inn: '',
      date_of_passport_issue: null,
      passport_issued_by: '',
      registration: null,
      registration_address_st: null,
      registration_date: null,
      deregistration_date: null,
      relationships: [] as any,
      contact: null,
      sources: [],
      phone_numbers: [],
    } as Pick<Tenant,
      'full_name'
      | 'birth_date'
      | 'birth_place'
      | 'registration_address_st'
      | 'num_of_passport'
      | 'inn'
      | 'date_of_passport_issue'
      | 'passport_issued_by'
      | 'registration'
      | 'registration_date'
      | 'relationships'
      | 'contact'
      | 'phone_numbers'
      > as Tenant);

    await saveOne();
    editingItem.value = id as unknown as Tenant['id'];
    records.value.push(tenant);
    return tenant;
  };

  const submit = async () => {
    const errors = await saveOne();
    if (errors?.length === 0) {
      await showToast({ label: 'Данные сохранены', level: IToastLevel.success, duration: 5000 });
      await router.push({ ...router.currentRoute.value, hash: '' });
      await closeDialogByComponent(IDialogComponent.debtor);
    }
  };

  const contactOptions = [
    { value: null, label: t('contact.none') },
    { value: 'owner', label: t('contact.owner') },
    { value: 'contact_person', label: t('contact.contact_person') },
  ];

  const relationshipsOptions = computed(() => (
    getDict(DictType.tenantRelationships).value
  ));

  const relationshipsMap = computed(() => (
    getDictMap(DictType.tenantRelationships).value
  ));

  const {
    columns,
    records,
    actions,
    fetchData: refetchTenants,
  } = useActiveTable<Tenant, Tenant, 'id'>({
    keyField: 'id',
    async fetch() {
      editingItem.value = null;
      toRemove.value = [];
      if (!debtor.value || !isPersonDebtor(debtor.value)) {
        return {
          count: 0,
          results: [],
        };
      }
      const useLocal = debtor.value._tenants?.length;
      const value = {
        count: debtor.value._tenants?.length ?? debtor.value.debtor_tenant_profiles.length ?? 0,
        results: useLocal ? debtor.value._tenants! : ([...debtor.value.debtor_tenant_profiles || []]).map((tenant) => ({
          ...tenant,
          ...([
            'birth_date',
            'date_of_passport_issue',
            'registration_date',
            'deregistration_date',
          ] as Array<keyof Tenant>).reduce((acc, cur) => ({
            ...acc,
            [cur]: parseDate(tenant[cur] as Tenant[keyof Tenant] & Date),
          }), {} as unknown as Tenant),
          relationships: tenant.relationships || null as any,
          contact: tenant.contact,
          num_of_passport: tenant.num_of_passport,
        })).sort((a, b) => (a.id > b.id ? 1 : -1)),
      };
      updateTenants(value.results);
      return value;
    },
    actions: computed(() => ((([
      !isIonicApp && hasWritePermission.value && {
        key: 'edit',
        icon: 'pencil',
        label: 'Редактировать',
        types: [ActionType.record],
        async handler({ selectedItems: [itemId] }) {
          await saveOne();
          editingItem.value = itemId;
        },
        check: ({ record: { id } }) => editingItem.value !== id,
      },
      !isIonicApp && hasWritePermission.value && {
        key: 'done',
        icon: 'check',
        label: 'Сохранить',
        types: [ActionType.record],
        async handler() {
          await saveOne();
        },
        check: ({ record: { id } }) => editingItem.value === id,
      },
      !isIonicApp && hasWritePermission.value && {
        key: 'remove',
        icon: 'close',
        label: 'Удалить',
        types: [ActionType.record],
        async handler({ selectedItems: [itemId] }) {
          const {
            result,
            closeDialog,
          } = await confirmDialog({
            title: t('actions.removeConfirmation'),
          });
          closeDialog();
          if (!result) {
            return;
          }
          const { status } = await remove({ id: itemId });
          if (!status) {
            return showPureDangerToast({ params: { message: 'Ошибка при удалении жильца' } });
          }
          await showPureSuccessToast({
            duration: 1000,
            params: { message: 'Жилец удален' },
          });
          const index = records.value.findIndex(({ id }) => id === itemId);
          records.value.splice(index, 1);
          await reset();
        },
        check: ({ record: { id } }) => editingItem.value !== id,
      },
      isIonicApp && hasWritePermission.value && {
        key: 'edit',
        icon: 'pencil',
        label: 'Редактировать',
        types: [ActionType.record],
        async handler({ selectedItems: [itemId] }) {
          const tenant = records.value.find((t) => t.id === itemId);
          if (!tenant) {
            return;
          }
          // @ts-ignore
          const result = await showUpdateTenantDialog(tenant);
          if (result.status) {
            const i = records.value.findIndex(({ id }) => id === result.response.id);
            if (i !== -1) {
              records.value.splice(i, 1, result.response);
            }
          }
        },
      },
      isIonicApp && hasWritePermission.value && {
        key: 'remove',
        icon: 'close',
        label: 'Удалить',
        types: [ActionType.record],
        async handler({ selectedItems: [itemId] }) {
          const response = await removeTenant({
            id: itemId,
          });
          if (response.status) {
            const i = records.value.findIndex(({ id }) => id === itemId);
            if (i === -1) {
              return;
            }
            records.value.splice(i, 1);
          }
        },
      },
      isIonicApp && hasWritePermission.value && {
        key: 'add',
        icon: 'add',
        label: 'Добавить',
        types: [ActionType.side],
        handler: async () => {
          const result = await showCreateTenantDialog();
          if (result.status) {
            const i = records.value.findIndex(({ id }) => id === result.response.id);
            if (i === -1) {
              records.value.push(result.response);
            }
          }
        },
      },
    ] as Array<ActiveTableAction<any, any> | boolean>)
      .filter(Boolean) as Array<ActiveTableAction<any, any>>)
      .map((action) => ({
        ...action,
        id: `modal_debtor_common_tab_tenants_tab_${action.key}_${action.types[0]}_action`,
      }) as ActiveTableAction<any, any>))),
    filters: computed(() => ([{
      key: 'id',
      field: 'id',
      type: ActiveFormFieldType.input,
      defaultValue: debtor.value?.debtor.pk,
    }, {
      key: 'production_type',
      field: 'production_type',
      type: ActiveFormFieldType.input,
      defaultValue: productionType.value,
    }])),
    columns: initialColumns,
  });

  const visibleColumns = computed(() => {
    return (columns?.value || []).filter((column) => column.is_show) || [];
  });

  const reset = async () => {
    leaveController.reset();
    editingItem.value = null;
    toRemove.value = [];
  };

  watch(debtor, () => {
    return refetchTenants();
  });

  const { showDialog } = useDialog();

  const showCustomDialog = async () => {
    await showDialog({
      component: IDialogComponent.draggableTableColumns,
      payload: {
        columns: initialColumns as unknown as any,
      },
      addInRoute: false,
    });
  };

  async function showEditPhonesDialog(item: ClientTenant) {
    if (typeof item.id === 'string' && item.id.startsWith('new')) {
      await saveOne(true);
    }
    return showDialog({
      component: IDialogComponent.phonesDialog,
      addInRoute: false,
      payload: {
        debtor: debtor.value,
        productionType: productionType.value,
        model: <HasPhones>item, // saved than id is a number
        showContactFields: false,
      },
    });
  }

  return {
    submit,
    reset,
    add,

    t,

    columns,
    initialColumns,
    visibleColumns,
    records,
    actions,
    editingItem,
    toRemove,
    registrationOptions,
    relationshipsOptions,
    contactOptions,
    errorsMap,
    refetchData: refetchTenants,
    hasWritePermission,
    leaveController,
    isPassportNumber,
    showEditPhonesDialog,
    showCustomDialog,
  };
};
