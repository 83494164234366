import { computed, Ref } from 'vue';
import { ActiveFormField, ActiveFormFieldType } from '@/hooks/useActiveForm';
import { useInjectDebtorDialog } from '@/components/dialog/dialogs/debtor/useInjectDebtorDialog';
import { useDefaultCompany } from '@/hooks/useDefaultCompany';
import {
  CourtCase,
  CourtCaseFilters,
  CourtTypeTabKey,
} from '@/components/dialog/dialogs/debtor/tabs/courts/types';
import { ListingRequestSource } from '@/store/modules/api';
import {
  isMagistrate,
} from '@/components/dialog/dialogs/debtor/tabs/courts/useTabs';
import {
  useContractualCourts,
} from '@/components/dialog/dialogs/debtor/tabs/courts/useContractualCourts';

export function useFilters(activeTab: Ref<CourtTypeTabKey>) {
  const { debtor } = useInjectDebtorDialog();
  const { companyId } = useDefaultCompany();
  const { contractualId } = useContractualCourts(debtor, activeTab);

  return { filters, params };

  function filters(archive: boolean) {
    return computed(() => {
      const filters: Array<ActiveFormField<CourtCaseFilters>> = [
        filter('__tab', activeTab.value),
        filter('similar_debtor', debtor.value?.debtor.pk),
        filter('company_id', companyId.value!),
        filter('court_type', {
          magistrate: 'mirsud',
          regional: 'rajsud',
          arbitration: 'arbitr',
        }[activeTab.value]),
        filter('has_period', archive),
        ...(contractualId?.value ? [filter('is_contractual_court', true)] : []),
      ];
      return filters;
    });
  }

  function params(archive: boolean): ListingRequestSource<CourtCase> {
    return {
      page: 1,
      limit: 100,
      filters: filters(archive).value.reduce((acc, item) => {
        acc[item.key as string] = item.defaultValue;
        return acc;
      }, {} as Record<string, any>),
    };
  }

}

function filter(key: string, value: any) {
  return {
    key,
    field: key,
    type: ActiveFormFieldType.input,
    defaultValue: value,
  };
}
