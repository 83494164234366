import { Company, VoiceSettingsModel, VoiceSettingsType } from '@/hooks/useCompanies';

export function createEmptyVoiceSettingsModel(company: Company['id'], type: VoiceSettingsType): VoiceSettingsModel {
  return ({
    company,
    type,
    notifications_without_penalties: null, // TODO: Нет в форме??
    consider_accruals: false,
    timezone_by_address: true,
    promised_payment_days: 0,
    notifications_by_period: true,
    debt_notifications_period_voice: null,
    weekday_period_from: null,
    weekday_period_to: null,
    weekend_or_holiday_period_from: null,
    weekend_or_holiday_period_to: null,
    auto_exclude_numbers: true,
    excluding_all_numbers_statuses: [],
    excluding_current_numbers_statuses: [],
    enabling_statuses: [],
    max_dialing_attempts: 0,
    dialing_redial_interval: 0,
    request_recall_dialing_redial_interval: 0,
  });
}
