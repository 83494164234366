import { ActiveFormIonGroup } from '@/hooks/useActiveForm';
import { AutoTabKey, VoiceTabKey } from '../@x/tabs';
import { PretrialActiveFormScheme, VoiceSettingsActiveFormScheme } from '../@x/forms';

export const fieldGroup = (group: AutoTabKey | VoiceTabKey | 'common'): Partial<ActiveFormIonGroup> => ({
  tag: 'fieldGroup',
  key: group.toString(),
});

export const fieldsUniter = <T extends PretrialActiveFormScheme | VoiceSettingsActiveFormScheme>(
  name: AutoTabKey | VoiceTabKey | 'common',
  fields: T,
  chapter?: VoiceTabKey,
): PretrialActiveFormScheme => fields.map((field) => ({
  ...field,
  ionFieldGroup: fieldGroup(chapter ?? name) as ActiveFormIonGroup,
})) as PretrialActiveFormScheme;
