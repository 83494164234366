import { useStore } from 'vuex';
import { computed } from 'vue';
import { ApiResponse, ListingRequestSource, ListingResponse } from '@/store/modules/api';
import { UserData } from '@/hooks/useUser';
import { Dict, DictType } from '@/hooks/useDicts';
import { ProductionType } from '@/hooks/useConstructor';
import { CompanyModuleType } from '@/pages/debtors/_module/useDebtorsActions';
import { PackageCompositeUpdateModel } from '@/types/datafile';
import { parseJSON } from '@/utils/json';
import { PeriodicTaskSchedule } from './useSchedule';

export type CompanyService = {
  name: string;
  id: number;
  is_default: boolean;
}

export type PenaltyKeyRateMode = 'k_rate'|'p_pay'|'court'|'p_pen'

export type Company = {
  id: number;
  module: CompanyModuleType;
  services: Array<CompanyService>;
  base_type: string;
  total_debtors: string;
  fin_services: string;
  balance: number;
  name_full: string;
  name_short: string;
  okopf: string;
  inn: string;
  kpp: string;
  ogrn: string;
  ogrn_data_reg: string;
  director: string;
  type: string;
  physical_address: string;
  legal_address: string;
  show_administrative_district_filter: boolean;
  send_joint_ownership_letters_separately: boolean;
  court_order_period_after_canceled_judgment: boolean;
  pochta_ru_send_to_legal_address: boolean;
  email: string;
  email_buh: string;
  email_cor: string;
  phone: string;
  fax: string;
  site: string;
  okpo: string;
  postal_address_zip_code: string;
  timezone: number;
  type_peni_calculation: '155'|'395';
  penalty_percentage: number;
  taxation_system: string;
  penalty_key_rate_mode: PenaltyKeyRateMode;
  representative_power_attorney: string;
  ras_schet: string;
  kor_schet: string;
  bic: string;
  full_name_bank: string;
  fns_full_info: Record<any, any>;
  dadata_raw: Record<any, any>;
  owner: number;
  enabled_production_types?: Array<ProductionType>;
  accrual_by_area?: boolean;
  day_accrual_by_area?: number|null;
  standartizated_physical_address: any;
  standartizated_legal_address: any;
  standartizated_postal_address_zip_code: any;
  is_demo: boolean;
  is_demo_ready: boolean;
  show_automatic_attachments: boolean;
} & {
  is_branch: boolean;
  name_branch?: string | null;
} & {
  name_with_branch: string;
}

export type Region = {
  id: number;
  code: string;
  name: string;
  full_name: string;
}

export enum CompanySettingsDischargeType {
  first = 'first'
}

export enum CompanySettingsSendType {
  send_to_court = 'send_to_court',
  send_to_email = 'send_to_email'
}

export type Nullable<T> = T | null;

export enum SmsOperator {
  Sendpulse = '320d53db-abc4-4035-81d0-56f99c481e85',
  SmsC = '6f76c469-be6c-44e7-a0c9-ce2b8b4f2962',
  Imobis = '89f58603-32d9-4bd1-b766-4bc6b8c6d0f4',
  SmsTraffic = '1d03b677-08ad-4711-a7fd-b5c0f4896e91',
  StreamTelecom = '4828211c-bda9-4ed3-88ef-598cdcd060e8',
  ProstorSms = 'c95ed702-0fad-475c-bdd8-2cb268e1cf4f',
}
export type DebtNotificationKey = 'sms' | 'voice' | 'email' | 'messengers';

export const UrrobotOperatorUuid = 'f3aec2b4-87d6-407b-9f59-e53a687b921b';
type UrrobotOperator = typeof UrrobotOperatorUuid | null;

export type CompanySettings = {
  id: number;
  active_esia_rolename: string;
  esia_rolenames: { short_rolename: string; display_text: string }[];

  company_logo_name: any;
  company_domain: string|null;

  // pretrial settings
  automation_enabled: boolean;
  debt_notification_threshold: number;
  debt_notification_failed_attempts: number;
  debt_notification_period: Nullable<number>;
  debt_notification_time_start: Nullable<number>;
  debt_notification_time_end: Nullable<number>;
  enabled_debt_notifications: Array<DebtNotificationKey>;
  debt_notifications: Array<DebtNotificationKey>;
  court_days_overdue: number;
  court_threshold_enabled: boolean;
  court_threshold: number;
  court_user: number|null;
  voice_send_sms_if_unavailable: boolean;
  max_dialing_attempts: number;
  dialing_redial_interval: number;
  request_recall_dialing_redial_interval: number;
  auto_create_sms_notifications: boolean;
  auto_create_voice_notifications: boolean;
  auto_create_email_notifications: boolean;
  voice_notifications_by_period: boolean;
  voice_timezone_by_address: boolean;
  voice_weekday_period_from: string;
  voice_weekday_period_to: string;
  voice_weekend_or_holiday_period_from: string;
  voice_weekend_or_holiday_period_to: string;
  voice_auto_exclude_numbers: boolean;
  voice_excluding_all_numbers_statuses: string[];
  voice_excluding_current_numbers_statuses: string[];
  voice_assigned_user: number;
  auto_court_transfer: boolean;
  actual_debt: boolean;
  kvint_campaign_id: string;
  transfer_to_operator_number: string;

  receiving_registration_adress_mvd: boolean;

  egrul_extract_optimization: boolean;

  debt_threshold: number;
  wait_days_before_court: number;
  wait_days_before_notify: number;
  notification_period: number;
  sms_enabled: boolean;
  voice_enabled: boolean;
  sms_priority: number;
  failed_attempts: number;
  notification_shedule_days: Record<any, any>;
  notification_shedule_wdays: Record<any, any>;
  sms_operator_uuid: SmsOperator | null;
  voice_operator_uuid: SmsOperator | UrrobotOperator;
  urrobot_voip_id?: string;

  viber_operator_uuid: SmsOperator | null;
  email_operator_uuid: string | null;

  kvint_id: Nullable<number>;
  timezone: number;
  sms_attempts: number;
  call_attempts: number;
  retries_count: number;
  auto_rosreestr_discharge: boolean;
  discharge_type: CompanySettingsDischargeType;
  discharge_periodic_month: number;
  max_discharges_per_day: number;
  amount_from: number;
  amount_to: number;
  notify_emails: string[];
  send_type: CompanySettingsSendType;
  need_rosreestr_discharge: true;
  amount_setting: true;
  payment_of_duties: true;
  auto_filing_claim: true;
  auto_change_status: true;
  auto_executive_transfer: true;
  rosreestr_amount_from: number;
  rosreestr_amount_to: number;
  debtor_data_registry_receiver_name: string;
  debtor_data_registry_payment_name: string;
  debtor_data_registry_columns_order: string[];
  company: number;
  default_region: number;

  employees?: any[];
  emails?: any[];
  rosreestr_characteristics?: boolean;
  rosreestr_movement?: boolean;
  crypto_pro_thumbprint?: string;
  show_administrative_district_filter: boolean;
  send_joint_ownership_letters_separately: boolean;
  court_order_period_after_canceled_judgment: boolean;

  // Настройка печати: Заявитель освобожден от уплаты пошлины в соответствии со ст. 333,35, 333,36, 333,37 НК РФ
  without_fee: boolean;
  // Настройка печати: Использовать факсимильную подпись и печать
  use_company_signature_and_stamp: boolean;

  auto_fees_discharge: boolean;
  need_fees_discharge: boolean;
  fees_amount_from: boolean;
  fees_amount_to: boolean;

  eds_certificate: string;
  eds_owner: string;
  eds_period: string;

  judicial_amount_from: string;
  judicial_amount_to: string;

  court_test: boolean;
  pochta_ru_use_urrobot: boolean;
  court_complaint_overdue: number|null;

  judicial_auto_update_debtor_data_config: PackageCompositeUpdateModel['config']|null;
  judicial_auto_update_debtor_data_arguments: PackageCompositeUpdateModel['arguments']|null;
  unknown_owner_rosreestr_movement: boolean;
  moratorium_enabled: boolean;
  enrichment_by_address: boolean;
  enrichment_by_address_estates: boolean;
  enrichment_by_filed_in_court: boolean;
  enrichment_by_existing_extract_from_egrn: boolean;
  debt_previous_rosreestr_movement: boolean;
  pp3_counter: number;
  send_electronic_court_decisions: boolean;
  send_electronic_court_decisions_notify_emails: string[];
  include_children: boolean;
  allow_without_court: boolean;
  short_penalty_calculation: boolean;
  show_automatic_attachments: boolean;
  auto_rosreestr_config: Record<any, any>;
  rosreestr_user: number;
} & FileCompanySettings;

export type FileCompanySettings = {
  company_logo: any;
  company_full_logo: any;
  company_auth_background: any;
  company_auth_logo: any;
}

export type FetchCompaniesResponse = ListingResponse<Company>;

export type FetchRegionsModel = ListingRequestSource<Region>

export type FetchRegionsResponse = ListingResponse<Region>;

export type FetchDefaultCompanyIdModel = {
  id: UserData['id'];
}

export type FetchDefaultCompanyIdResponse = {
  default_company: Company['id'];
}

export type SetDefaultCompanyIdModel = {
  id: UserData['id'];
  companyId: Company['id'];
}

export type SetDefaultCompanyIdResponse = {
  default_company: Company['id'];
}

export type FetchCompanyModel = {
  id: Company['id'];
}

export type FetchCompanyResponse = Company;

export type FetchCompanySettingsModel = {
  id: Company['id'];
}

export const enum VoiceSettingsType {
  Informing = 'informing',
  ControlOfPayment = 'control_of_payment'
}

export type FetchVoiceSettingsModel = {
  company_id: Company['id'];
  type: VoiceSettingsType;
}

export type CreateVoiceSettingsModel = {
  company_id: Company['id'];
  payload: Omit<VoiceSettingsModel, 'id'>;
};

export type UpdateVoiceSettingsModel = {
  company_id: Company['id'];
  id: Required<VoiceSettingsModel['id']>;
  payload: Omit<VoiceSettingsModel, 'id'>;
};

export type VoiceSettingsModel = {
  company: Company['id'];
  type: VoiceSettingsType;
  id?: number;
  notifications_without_penalties?: boolean | null;
  consider_accruals?: boolean;
  timezone_by_address?: boolean;
  promised_payment_days?: number;
  notifications_period_voice?: number;
  notifications_by_period?: boolean;
  debt_notifications_period_voice: PeriodicTaskSchedule | null;
  weekday_period_from?: string | null;
  weekday_period_to?: string | null;
  weekend_or_holiday_period_from?: string | null;
  weekend_or_holiday_period_to?: string | null;
  auto_exclude_numbers?: boolean;
  excluding_current_numbers_statuses?: string[];
  excluding_all_numbers_statuses?: string[];
  enabling_statuses?: string[];
  max_dialing_attempts?: number;
  dialing_redial_interval?: number;
  request_recall_dialing_redial_interval?: number;
  assigned_user?: number;
}

export type FetchCompanySettingsResponse = CompanySettings;

export type UpdateCompanySettingsModel = {
  id: Company['id'];
  payload: Partial<CompanySettings>;
}

export type UpdateFileCompanySettingsModel = {
  id: Company['id'];
  payload: Partial<FileCompanySettings>;
}

export type UpdateCompanySettingsResponse = CompanySettings;

export type UpdateFileCompanySettingsResponse = FileCompanySettings;

export type RemoveCompanyModel = {
  id: Company['id'];
}

export type RemoveCompanyResponse = null;

export type UpdateCompanyModel = {
  id: Company['id'];
  payload: Company;
}

export type UpdateCompanyResponse = Company;

export type AddCompanyModel = {
  inn: Company['inn'];
  kpp?: Company['kpp'];
}

export type AddCompanyResponse = {
  inn?: string[];
  detail?: string;
  non_field_errors?: string;
}

export const useCompanies = () => {
  const store = useStore();

  const companies = computed<Array<Company>>(() => (
    store.getters['companies/companies']
  ));
  const companiesMap = computed<Record<Company['id'], Company>>(
    () => Object.fromEntries(
      companies.value.map((c) => ([c.id, c])),
    ),
  );
  const getCompanyById = (id: Company['id']) => companiesMap.value[id];
  const getCompanyNameById = (id: Company['id']) => {
    const c = getCompanyById(id);
    if (!c) return 'NO_COMPANY';
    return c.name_short || c.name_full;
  };

  const fetchCompanies = async (
    request: ListingRequestSource<Company>,
  ): Promise<ApiResponse<ListingResponse<Company>>> => {
    const { status, response } = await store.dispatch('companies/fetchCompanies', request);

    return { status, response };
  };

  const fetchCompany = async (
    id: FetchCompanyModel['id'],
  ): Promise<ApiResponse<FetchCompanyResponse>> => {
    const { status, response } = await store.dispatch('companies/fetchCompany', { id });

    return { status, response };
  };

  const fetchCompanySettings = async (
    id: FetchCompanySettingsModel['id'],
  ): Promise<ApiResponse<FetchCompanySettingsResponse>> => {
    const { status, response } = await store.dispatch('companies/fetchCompanySettings', { id });

    return { status, response };
  };

  const fetchFileCompanySettings = async (
    id: FetchCompanySettingsModel['id'],
  ): Promise<ApiResponse<FetchCompanySettingsResponse>> => {
    const { status, response } = await store.dispatch('companies/fetchFileCompanySettings', { id });

    return { status, response };
  };

  const updateCompanySettings = async (
    model: UpdateCompanySettingsModel,
  ): Promise<ApiResponse<UpdateCompanySettingsResponse>> => {
    const { status, response } = await store.dispatch('companies/updateCompanySettings', {
      id: model.id,
      payload: {
        ...model.payload,
        ...(model.payload.judicial_auto_update_debtor_data_config
          ? { judicial_auto_update_debtor_data_config: model.payload.judicial_auto_update_debtor_data_config }
          : {}
        ),
        ...(model.payload.judicial_auto_update_debtor_data_arguments
          ? { judicial_auto_update_debtor_data_arguments: model.payload.judicial_auto_update_debtor_data_arguments }
          : {}
        ),
        ...(model.payload.send_electronic_court_decisions_notify_emails ? {
          send_electronic_court_decisions_notify_emails: [...model.payload.send_electronic_court_decisions_notify_emails].filter(Boolean),
        } : {}),
      },
    });

    return { status, response };
  };

  const updateFileCompanySettings = async (
    model: UpdateCompanySettingsModel,
  ): Promise<ApiResponse<UpdateFileCompanySettingsResponse>> => {
    const { status, response } = await store.dispatch('companies/updateFileCompanySettings', {
      id: model.id,
      payload: model.payload,
    });

    return { status, response };
  };

  const removeCompany = async (
    id: RemoveCompanyModel['id'],
  ): Promise<ApiResponse<RemoveCompanyResponse>> => {
    const { status, response } = await store.dispatch('companies/removeCompany', { id });

    return { status, response };
  };

  const updateCompany = async (
    model: UpdateCompanyModel,
  ): Promise<ApiResponse<UpdateCompanyResponse>> => {
    const { status, response } = await store.dispatch('companies/updateCompany', model);

    return { status, response };
  };

  const setDefaultCompanyId = async (
    companyId: Company['id'],
  ): Promise<ApiResponse<SetDefaultCompanyIdResponse>> => {
    const { status, response } = await store.dispatch('companies/setDefaultCompanyId', companyId);

    return { status, response };
  };

  const addCompany = async (payload: AddCompanyModel): Promise<ApiResponse<AddCompanyResponse>> => {
    const { status, response } = await store.dispatch('companies/addCompany', payload);

    return { status, response };
  };

  const fetchPositions = async (
    model: Company['id'],
  ): Promise<ApiResponse<Dict<DictType.employeePositions>>> => {
    const { status, response } = await store.dispatch('dicts/fetchEmployeePositions', model);

    return { status, response };
  };

  const fetchRegions = async (
    request: ListingRequestSource<Region>,
  ): Promise<ApiResponse<ListingResponse<Region>>> => {
    const { status, response } = await store.dispatch('companies/fetchRegions', request);

    return { status, response };
  };

  const fetchInns = async (
    model: { inn: string },
  ): Promise<ApiResponse<{
    value: string;
  }>> => {
    const { status, response } = await store.dispatch('dicts/fetchInns', model);

    return { status, response };
  };

  const fetchVoiceSettings = async (company_id: Company['id'], type: VoiceSettingsType): Promise<ApiResponse<ListingResponse<VoiceSettingsModel>>> => {
    const { status, response } = await store.dispatch('companies/fetchVoiceSettings', { company_id, type });

    return { status, response };
  };
  const createVoiceSettings = async (company_id: Company['id'], payload: VoiceSettingsModel): Promise<ApiResponse<VoiceSettingsModel>> => {
    const { status, response } = await store.dispatch('companies/createVoiceSettings', { company_id, payload });

    return { status, response };
  };
  const updateVoiceSettingsById = async (
    id: VoiceSettingsModel['id'],
    company_id: Company['id'],
    payload: VoiceSettingsModel,
  ): Promise<ApiResponse<VoiceSettingsModel>> => {
    const { status, response } = await store.dispatch('companies/updateVoiceSettingsById', { id, company_id, payload });

    return { status, response };
  };

  return {
    companies,
    companiesMap,
    getCompanyById,
    getCompanyNameById,
    fetchCompanies,
    setDefaultCompanyId,
    addCompany,
    fetchCompany,
    updateCompany,
    removeCompany,
    fetchPositions,
    fetchRegions,
    fetchCompanySettings,
    updateCompanySettings,
    fetchFileCompanySettings,
    updateFileCompanySettings,
    fetchInns,
    fetchVoiceSettings,
    createVoiceSettings,
    updateVoiceSettingsById,
  };
};
