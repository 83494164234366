import { Module, Plugin } from 'vuex';
import { Theme } from '@/hooks/useLayout';
import { StoreState } from '@/store';
import { IDialog, IDialogComponent } from '@/hooks/useDialog';
import { getRandomString } from '@/utils/string';
import { Signal, SignalType } from '@/hooks/useSignal';
import { IToast, IToastLevel } from '@/hooks/useToast';
import { ImportTypeKey, DebtorsDataTypeKey } from '@/pages/exchange/import/importTypes';
import { ApiCommand, ApiResponse, ListingResponse } from '@/store/modules/api';
import { UpdatesVersion } from '@/pages/updates/index.vue';
import { legacyApiPublicRequest } from '@core/service/publicService';

const DEFAULT_TOAST_DURATION = 4000;

export enum ImportFileTemplate {
  csv = 'csv',
  xls = 'xls',
  xlsx = 'xlsx',
}

export const VERSION = require('../../../package.json').version;

export type LayoutStateCustom = {
  logo: string|null;
  authLogo: string|null;
  authBackground: string|null;
  name: string|null;
};

const LAYOUT_CUSTOM_DEFAULT: LayoutStateCustom = {
  logo: require('@/components/logo/assets/defaultLogo.svg'),
  authLogo: require('@/components/logo/assets/defaultLogo.svg'),
  authBackground: require('@/components/authLayout/assets/authBackground.jpeg'),
  name: 'ЮРРОБОТ',
};

export type LayoutState = {
  theme: Theme;
  isSidebarExpanded: boolean;
  dialogs: Array<IDialog>;
  toasts: Array<IToast>;
  isPreloaderVisible: boolean;
  isUpdateAvailable: boolean;
  hasUpdates: boolean;
  customIsLoaded: boolean;
  custom: LayoutStateCustom | null;
  settings: {
    serviceCreationDate: Date;
    serviceVersion: string;
    exchangeImportEmail: string;
    importFilesTemplates: {
      [key in DebtorsDataTypeKey]: {
        [key in 'linear' | 'table']: {
          [key in ImportFileTemplate]: string
        }
      }
    };
  };
}

type LayoutModule = Module<LayoutState, StoreState>;

export const namespaced = true;

export const state: LayoutModule['state'] = () => ({
  theme: Theme.light,
  isSidebarExpanded: false,
  isPreloaderVisible: false,
  isUpdateAvailable: false,
  hasUpdates: false,
  customIsLoaded: false,
  custom: null,
  dialogs: [],
  toasts: [],

  settings: {
    serviceCreationDate: new Date(Date.UTC(2019, 0, 1)),
    serviceVersion: `${VERSION}`,
    exchangeImportEmail: 'loading@urrobot.tech',
    importFilesTemplates: {
      [ImportTypeKey.pretrial]: {
        linear: {
          xls: require('@/assets/files/import-templates/pretrial/linear/Шаблон файла реестра по загрузке должников в Досудебное производство.xls'),
          xlsx: require('@/assets/files/import-templates/pretrial/linear/Шаблон файла реестра по загрузке должников в Досудебное производство.xlsx'),
          csv: require('@/assets/files/import-templates/pretrial/linear/Шаблон файла реестра по загрузке должников в Досудебное производство.csv'),
        },
        table: {
          xls: require('@/assets/files/import-templates/pretrial/table/Шаблон файла реестра по загрузке должников в Досудебное производство (табличный).xls'),
          xlsx: require('@/assets/files/import-templates/pretrial/table/Шаблон файла реестра по загрузке должников в Досудебное производство (табличный).xlsx'),
          csv: require('@/assets/files/import-templates/pretrial/table/Шаблон файла реестра по загрузке должников в Досудебное производство (табличный).csv'),
        },
      },
      [ImportTypeKey.judicial]: {
        linear: {
          xls: require('@/assets/files/import-templates/judicial/linear/Шаблон файла реестра по загрузке должников в Судебное производство.xls'),
          xlsx: require('@/assets/files/import-templates/judicial/linear/Шаблон файла реестра по загрузке должников в Судебное производство.xlsx'),
          csv: require('@/assets/files/import-templates/judicial/linear/Шаблон файла реестра по загрузке должников в Судебное производство.csv'),
        },
        table: {
          xls: require('@/assets/files/import-templates/judicial/table/Шаблон файла реестра по загрузке должников в Судебное производство (табличный).xls'),
          xlsx: require('@/assets/files/import-templates/judicial/table/Шаблон файла реестра по загрузке должников в Судебное производство (табличный).xlsx'),
          csv: require('@/assets/files/import-templates/judicial/table/Шаблон файла реестра по загрузке должников в Судебное производство (табличный).csv'),
        },
      },
      [ImportTypeKey.executive]: {
        linear: {
          xls: require('@/assets/files/import-templates/executive/linear/Шаблон файла реестра по загрузке должников в Исполнительное производство.xls'),
          xlsx: require('@/assets/files/import-templates/executive/linear/Шаблон файла реестра по загрузке должников в Исполнительное производство.xlsx'),
          csv: require('@/assets/files/import-templates/executive/linear/Шаблон файла реестра по загрузке должников в Исполнительное производство.csv'),
        },
        table: {
          xls: require('@/assets/files/import-templates/executive/linear/Шаблон файла реестра по загрузке должников в Исполнительное производство.xls'),
          xlsx: require('@/assets/files/import-templates/executive/linear/Шаблон файла реестра по загрузке должников в Исполнительное производство.xlsx'),
          csv: require('@/assets/files/import-templates/executive/linear/Шаблон файла реестра по загрузке должников в Исполнительное производство.csv'),
        },
      },
    },
  },
});

export const getters: LayoutModule['getters'] = {
  theme: (state) => state.theme,
  isSidebarExpanded: (state) => state.isSidebarExpanded,
  isPreloaderVisible: (state) => state.isPreloaderVisible,
  settings: (state) => state.settings,
  dialogs: (state) => state.dialogs,
  toasts: (state) => state.toasts,
  toastsMap: (state) => Object.fromEntries(
    state.toasts.map((t) => ([t.id, t])),
  ),
  custom: (state) => ({
    ...LAYOUT_CUSTOM_DEFAULT,
    ...Object.fromEntries(
      Object.entries(state.custom || {}).filter(([_, value]) => !!value),
    ),
    hasCustomAuthBackground: state.custom?.authBackground ?? false,
  }),
};

export const mutations: LayoutModule['mutations'] = {
  setTheme: (state, theme: Theme) => {
    state.theme = theme;
  },
  setSidebarExpansion: (state, isExpanded: boolean) => {
    state.isSidebarExpanded = isExpanded;
  },
  setPreloaderVisibility: (state, isVisible: boolean) => {
    state.isPreloaderVisible = isVisible;
  },
  addDialog: (state, dialog: IDialog) => {
    state.dialogs.push(dialog);
  },
  removeDialogById: (state, dialogId: IDialog['id']) => {
    const index = state.dialogs.findIndex(({ id }) => dialogId === id);
    if (index === -1) {
      return;
    }
    state.dialogs.splice(
      index,
      1,
    );
  },
  removeDialogByComponent: (state, component: IDialogComponent) => {
    state.dialogs = state.dialogs.filter((dialog) => dialog.component !== component);
  },
  updateDialogParams: (state, dialog: IDialog) => {
    const found = state.dialogs.find((d) => d.component === dialog.component);
    if (found) {
      found.payload = dialog.payload;
    }
  },
  updateDialogById: (state, dialog: IDialog) => {
    const found = state.dialogs.find((d) => d.id === dialog.id);
    if (found) {
      found.payload = dialog.payload;
    }
  },
  // toasts are shown in reverse order, so the last one is by default in the top
  // if there is toasts with null duration they are always on top
  addToast: (state, toast: IToast) => {
    const permanent = state.toasts.filter((t) => !t.duration);
    const temporary = state.toasts.filter((t) => !!t.duration);
    // consider that error & warn toasts are only temporary
    let counter = 0;
    const filteredTemporary = temporary.reverse().filter((t) => {
      if ([IToastLevel.danger, IToastLevel.warning].includes(t.level)) {
        counter++;
      }
      return counter < 3;
    }).reverse();
    state.toasts = [...filteredTemporary, toast, ...permanent];
  },
  updateToastById: (state, toast: Partial<IToast> & { id: string }) => {
    const toastIndex = state.toasts.findIndex((t) => t.id === toast.id);
    if (toastIndex === -1) {
      console.log('toast not found');
      return;
    }
    Object.entries(toast).forEach(([key, value]) => {
      // @ts-ignore
      state.toasts[toastIndex][key] = value;
    });
  },
  removeToastById: (state, toastId: IToast['id']) => {
    const toastIndex = state.toasts.findIndex(({ id }) => toastId === id);
    if (toastIndex === -1) {
      console.log('toast not found');
      return;
    }
    state.toasts[toastIndex].onClose?.();
    state.toasts.splice(
      state.toasts.findIndex(({ id }) => toastId === id),
      1,
    );
  },
  setUpdateAvailable: (state, payload) => {
    state.isUpdateAvailable = payload;
  },
  signal: (state, payload: {signalType: SignalType; payload: Signal['payload']}) => {
    console.log('signal', payload);
  },
  setSidebar: (state, value) => {
    state.isSidebarExpanded = value;
  },
  setServiceVersion: (state, version: string) => {
    state.settings.serviceVersion = version;
  },
  setHasUpdates: (state, val: boolean) => {
    state.hasUpdates = val;
  },
  setCustomLayout: (state, value: LayoutStateCustom) => {
    state.customIsLoaded = true;
    state.custom = value;
  },
};

export const actions: LayoutModule['actions'] = {
  async initServiceVersion({ state, dispatch, commit }) {
    const versionsResponse = await dispatch('api/request', {
      command: ApiCommand.updatesGetList,
      params: {
        limit: 1,
      },
    }, { root: true }) as ApiResponse<ListingResponse<UpdatesVersion>>;

    if (!versionsResponse.status || !versionsResponse.response) {
      return;
    }
    const oldVersion = state.settings.serviceVersion;
    const version = versionsResponse.response.results[0]?.version_backend;
    if (version) {
      commit('setServiceVersion', version);
      if (oldVersion && version !== oldVersion) {
        commit('setHasUpdates', true);
      }
    }
  },
  async onUpdateAvailable({ commit }) {
    commit('setUpdateAvailable', true);
    commit('signal', {
      signalType: SignalType.updateAvailable,
    });
  },
  async setTheme({ commit }, theme: Theme) {
    commit('setTheme', theme);
  },
  async toggleSidebar({ commit, getters }) {
    commit('setSidebarExpansion', !getters.isSidebarExpanded);
  },
  async showDialog({ commit, dispatch }, dialog: IDialog) {
    const id = dialog.id || getRandomString();

    const payload = {
      addInRoute: true,
      ...dialog,
      id,
    } as IDialog;

    if (payload.addInRoute) {
      const { params } = payload;
      commit('signal', {
        signalType: SignalType.addDialogInRoute,
        payload: {
          component: payload.component,
          payload: payload.payload,
          params,
          ...(params?.preventCloseOnRouteChange ? { preventCloseOnRouteChange: true } : {}),
        },
      });
    } else {
      commit(
        'addDialog',
        {
          ...payload,
          addInRoute: false,
          isCloseable: dialog.isCloseable,
          ...(payload.params?.preventCloseOnRouteChange ? { preventCloseOnRouteChange: true } : {}),
        },
      );
    }

    const callback = () => {
      dispatch('closeDialogById', id);
    };
    callback.id = id;

    (document.activeElement as any)?.blur?.();

    return callback;
  },
  async closeDialogById({ commit, getters, state }, dialogId: IDialog['id']) {
    console.log('closeDialogById', dialogId);
    try {
      let dialogParams = (getters.dialogs as Array<IDialog>).find(
        ({ id }) => id === dialogId,
      );

      if (!dialogParams) {
        dialogParams = state.dialogs.find((x) => x.id === dialogId);
      }

      commit('signal', {
        signalType: SignalType.dialogClosed,
        payload: {
          ...(dialogParams || {}),
        },
      });
      commit('signal', {
        signalType: SignalType.removeDialogFromRoute,
        payload: dialogParams,
      });
    } catch (e) {
      console.warn('error on dialog close', (e as Error)?.message);
    }
    commit('removeDialogById', dialogId);
  },
  async closeDialogByComponent({ commit }, component: IDialogComponent) {
    commit('removeDialogByComponent', component);
  },
  async showToast({ commit, dispatch }, toast: IToast) {
    const id = toast.id || getRandomString();

    const payload = {
      duration: DEFAULT_TOAST_DURATION,
      ...toast,
      id,
    } as IToast;

    commit('addToast', payload);

    const callback = () => {
      dispatch('closeToastById', id);
    };
    callback.id = id;

    return callback;
  },
  async closeDialogs({ state, dispatch }) {
    return Promise.all(
      state.dialogs.map((dialog) => dispatch('closeDialogById', dialog.id)),
    );
  },
  async closeToastById({ commit }, toastId: IToast['id']) {
    commit('removeToastById', toastId);
  },
  async showPreloader({ commit }) {
    commit('setPreloaderVisibility', true);
  },
  async hidePreloader({ commit }) {
    commit('setPreloaderVisibility', false);
  },
  async closeToasts({ state, dispatch }) {
    return Promise.all(
      state.toasts.map((toast) => dispatch('closeToastById', toast.id)),
    );
  },
  async fetchCustomLayout({ commit }) {
    const testDomain = new URL(window.location.href).searchParams.get('test_domain');
    const domain = window.location.origin.replace(/http(s)?:\/\//, '');
    if (!testDomain && (domain.endsWith('app.urrobot.tech') || domain.endsWith('testapp.urrobot.tech'))) {
      commit('setCustomLayout', null);
      return;
    }

    const company_domain = (testDomain || domain).replaceAll('.', '$');

    const { status, response } = await legacyApiPublicRequest<{
      company_auth_logo: string;
      company_auth_background: string;
      company_logo: string;
      company_logo_name: string;
    }>({
      command: ApiCommand.publicCompanySettingsFetchByCompanyDomain,
      params: { company_domain },
    });

    const replaceDomain = (url: string) => {
      if (!url) {
        return url;
      }
      const domainsMap = {
        testapp: 'api-test',
        app: 'api-2',
        pilot: 'api-pilot',
        cl: 'api-cl',
      } as Record<string, string>;
      const match = url.match(/https?:\/\/(.+?)\./);
      if (!match || !match[1]) {
        return url;
      }
      const domain = match[1];
      const replacement = domainsMap[domain];
      if (!replacement) {
        return url;
      }
      return url.replace(domain, replacement);
    };

    if (status) {
      commit('setCustomLayout', {
        logo: replaceDomain(response.company_logo),
        authLogo: replaceDomain(response.company_auth_logo),
        name: replaceDomain(response.company_logo_name),
        authBackground: replaceDomain(response.company_auth_background),
      });
    } else {
      commit('setCustomLayout', null);
    }
  },
};

export const plugins: Array<Plugin<StoreState>> = [
  (store) => {
    store.watch((store) => store.user.token, (token) => {
      if (token) {
        store.dispatch('layout/initServiceVersion');
      }
    }, { immediate: true });
  },
];
