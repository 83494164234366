import { useLocalI18n } from '@/hooks/useLocalI18n';
import { PretrialActiveFormScheme, VoiceSettingsActiveFormScheme } from '../@x/forms';
import { ActiveFormIonGroup } from '@/hooks/useActiveForm';
import { Nullable } from '@/shared/lib';

export const labelMap = (
  field: PretrialActiveFormScheme[number] | VoiceSettingsActiveFormScheme[number],
  t: ReturnType<typeof useLocalI18n>['t'],
) => {
  if (field.type === 'label') {
    const result = {
      key: field.key,
      type: field.type,
      label: field.label ?? t(`label.${String(field.key)}`),
      style: field.style,
      ionFieldGroup: undefined as Nullable<ActiveFormIonGroup>,
    };

    if ('ionFieldGroup' in field) {
      result.ionFieldGroup = field.ionFieldGroup as ActiveFormIonGroup;
    }

    return result;
  }

  return field;
};
