import { useInjectDebtorDialog } from '@/components/dialog/dialogs/debtor/useInjectDebtorDialog';
import { useLocalI18n } from '@/hooks/useLocalI18n';
import { useDefaultCompany } from '@/hooks/useDefaultCompany';
import {
  DebtorCustomDocumentType,
  UploadHousebookDocumentsModel,
  UploadMyDocumentsModel,
  useDocumentsApi,
} from '@/hooks/useDocumentsApi';
import { ActiveTabKey, DocumentTab } from '@/components/dialog/dialogs/debtor/tabs/documents/types';
import {
  useEmptyDocumentTabs,
} from '@/components/dialog/dialogs/debtor/tabs/documents/useEmptyDocumentTabs';
import { IDialogComponent, useDialog, useDialogRouteParam } from '@/hooks/useDialog';
import {
  computed, onBeforeUnmount, onMounted, ref, watch,
} from 'vue';
import { mbToBites, useFileManager } from '@/hooks/useFileManager';
import {
  ActiveTableColumn,
  ActiveTableColumnFormat,
  useActiveTable,
} from '@/components/activeTable/useActiveTable';
import { getAuthProtectedFileUrl } from '@/utils/fileUrl';
import { ActiveFormField, ActiveFormFieldType } from '@/hooks/useActiveForm';
import { useUnsubs } from '@/hooks/useUnsubs';
import { SignalType, useSignal } from '@/hooks/useSignal';
import { absurd } from '@/utils/function';
import { DictType, useDicts } from '@/hooks/useDicts';
import { formatMoney } from '@/utils/string';
import { useDocumentsColumns } from '@/components/dialog/dialogs/debtor/tabs/documents/tableColumns';
import {
  activeTabRemoveDocumentApiCommandMap,
  useActions,
} from '@/components/dialog/dialogs/debtor/tabs/documents/tableActions';
import {
  useDocsCommonRequests,
} from '@/components/dialog/dialogs/debtor/tabs/documents/common-requests';
import {
  useFetchEstateOwners,
} from '@/components/dialog/dialogs/debtor/tabs/documents/useFetchEstateOwners';
import {
  useDebtorCustomDocuments,
} from '@/components/dialog/dialogs/debtor/tabs/documents/useDebtorCustomDocuments';
import { isNumber } from '@/utils/number';
import {
  useDebtorDefaultDocuments,
} from '@/components/dialog/dialogs/debtor/tabs/documents/useDebtorDefaultDocuments';
import { sortBy } from 'lodash';
import { useCustomLocales } from '@/components/dialog/dialogs/debtor/useCustomLocales';

export const useDebtorDocumentsTab = () => {
  const { companyModuleType, companyId } = useDefaultCompany();
  const { debtor, debtorId, productionType } = useInjectDebtorDialog();
  const { t } = useLocalI18n('debtor.documents');
  const { dispatchSignal, subscribeToSignal } = useSignal();
  const isLoading = ref<boolean>(false);
  const { fetchEstateOwners } = useFetchEstateOwners();
  const {
    fetchDefaultDocTypes,
    defaultTabs,
  } = useDebtorDefaultDocuments(productionType, companyId);
  const {
    fetchCustomDocTypes,
    customTabs,
    customTabKeysReqs,
  } = useDebtorCustomDocuments(productionType, companyId);
  const tabs = computed<Array<DocumentTab>>(() => sortBy(defaultTabs.value.concat(customTabs.value), ['order_number']));
  const tabsWithDocuments = useEmptyDocumentTabs(tabs, customTabKeysReqs);

  const [activeTab] = useDialogRouteParam<ActiveTabKey>(
    'documents-tab',
    'common',
    ref(true),
  );

  const apiEndpoints = useDocumentsApi();

  const {
    files,
    selectFiles,
  } = useFileManager({
    multiple: true,
    accept: ['application/pdf'],
    maxSize: mbToBites(30),
  });

  const requests = useDocsCommonRequests();

  const { locales, fetchLocales } = useCustomLocales();
  const judgementDecisions = computed<Record<string, string>>(() => locales.value.ru['debtor.court_decision.decisions']);

  onMounted(async () => {
    isLoading.value = true;
    await fetchDefaultDocTypes();
    await fetchCustomDocTypes();
    await fetchLocales();
    isLoading.value = false;
  });

  const { showDialog } = useDialog();

  const showDebtorCustomTypesDialog = async () => {
    await showDialog({
      component: IDialogComponent.debtorDocTypes,
      payload: {
        productionType: productionType.value,
      },
      addInRoute: false,
    });
  };

  const {
    records,
    columns,
    actions,
    fetchData: refetchDocuments,
    limit,
    page,
    total,
  } = useActiveTable<any & {id: 'id'}, any, 'id'>({

    name: 'debtor-documents',
    keyField: 'id',
    resetOnQuery: true,
    defaultLimit: ref(15),
    columns: useDocumentsColumns(activeTab, debtor),

    actions: useActions(
      activeTab,
      getRecords,
      companyId,
      getRefetchDocuments,
      t,
      selectFiles,
      computed(() => locales.value),
    ),

    async fetch({ params, signal }) {
      if (!debtor.value) {
        return {
          count: 0,
          results: [],
        };
      }
      isLoading.value = true;

      if (records?.value?.length) {
        records.value = [];
      }

      // если что-то тут редактируешь - внеси аналогичные правки в useEmptyDocumentTabs.ts
      const { response } = await ((({
        ...requests,
        ...customTabKeysReqs.value,
        egrn_data: apiEndpoints.fetchEgrnData,
        egrn_rights: apiEndpoints.fetchEgrnRights,
        fee: apiEndpoints.fetchFeePayments,
        protocol_ooc: (params: any) => apiEndpoints.fetchUniqueDebtorsDocuments(
          { ...params, filters: { ...params.filters, key: 'protocol_ooc' }, signal },
        ),
        preloaded_charges: (params: any) => apiEndpoints.fetchUniqueDebtorsDocuments(
          { ...params, filters: { ...params.filters, key: 'preloaded_charges' }, signal },
        ),
        preloaded_penny: (params: any) => apiEndpoints.fetchUniqueDebtorsDocuments(
          { ...params, filters: { ...params.filters, key: 'preloaded_penny' }, signal },
        ),
        guarantee_letter: (params: any) => apiEndpoints.fetchUniqueDebtorsDocuments(
          { ...params, filters: { ...params.filters, key: 'guarantee_letter' }, signal },
        ),
        act: (params: any) => apiEndpoints.fetchUniqueDebtorsDocuments(
          { ...params, filters: { ...params.filters, key: 'act' }, signal },
        ),
        agreements: apiEndpoints.fetchDebtorAgreements,
        judgments: (params: any) => {
          const { production_type, ...filters } = params.filters;
          return apiEndpoints.fetchJudgments(
            { ...params, filters, signal },
          );
        },
        pochta: (params: any) => {
          const { filters, ...restParams } = params;
          const { production_type, ...restFilters } = filters;
          return apiEndpoints.fetchDebtorPochtaTracks({
            ...restParams,
            filters: restFilters,
            signal,
          });
        },
        my_documents: apiEndpoints.fetchMyDocuments,
        invoice: (params: any) => apiEndpoints.fetchUniqueDebtorsDocuments(
          { ...params, filters: { ...params.filters, key: 'invoice' }, signal },
        ),
        returnGP: (params: any) => apiEndpoints.fetchUniqueDebtorsDocuments(
          { ...params, filters: { ...params.filters, key: 'returnGP' }, signal },
        ),
        settlement_statements: (params: any) => apiEndpoints.fetchUniqueDebtorsDocuments(
          { ...params, filters: { ...params.filters, key: 'settlement_statements' }, signal },
        ),
        technical_passport: (params: any) => apiEndpoints.fetchUniqueDebtorsDocuments(
          { ...params, filters: { ...params.filters, key: 'technical_passport' }, signal },
        ),
        inspection_report: (params: any) => apiEndpoints.fetchUniqueDebtorsDocuments(
          { ...params, filters: { ...params.filters, key: 'inspection_report' }, signal },
        ),
      } as {
        [key in ActiveTabKey]: any
        // eslint-disable-next-line no-underscore-dangle
      })[params.filters!.__tab as ActiveTabKey])({ ...params, signal }));
      isLoading.value = false;
      return response;
    },

    filters: computed(() => (([
      {
        key: '__tab',
        field: '__tab',
        type: ActiveFormFieldType.input,
        defaultValue: activeTab.value,
      },
      {
        key: 'debtor_id',
        field: 'debtor_id',
        type: ActiveFormFieldType.input,
        defaultValue: debtor.value?.debtor.pk,
      },
      {
        key: 'company_id',
        field: 'company_id',
        type: ActiveFormFieldType.input,
        defaultValue: companyId.value,
      },
      !isNumber(activeTab.value) && {
        key: 'debtor_ids',
        field: 'debtor_ids',
        type: ActiveFormFieldType.input,
        defaultValue: [debtor.value?.debtor.pk],
      },
      (['common', 'pochta', 'judgments', 'fee'].includes(activeTab.value) || isNumber(activeTab.value)) && {
        key: 'production_type',
        field: 'production_type',
        type: ActiveFormFieldType.input,
        defaultValue: productionType.value,
      },
    ] as Array<ActiveFormField<any> | boolean>)
      .filter(Boolean) as Array<ActiveFormField<any>>)),

  });

  function getRefetchDocuments() { return refetchDocuments; }
  function getRecords() { return records; }

  const { sub, unsub } = useUnsubs();

  sub(subscribeToSignal(SignalType.debtorPochtaTracksUpdated, refetchDocuments));
  sub(subscribeToSignal(SignalType.debtorCustomTypesUpdated, async () => {
    await fetchDefaultDocTypes();
    await fetchCustomDocTypes();
  }));

  watch(files, async (newFiles) => {
    if (!debtor.value) {
      return;
    }
    let endpoint: any;
    if (activeTab.value === 'my_documents') {
      endpoint = apiEndpoints.uploadMyDocument;
    } else if (['protocol_ooc', 'guarantee_letter', 'act', 'invoice', 'settlement_statements', 'technical_passport', 'inspection_report'].includes(activeTab.value)) {
      type UniqueDocumentTab = 'protocol_ooc'|'guarantee_letter'|'act'|'invoice'|'settlement_statements'|'technical_passport'|'inspection_report';

      const tab = activeTab.value as UniqueDocumentTab;

      endpoint = async (model: any) => {
        // т.к. документ уникальный, то перед загрузкой удаляем предыдущий
        if (records.value.length) {
          await apiEndpoints.removeDocument(
            activeTabRemoveDocumentApiCommandMap[tab],
            { id: records.value[0].id },
          );
        }
        await apiEndpoints.uploadUniqueDebtorsDocument(
          { ...model, key: activeTab.value, company: companyId.value },
        );
      };
    } else if (isNumber(activeTab.value)) {
      endpoint = async (model: any) => {
        if (records.value.length) {
          await apiEndpoints.deleteDebtorCustomDocument(records.value[0].id);
        }
        await apiEndpoints.uploadDebtorCustomDocument({ ...model, type: activeTab.value, company: companyId.value });
      };
    } else {
      endpoint = apiEndpoints.uploadHousebookDocument;
    }

    await Promise.all(
      newFiles.map((file) => endpoint({
        debtor: debtor.value!.debtor.pk,
        production_type: productionType.value,
        file,
        estate_object: null,
        ...(activeTab.value === 'housebook' ? {
          document_formation_date: (new Date()).toISOString(),
          can_be_attached: true,
          company: companyId.value,
        } : {}),
        ...(isNumber(activeTab.value) ? {
          type: activeTab.value,
        } : {}),
      } as UploadMyDocumentsModel & UploadHousebookDocumentsModel)),
    );
    await refetchDocuments();
    await dispatchSignal(SignalType.debtorDocumentsUpdated);
  }, {
    deep: true,
  });

  const {
    getDictMap,
  } = useDicts();

  const substatusesMap = computed(() => (
    getDictMap(DictType.debtorSubstatuses).value
  ));

  const getPochtaType = (
    { is_electronic, is_ordered }: { is_electronic: boolean; is_ordered: boolean },
  ) => [
    is_electronic ? 'Электронное' : 'Бумажное',
    is_ordered ? 'Заказное' : 'Простое',
  ].join('/');

  const extraAgreementsTable = useActiveTable<any, any, 'number'>({
    keyField: 'number',
    resetOnQuery: true,
    defaultLimit: ref(1000),
    defaultPage: ref(1),
    async fetch({ params, signal }) {
      return {
        count: 0,
        results: [],
      };
    },
    columns: computed(() => (([
      {
        key: 'number',
      },
      {
        key: 'created_at',
        format: ActiveTableColumnFormat.date,
      },
      {
        key: 'valid_until',
        format: ActiveTableColumnFormat.date,
      },
      {
        key: 'amount',
        format: ActiveTableColumnFormat.money,
      },
    ]) as ActiveTableColumn<any>[]).map((column) => ({
      ...column,
      field: column.field || column.key,
      label: t(`extra_agreements_column.${String(column.key).split('.').pop()}`),
    }))),
    filters: computed(() => ([])),
  });
  watch(debtor, (d) => {
    if (d) {
      extraAgreementsTable.fetchData();
    }
  }, { immediate: true });

  watch(records, async (value) => {
    if (activeTab.value === 'fee' && value.length) {
      const results = await fetchEstateOwners(debtorId);
      records.value.forEach((record) => {
        const owner = results.find((estate: { id: number }) => estate.id === record.estate_object);
        record.estate_owner_name = owner?.owner_name;
      });
    }
    if (activeTab.value === 'pochta' && value.length) {
      const results = await fetchEstateOwners(debtorId);
      records.value.forEach((record) => {
        const owner = results.find((estate: { id: number }) => estate.id === record.estate_object);
        record.estate_object = owner?.owner_name;
      });
    }
  });

  const extraAgreementRecords = computed(() => {
    if (activeTab.value !== 'agreements') return [];
    return records.value?.[0]?.extra_agreements;
  });

  onBeforeUnmount(
    subscribeToSignal(SignalType.debtorAgreementsUpdated, refetchDocuments),
  );

  const downloadFile = async (value: string) => {
    const fileUrl = await getAuthProtectedFileUrl(value, true);
    if (!fileUrl) {
      return;
    }
    window.open(fileUrl, '_blank');
  };

  return {
    substatusesMap,
    t,
    isLoading,
    activeTab,
    tabs,
    records,
    columns,
    actions,
    limit,
    page,
    total,
    judgementDecisions,
    companyModuleType,
    getPochtaType,
    formatMoney,
    extraAgreementColumns: extraAgreementsTable.columns,
    extraAgreementRecords,
    tabsWithDocuments,
    downloadFile,
    showDebtorCustomTypesDialog,
  };
};
