import { ref } from 'vue';
import { useDefaultCompany } from '@/hooks/useDefaultCompany';
import { useInjectDebtorDialog } from '../../useInjectDebtorDialog';
import { useDocumentsApi } from '@/hooks/useDocumentsApi';

export const useGis = () => {
  const apiEndpoints = useDocumentsApi();
  const { debtorId } = useInjectDebtorDialog();
  const { companyId } = useDefaultCompany();

  const isGisLoading = ref(false);
  const gisData = ref<any>(null);

  function requestGis() {
    return apiEndpoints
      .fetchUniqueDebtorsDocuments(
        {
          limit: 15,
          page: 1,
          filters: {
            debtor_id: debtorId.value,
            debtor_ids: [debtorId.value],
            company_id: companyId.value,
            key: 'gis',
          },
        },
      )
      .then((resp) => {
        gisData.value = resp.response?.results;
      })
      .finally(() => { isGisLoading.value = false; });
  }

  return {
    isGisLoading,
    requestGis,
    gisData,
  };
};
