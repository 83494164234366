import { ITextInput } from '@/components/textInput/useTextInput';
import { ISelectInput } from '@/components/selectInput/useSelectInput';
import { ICheckbox, ICheckboxState, ICheckboxValue } from '@/components/checkbox/useCheckbox';
import { IRange } from '@/hooks/useRange';
import { IDateRangeProps } from '@/components/dateRangeInput/DateRangeInput.vue';
import { ComputedRef, VNode } from 'vue';
import { ActiveTableColumnFormat } from '@/components/activeTable/useActiveTable';
import { ActiveFormFieldScheduleOptions } from '@/components/scheduleSelector/utils';
import { ICascaderProps } from '@/hooks/useCascader';
import { IDateInput } from '@/hooks/useDateInput';

export enum ActiveFormFieldType {
  input = 'input',
  select = 'select',
  searchSelect = 'searchSelect',
  date = 'date',
  dateRange = 'dateRange',
  checkbox = 'checkbox',
  radio = 'radio',
  range = 'range',
  file = 'file',
  schedule = 'schedule',
  cascader = 'cascader',
}

export type IRadio = {
  modelValue?: ICheckboxValue;
  options: { label: string; value: any }[];
  state?: ICheckboxState | Array<ICheckboxState>;
  label?: string;
  errors?: string[];
}
export type ActiveFormIonFieldGroup = {
  tag: 'fieldGroup';
  key: string;
  label?: string;
  groupOrder?: number;
  order?: number;
  state?: string|string[];
};

export type ActiveFormIonListGroup = {
  tag: 'listGroup';
  key: string;
  label?: string;
  groupOrder?: number;
  order?: number;
  renderReadonly?: (value: any) => any;
  getItemLabel?: (value: any, index: number) => string;
  isDeletable: boolean;
  isAddable: boolean;
  withInitValue?: boolean;
  state?: string|string[];
  isReadonly?: boolean;
}

export type ActiveFieldIonOptions = {
  ignorePlaceholder?: boolean;
  labelPosition?: 'floating'|'stacked'|'fiex';
  withErrors?: boolean;
  renderValue?: (value: any) => VNode;
}

export type ActiveFormIonGroup = ActiveFormIonFieldGroup|ActiveFormIonListGroup;

export const isActiveFormIonGroup = (
  g: any,
): g is ActiveFormIonGroup => g.tag === 'fieldGroup' || g.tag === 'listGroup';

export const isActiveFormIonFieldGroup = (
  g: ActiveFormIonGroup,
): g is ActiveFormIonFieldGroup => g.tag === 'fieldGroup';

export const isActiveFormIonListGroup = (
  g: ActiveFormIonGroup,
): g is ActiveFormIonListGroup => g.tag === 'listGroup';

type StateOption = 'primary' | 'horizontal' | string;
type StateOptions = StateOption | StateOption[];

export type ActiveFormField<T extends Record<any, any>> = {
  key: string | keyof T;
  field: string | keyof T;
  type: ActiveFormFieldType;
  label?: string|false;
  renderValue?: (payload: any) => any;
  format?: ActiveTableColumnFormat;
  options?: Record<any, any>;
  defaultValue?: any;
  isVisible?: boolean;
  checkHidden?: (model: any) => boolean;
  width?: number;
  isReadonly?: boolean;
  blockedBy?: string[];
  ionFieldGroup?: ActiveFormIonGroup;
  ionOptions?: ActiveFieldIonOptions;
  isDropDownTripleSelect?: boolean;
  stickBottom?: boolean;
  state?: StateOptions | ComputedRef<StateOptions>;
  hint?: string|((model: any, keyPath: string[]) => string);
  onUpdateModelValue?: (model: any, $event: any) => void;
  isResettable?: boolean;
  id?: string;
} & ({
  type: ActiveFormFieldType.input;
  options?: Partial<ITextInput>;
  defaultValue?: string | number;
} | {
  type: ActiveFormFieldType.select;
  options?: Partial<ISelectInput<any>>;
  onQuery?(payload: {query: string}): Promise<void>;
  defaultValue?: string | number | Array<string | number>;
} | {
  type: ActiveFormFieldType.searchSelect;
  options?: Partial<ISelectInput<any>>;
} | {
  type: ActiveFormFieldType.date;
  options?: Partial<IDateInput>;
} | {
  type: ActiveFormFieldType.checkbox;
  options?: Partial<ICheckbox>;
  defaultValue?: false;
} | {
  type: ActiveFormFieldType.radio;
  options?: Partial<IRadio>;
  defaultValue?: false;
} | {
  type: ActiveFormFieldType.range;
  options?: Partial<IRange>;
  defaultValue?: null | [null, null];
} | {
  type: ActiveFormFieldType.file;
} | {
  type: ActiveFormFieldType.schedule;
  options?: ActiveFormFieldScheduleOptions;
} | {
  type: ActiveFormFieldType.dateRange;
  defaultValue?: [Date, Date];
  options?: Omit<IDateRangeProps, 'modelValue'>;
} | {
  type: ActiveFormFieldType.cascader;
  defaultValue?: string | string[];
  options?: Partial<ICascaderProps>;
})

export type IActiveForm<T extends Record<any, any>> = {
  fields: Array<ActiveFormField<T>>;
}

export type ActiveFormModel<T extends Record<any, any>> = {
  [key in ActiveFormField<T>['key']]: ActiveFormField<T>['defaultValue'] | null;
}
export type IRadioOption = { label: string; value: string | boolean }

export type BuyServiceDialogModel = {
  rateId: number;
  amount: number;
  auto_renewal: boolean;
  // days: number;
}

export type BuyLicenseDialogModel = {
  licenseId: number | null;
  amount: any;
}
