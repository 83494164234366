import { useInjectDebtorDialog } from '../../useInjectDebtorDialog';
import { DocumentDto, UpdateModel, useFeeReturnCertificateApi } from './useFeeReturnCertificateApi';
import { ActiveFormFieldType } from '@/hooks/useActiveForm';
import { mbToBites } from '@/hooks/useFileManager';
import { useModelDialog } from '@/hooks/useModelDialog';
import { ErrorsMap } from '@/hooks/useErrors';
import { ApiResponseError, ApiResponseSuccess } from '@/service/api';

type Options = {
  create: string;
  edit: string;
}

export const useFeeReturnCertificate = (options: Options) => {
  const { debtor, productionType } = useInjectDebtorDialog();
  const { createFeeReturnCertificate, updateFeeReturnCertificate, fetchFeeReturnCertificate } = useFeeReturnCertificateApi();

  const fields = [
    {
      key: 'file',
      field: 'file',
      type: ActiveFormFieldType.file,
      options: {
        label: 'Файл',
        state: ['primary', 'short'],
        maxSize: mbToBites(30),
      },
    },
  ];

  const modelDialogCreate = useModelDialog<UpdateModel, DocumentDto>({
    key: 'add_return_certificate',
    title: options.create,
    // @ts-ignore
    fields,
    onModelUpdate: async (payload) => {
      return upsertFeeReturnCertificate(payload);
    },
  });

  const modelDialogUpdate = useModelDialog<UpdateModel, DocumentDto, number>({
    key: 'edit_return_certificate',
    title: options.edit,
    // @ts-ignore
    fields,
    getForm: (id) => fetchFeeReturnCertificate({
      filters: { id },
    }),
    onModelUpdate: async (payload, id) => {
      return upsertFeeReturnCertificate(payload, id);
    },
  });

  function upsertFeeReturnCertificate(payload: UpdateModel, id?: number) {
    const { errorsMap, hasErrors } = Object.entries(payload).reduce(
      (acc, [key, value]) => {
        if (['document_number', 'document_amount', 'document_date', 'file'].includes(key)) {
          if (!value) {
            acc.errorsMap[key] = ['Поле обязательно'];
            acc.hasErrors = true;
          }
        }
        return acc;
      }, {
        errorsMap: {},
        hasErrors: false,
      } as { errorsMap: ErrorsMap<any>; hasErrors: boolean},
    );

    if (hasErrors) {
      return {
        status: false,
        response: errorsMap,
      } as ApiResponseError;
    }

    const { file, ...restPayload } = payload;

    if (!id) {
      return createFeeReturnCertificate({
        ...payload,
        debtor: debtor?.value.debtor?.pk,
        production_type: productionType.value,
        is_loaded_manually: true,
      }) as Promise<ApiResponseSuccess<DocumentDto>>;
    }

    return updateFeeReturnCertificate({
      ...restPayload,
      ...(typeof file !== 'string' ? { file } : {}),
      debtor: debtor?.value.debtor?.pk,
      production_type: productionType.value,
    }) as Promise<ApiResponseSuccess<DocumentDto>>;
  }

  return {
    openCreateDialog: modelDialogCreate.showDialog,
    openUpdateDialog: modelDialogUpdate.showDialog,
  };
};
