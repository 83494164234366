import { computed, ref, Ref } from 'vue';
import { AutoTabKey, VoiceTabKey } from '../@x/tabs';
import { PretrialAutomationModel } from '../@x/pretrial-automation-model';
import { useFields } from './fields';
import { PretrialActiveFormScheme } from '../@x/forms';
import { VoiceSettingsModel } from '@/hooks/useCompanies';

const id = 'modal_automation_pretrial_tab';

export const useTabs = (
  settingsModel: Ref<PretrialAutomationModel>,
  voiceModel: Record<VoiceTabKey, Ref<VoiceSettingsModel>>,
) => {
  const allFields = useFields(settingsModel, voiceModel);
  const activeTab = ref<AutoTabKey>(AutoTabKey.commonP);

  const tabs = ref([
    {
      id: `${id}_${AutoTabKey.commonP}`,
      key: AutoTabKey.commonP,
      label: 'Общее',
    },
    {
      id: `${id}_${AutoTabKey.text}`,
      key: AutoTabKey.text,
      label: 'Уведомления SMS/мессенджеры',
    },
    {
      id: `${id}_${AutoTabKey.voice}`,
      key: AutoTabKey.voice,
      label: 'Голосовые уведомления',
    },
    {
      id: `${id}_${AutoTabKey.claim}`,
      key: AutoTabKey.claim,
      label: 'Уведомления Email',
    },
  ]);

  const commonFields = computed(() => allFields.value.fields.filter(
    (field) => field.ionFieldGroup?.key === 'common',
  ));

  const tabFieldsMap = computed(() => {
    const tabFields = {} as Record<AutoTabKey, PretrialActiveFormScheme>;

    for (const tab of tabs.value) {
      tabFields[tab.key] = allFields.value.fields.filter((x) => x?.ionFieldGroup?.key === tab.key) as PretrialActiveFormScheme;
    }

    return tabFields;
  });

  const tabFields = computed(() => tabFieldsMap.value[activeTab.value]);

  return {
    activeTab,
    tabs,
    commonFields,
    tabFields,
    tabFieldsMap,
  };
};
