import { ComputedRef, Ref, computed } from 'vue';
import { ProductionType } from '@/hooks/useConstructor';
import { useModelDialog } from '@/hooks/useModelDialog';
import { ActiveFormFieldType } from '@/hooks/useActiveForm';
import { DebtorCourtDecisionModel, useDocumentsApi } from '@/hooks/useDocumentsApi';
import {
  DebtorCourtDecisionModelForm, modelToPayload,
} from '@/components/dialog/dialogs/debtor/tabs/documents/debtorCourtDecisions';

export const useEditDebtorCourtDecisionModel = (
  debtorPk: ComputedRef<string>,
  productionType: Ref<ProductionType>,
  customLocales: ComputedRef<Array<{ label: string; value: string }>>,
) => {
  const { updateDebtorCourtDecision, fetchDebtorCourtDecision } = useDocumentsApi();

  const dialog = useModelDialog<
      DebtorCourtDecisionModelForm,
      DebtorCourtDecisionModel,
      number
    >({
      key: 'edit_court_decision',
      title: 'Редактирование судебного решения',
      getForm: (id) => fetchDebtorCourtDecision({
        id,
        debtor_id: debtorPk.value as unknown as number,
      }).then((r) => {
        if (!r.status) {
          return r;
        }
        return {
          ...r,
          response: {
            ...r.response,
            birth_date: r.response.birth_date ? new Date(r.response.birth_date) : null,
            decision_date: r.response.decision_date ? new Date(r.response.decision_date) : null,
          } as DebtorCourtDecisionModelForm,
        };
      }),
      // @ts-ignore
      fields: computed(() => [
        {
          key: 'file',
          field: 'file',
          type: ActiveFormFieldType.file,
          options: {
            label: 'Файл',
          },
        },
        {
          key: 'decision',
          field: 'decision',
          type: ActiveFormFieldType.select,
          options: {
            label: 'Статус',
            options: customLocales.value,
          },
        },
        {
          key: 'court_name',
          field: 'court_name',
          type: ActiveFormFieldType.input,
          options: {
            label: 'Название суда',
          },
        },
        {
          key: 'decision_date',
          field: 'decision_date',
          type: ActiveFormFieldType.date,
          options: {
            label: 'Дата судебного решения',

          },
        },
        {
          key: 'case_number',
          field: 'case_number',
          type: ActiveFormFieldType.input,
          options: {
            label: '№ судебного решения',

          },
        },
        {
          key: 'debtor_name',
          field: 'debtor_name',
          type: ActiveFormFieldType.input,
          options: {
            label: 'ФИО Должника',

          },
        },
        {
          key: 'address',
          field: 'address',
          type: ActiveFormFieldType.input,
          options: {
            label: 'Адрес',

          },
        },
        {
          key: 'birth_place',
          field: 'birth_place',
          type: ActiveFormFieldType.input,
          options: {
            label: 'Место рождения',

          },
        },
        {
          key: 'birth_date',
          field: 'birth_place',
          type: ActiveFormFieldType.date,
          options: {
            label: 'Дата рождения',

          },
        },
        {
          key: 'amount',
          field: 'amount',
          type: ActiveFormFieldType.input,
          options: {
            label: 'Сумма долга',
            modelModifiers: {
              number: true,
            },
          },
        },
        {
          key: 'penalties',
          field: 'penalties',
          type: ActiveFormFieldType.input,
          options: {
            label: 'Пени',
            modelModifiers: {
              number: true,
            },
          },
        },
        {
          key: 'duty',
          field: 'duty',
          type: ActiveFormFieldType.input,
          options: {
            label: 'Пошлина',
            modelModifiers: {
              number: true,
            },
          },
        },
        {
          key: 'passport',
          field: 'passport',
          type: ActiveFormFieldType.input,
          options: {
            label: 'Паспорт',
          },
        },
        {
          key: 'snils',
          field: 'snils',
          type: ActiveFormFieldType.input,
          options: {
            label: 'СНИЛС',

          },
        },
        {
          key: 'account_number',
          field: 'account_number',
          type: ActiveFormFieldType.input,
          options: {
            label: 'ЛС / Договор',
          },
        },
        {
          key: 'date_from',
          field: 'date_from',
          type: ActiveFormFieldType.date,
          options: {
            label: 'Начало периода задолженности',
          },
        },
        {
          key: 'date_to',
          field: 'date_to',
          type: ActiveFormFieldType.date,
          options: {
            label: 'Конец периода задолженности',
          },
        },
      ]),
      onModelUpdate: async (model) => {
        if (!model.file) {
          return {
            status: false,
            response: {
              file: ['Поле обязательно'],
            },
          };
        }

        const payload = modelToPayload(model, debtorPk, productionType);

        type Keys = keyof typeof payload;

        return updateDebtorCourtDecision(Object.fromEntries(
          (Object.keys(payload) as Keys[]).filter(
            (k) => (payload[k] !== null && k === 'file' ? typeof payload[k] !== 'string' : true),
          ).map((k) => ([
            k, payload[k],
          ])),
        ) as DebtorCourtDecisionModel);
      },
    });

  return dialog;
};
