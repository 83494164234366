import { useLocalI18n } from '@/hooks/useLocalI18n';
import { PretrialActiveFormScheme, VoiceSettingsActiveFormScheme } from '../@x/forms';
import { ActiveFormFieldType } from '@/hooks/useActiveForm';

export const fieldMap = (
  field: PretrialActiveFormScheme[number] | VoiceSettingsActiveFormScheme[number],
  t: ReturnType<typeof useLocalI18n>['t'],
) => {
  const result = {
    ...field,
    field: 'field' in field ? field.field : field.key,
    label: field.label ?? t(`field.${String(field.key)}`),
    type: field.type ?? ActiveFormFieldType.input,
  };

  if (!('options' in result)) {
    result.options = {};
  }

  if (result.options) {
    const labelKey = field.type === ActiveFormFieldType.checkbox
      ? 'label'
      : 'placeholder';
    const labelValue = field.options?.[labelKey] ?? t(`field.${String(field.key)}`);

    result.options.id = `modal_automation_judicial_${field.type}_${field.key}`;
    result.options[labelKey] = labelValue;
  }

  return result;
};
