import { PeriodicTaskSchedule } from '@/hooks/useSchedule';
import { Nullable } from '@/shared/lib/common-types';

export const timeToScheduleTime = (str: string) => {
  const [hour, minute] = str.split(':');

  return {
    hour: hour.startsWith('0') ? hour.substring(1, 2) : hour,
    minute: minute.startsWith('0') ? '0' : minute,
    day_of_month: '*',
    day_of_week: '*',
    month_of_year: '*',
  };
};

export const scheduleTimeToTime = (taskSchedule: Nullable<PeriodicTaskSchedule>): string => {
  if (taskSchedule) {
    return `${taskSchedule.hour}:${taskSchedule.minute}`;
  }

  return '';
};
