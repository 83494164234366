import { SourceErrors } from '@/hooks/useErrors';
import { dateToApiDate } from '@/utils/date';
import {
  RemoveCharacteristicModelResponse,
  RosreestrEstateObjectCharacteristic,
  RosreestrEstateObjectMovement,
  UpdateCharacteristicModelResponse,
  UpdateMovementModelResponse,
  useRosreestr,
} from '@/hooks/useRosreestr';
import { useDebtorTenantsSave } from './useDebtorTenantsSave';
import { useInjectDebtorDialog } from '../../useInjectDebtorDialog';
import { Nullable } from '@/shared/lib';
import { ApiResponse } from '@/service/api';

export const useDebtorCommonOwnersSave = () => {
  const {
    updateEstateObjectCharacteristic: updateCharacteristic,
    createEstateObjectCharacteristic: createCharacteristic,
    removeEstateObjectCharacteristic: removeCharacteristic,
    updateEstateObjectMovement: updateMovement,
    createEstateObjectMovement: createMovement,
    removeEstateObjectMovement: removeMovement,
  } = useRosreestr();
  const { debtor, productionType } = useInjectDebtorDialog();
  const { save: saveTenants } = useDebtorTenantsSave();

  const tenantKeysMap = [
    'birth_date', 'birth_place', 'num_of_passport',
    'inn', 'date_of_passport_issue', 'passport_issued_by',
    'passport_is_valid',
  ];

  const processModel = (model: RosreestrEstateObjectCharacteristic | RosreestrEstateObjectMovement) => {
    const { id, ...restModel } = model;
    const newModel = {} as any;

    for (const field in restModel) {
      if (!tenantKeysMap.includes(field)) {
        newModel[field] = (restModel as any)[field];
      }
    }

    ['ownership_registration_date', 'end_date'].forEach((key) => {
      if (Object.hasOwn(newModel, key)) {
        const field = (newModel as any)[key];
        if (field instanceof Date) {
          (newModel as any)[key] = dateToApiDate(field as Date);
        }
      }
    });

    return newModel;
  };

  const createOrUpdateTenant = async (model: RosreestrEstateObjectCharacteristic | RosreestrEstateObjectMovement, debtor_id: number) => {
    const tenant = debtor.value.debtor_tenant_profiles
      .find((tenant) => tenant.full_name.toLowerCase() === model.owner_name.toLowerCase()) ?? {} as any;

    for (const key of tenantKeysMap) {
      if (['birth_date', 'date_of_passport_issue'].includes(key)) {
        if (typeof (model as any)[key] === 'string') {
          (tenant as any)[key] = (model as any)[key];
        } else {
          (tenant as any)[key] = dateToApiDate((model as any)[key]);
        }
      } else {
        (tenant as any)[key] = (model as any)[key];
      }
    }

    if (!tenant.full_name) {
      tenant.full_name = model.owner_name;
    }

    const { response, errors } = await saveTenants(tenant, productionType.value, debtor_id);

    if (!errors || errors.length === 0) {
      debtor.value.debtor_tenant_profiles.push(response);
    }
  };

  const saveCharacteristic = async (
    model: RosreestrEstateObjectCharacteristic,
    debtor_id: number,
    shouldDelete: boolean,
    isNew: boolean,
  ): Promise<{ errors: SourceErrors<any>; response: RosreestrEstateObjectCharacteristic }> => {
    let response: Nullable<ApiResponse<UpdateCharacteristicModelResponse | RemoveCharacteristicModelResponse>> = null;

    if (shouldDelete) {
      response = await removeCharacteristic({ id: model.id });
    } else if (isNew) {
      response = await createCharacteristic({ model: processModel(model), debtor_id });
    } else {
      response = await updateCharacteristic({ model: processModel(model), id: model.id });
    }

    if (response?.status) {
      await createOrUpdateTenant(model, debtor_id);
    }

    if (response && !response.status) {
      return {
        errors: Object.entries(response)
          .map(([key, errors]) => ([`${model.id}-${key}`, errors as string[]])),
        response: response as any,
      };
    }

    return {
      errors: [],
      response: shouldDelete ? { status: true } : response as any,
    };
  }

  const removeCharacteristicModel = async (
    model_id: RosreestrEstateObjectCharacteristic['id'],
  ): Promise<{ errors: SourceErrors<any>; response: RemoveCharacteristicModelResponse }> => {
    return removeCharacteristic({ id: model_id })
      .then(({ status, response }) => {
        if (!status) {
          return {
            errors: Object.entries(response)
              .map(([key, errors]) => ([`${model_id}-${key}`, errors as unknown as string[]])),
            response,
          };
        }
        return {
          errors: [],
          response: { status: true },
        };
      });
  };

  const saveMovement = async (
    model: RosreestrEstateObjectMovement,
    debtor_id: number,
    shouldDelete: boolean,
    isNew: boolean,
  ): Promise<{ errors: SourceErrors<any>; response: RosreestrEstateObjectMovement }> => {
    let response: Nullable<ApiResponse<UpdateMovementModelResponse | RemoveCharacteristicModelResponse>> = null;

    if (shouldDelete) {
      response = await removeMovement({ id: model.id });
    } else if (isNew) {
      response = await createMovement({ model: processModel(model), debtor_id });
    } else {
      response = await updateMovement({ model: processModel(model), id: model.id });
    }

    if (response?.status) {
      await createOrUpdateTenant(model, debtor_id);
    }

    if (response && !response.status) {
      return {
        errors: Object.entries(response)
          .map(([key, errors]) => ([`${model.id}-${key}`, errors as string[]])),
        response: response as any,
      };
    }

    return {
      errors: [],
      response: shouldDelete ? { status: true } : response as any,
    };
  };

  const removeMovementModel = async (model_id: RosreestrEstateObjectMovement['id']): Promise<{ errors: SourceErrors<any>; response: RemoveCharacteristicModelResponse }> => {
    return removeMovement({ id: model_id })
      .then(({ status, response }) => {
        if (!status) {
          return {
            errors: Object.entries(response)
              .map(([key, errors]) => ([`${model_id}-${key}`, errors as unknown as string[]])),
            response,
          };
        }
        return {
          errors: [],
          response: { status: true },
        };
      });
  };

  return {
    saveCharacteristic,
    removeCharacteristic: removeCharacteristicModel,
    saveMovement,
    removeMovement: removeMovementModel,
  };
};
