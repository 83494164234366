import { isObject } from '@/utils/object';
import { dateToApiDate, parseIfDate } from '@/utils/date';
import { DateTime } from 'luxon';
import {
  FEE_NOT_ORDERED,
  FEES_NOT_PAID, STATEMENT_MISSING,
  STATEMENT_NOT_ORDERED,
} from '@/pages/debtors/_module/filters/useStatusOptions';
import { useStore } from 'vuex';
import { useLocalStorage } from '@vueuse/core';

const mergeFieldsMap = {
  has_doc_and: [
    'doc_court_decision',
    'doc_house_register',
    'doc_payment',
    'doc_debtor',
    'doc_protocol_ooc',
    'doc_egrn',
    'doc_egrn_rights',
    'invoice',
  ],
  has_doc_or: [
  ],
  has_tenant_profile_and: [
    'birth_date',
    'birth_place',
    'num_of_passport',
    'inn',
  ],
  has_tenant_and: [
    'shareholders',
    'owners',
    'cohabitees',
  ],
};

const mergeFieldsMapReversed = Object.entries(mergeFieldsMap)
  .reduce((acc, [apiKey, fieldKeys]) => {
    fieldKeys.forEach((key) => {
      acc[key] = apiKey;
    });
    return acc;
  }, {} as Record<string, string>);

const feesSubstatuses = [
  'fees_paid',
  'fees_repaid',
  'fees_await_paid',
  'fees_await_repaid',
  'fees_shareholders_await_paid',
];

const statementSubstatuses = [
  'statement_ordered',
  'statement_received',
  'statement_ready',
  'statement_error',
  'estate_owner_unknown',
  'apartment_jurisdiction_wrong',
  'addresses_dont_match',
  'cadnum_not_found',
  'cadnum_archive',
  'cadnum_no_rights',
  'need_arbitr',
  'jurisdiction_undefined',
];

export const personalAccounts = useLocalStorage<string[]|[null]>('personalAccounts', null);

export const mapDebtorsQueryModelToRequestFilters = (
  filtersModel: Record<any, any>,
) => Object.entries(filtersModel).reduce((acc, [key, value]) => {
  let values: any[];
  const sourceValueIsArray = Array.isArray(value);
  if (key === 'personal_account') {
    // @ts-ignore
    values = typeof value === 'string'
      ? value === '' ? null : value.split(',').map((v) => v.trim())
      : [value];
    personalAccounts.value = values;
  } else {
    values = Array.isArray(value)
      ? value
      : typeof value === 'string' ? value.split(';') : [value];
  }

  if (key === 'estate_owner_known') {
    if (value == null || value === undefined) {
      return acc;
    }
    if (value) {
      acc.has_estate_object_characteristics = true;
    } else {
      acc.has_not_estate_object_characteristics = true;
    }
    return acc;
  }

  acc.status_name_not = acc.statement === 'order'
    ? ['prepare_in_lawsuit', 'ready_lawsuit_to_court', 'archive']
    : 'archive';

  if (key === 'statement') {
    if (value == null) {
      return acc;
    }
    if (value === 'claim') {
      acc.status_name = (acc.status_name ?? []).concat(...['prepare_in_lawsuit', 'ready_lawsuit_to_court']);
      return acc;
    }
  }

  if (key === 'status_updated_at_after;status_updated_at_before') {
    if (value) {
      const [after, before] = value.map(parseIfDate);
      if (!after || !before) {
        return acc;
      }
      const after_formatted = DateTime.fromJSDate(after).startOf('day').toISO();
      const before_formatted = DateTime.fromJSDate(before).endOf('day').toISO();

      acc.status_updated_at_after = after_formatted;
      acc.status_updated_at_before = before_formatted;
      return acc;
    }
  }

  if (key === 'status_filter') {
    (value as Array<[string]|[string, string]>).forEach((value) => {
      if (!acc.substatus_name_latest) {
        acc.substatus_name_latest = [];
      }
      if (!acc.substatus_name_latest_not) {
        acc.substatus_name_latest_not = [];
      }
      if (!acc.status_name_latest) {
        acc.status_name_latest = [];
      }
      if (!acc.statement_substatus_name_latest) {
        acc.statement_substatus_name_latest = [];
      }
      if (!acc.fee_substatus_name_latest) {
        acc.fee_substatus_name_latest = [];
      }
      if (!acc.fee_substatus_name_latest_not) {
        acc.fee_substatus_name_latest_not = [];
      }
      if (!acc.substatus_name_or_not) {
        acc.substatus_name_or_not = [];
      }
      if (!acc.user_status_latest) {
        acc.user_status_latest = [];
      }

      if (value) {
        const [status, substatus] = value;

        // Обрабатываем пользовательские статусы
        if (status === 'my_status') {
          if (substatus) {
            // Если выбран конкретный пользовательский статус, добавляем его в main_profile_status
            if (!acc.main_profile_status) {
              acc.main_profile_status = [];
            }
            // Проверяем, является ли main_profile_status массивом
            if (!Array.isArray(acc.main_profile_status)) {
              acc.main_profile_status = [acc.main_profile_status];
            }
            // Добавляем статус в массив, если его там еще нет
            if (!acc.main_profile_status.includes(substatus)) {
              acc.main_profile_status.push(substatus);
            }
          }
        } else {
          // Для обычных статусов оставляем прежнюю логику
          if (!acc.status_name_latest.includes(status)) {
            acc.status_name_latest.push(status);
          }

          if (acc.status_name_latest.includes('archive')) {
            acc.status_name_not = [];
          }

          if (substatus) {
            // Преобразуем подстатус к нижнему регистру для сравнения
            const normalizedSubstatus = substatus.toLowerCase();

            if ([FEES_NOT_PAID, FEE_NOT_ORDERED].includes(normalizedSubstatus)) {
              acc.has_fee_substatus_latest = false;

            } else if (normalizedSubstatus === STATEMENT_NOT_ORDERED) {
              acc.substatus_name_or_not.push(...[
                'statement_ordered',
                'statement_received',
                'statement_ready',
                'statement_error',
              ]);

            } else if (normalizedSubstatus === STATEMENT_MISSING) {
              acc.substatus_name_or_not.push(...[
                'statement_received',
                'statement_ready',
              ]);

            } else if (feesSubstatuses.includes(normalizedSubstatus)) {
              // Используем оригинальное значение для добавления в параметры
              acc.fee_substatus_name_latest.push(normalizedSubstatus);
            } else if (statementSubstatuses.includes(normalizedSubstatus)) {
              acc.statement_substatus_name_latest.push(normalizedSubstatus);

            } else {
              acc.substatus_name_latest.push(substatus);
            }
          }
        }
      }
    });
    return acc;
  }

  values.forEach((value, valueIndex) => {
    const keys = key.split(';');
    const valueIsObject = isObject(value);
    keys.forEach((key, keyIndex) => {
      const keyPrepared = mergeFieldsMapReversed[key]
          || (filtersModel.search_name_deep && key === 'full_name' ? 'full_name_deep' : key);
      const getValue = () => (valueIsObject
        ? value?.[key]
        : sourceValueIsArray
          ? valueIndex === keyIndex ? values[valueIndex] : undefined
          : value
      );
      const isValueIsNot = (v: any) => (typeof v === 'string'
        ? v.startsWith('!')
        : false);
      const trimIsNotValue = (v: any, isNot: boolean) => (
        !isNot
          ? v
          : (typeof v === 'string' ? v.substr(1) : v)
      );
      const formatDateIfItDate = (v: any) => {
        if (v instanceof Date) return dateToApiDate(v);
        const parsedDate = parseIfDate(v);
        if (parsedDate) return dateToApiDate(parsedDate);
        return v;
      };

      const isNot = isValueIsNot(getValue());
      const field = isNot ? `${keyPrepared}_not` : keyPrepared;
      const normValue = formatDateIfItDate(trimIsNotValue(getValue(), isNot));
      if (normValue === null || normValue === undefined) return acc;
      if (mergeFieldsMapReversed[key]) {
        // https://jira.urrobot.tech/browse/URROBOT-3006
        if (key === 'doc_payment') {
          if (!acc.has_tenant_and) {
            acc.has_tenant_and = [];
          }
          acc.has_tenant_and.push('shareholders');
        }
        const fieldPrep = `${field}${getValue() ? '' : '_not'}`;
        if (!acc[fieldPrep]) acc[fieldPrep] = [];
        acc[fieldPrep].push(key);
      } else if (field === 'substatus_name' || field === 'substatus_name_not') {
        if (acc[field] === undefined || acc[field] === null) {
          acc[field] = [normValue];
        } else {
          acc[field].push(normValue);
        }
      } else if (field === 'fee_status') {
        if (!acc.substatus_name) acc.substatus_name = [normValue];
        else acc.substatus_name.push(normValue);
      } else if (field === 'fee_status_not') {
        if (!acc.substatus_name_not) acc.substatus_name_not = [normValue];
        else acc.substatus_name_not.push(normValue);
      } else if (field === 'personal_account') {
        if (!acc[field]) acc[field] = [];
        acc[field].push(value);
      } else {
        if (sourceValueIsArray) {
          if (!acc[field]) {
            acc[field] = [];
          }
          acc[field].push(normValue);
        } else {
          acc[field] = normValue;
        }
      }
    });
  });
  return acc;
}, {} as Record<string, any>);
