import {
  CompanySettingsJudicial,
  JudicialAutomationModel,
} from '@/components/automation/judicial/types';
import { Employee } from '@/hooks/useEmployees';

export const toSettings = (model: JudicialAutomationModel, employees: Employee[]): CompanySettingsJudicial => {
  const {
    amount_range, employees: employeeIds, notify_emails, ...rest
  } = model;
  const [judicial_amount_from, judicial_amount_to] = model.amount_range;
  console.log('to settings', model);
  return {
    ...rest,
    judicial_amount_from,
    judicial_amount_to,
    notify_emails: [
      ...employeeIds.map(
        (id) => employees.find((e) => e.id === id)?.email || null,
      ),
      ...notify_emails,
    ],
    /**
     * Периодические задачи сохраняются не в настройки,
     * но используются "отсюда"
     */
    move_judicial_debtors_without_debt_to_archive: model.move_judicial_debtors_without_debt_to_archive,

    auto_correct_debt_period: model.auto_correct_debt_period,

    filling_claim_period: model.auto_filing_claim && model.filling_claim_period,
    fees_discharge_period: model.auto_fees_discharge && model.fees_discharge_period,
    rosreestr_discharge_period: model.auto_rosreestr_discharge && model.rosreestr_discharge_period,

    auto_refiling_claims_period: model.auto_refiling_claims && model.auto_refiling_claims_period,

    auto_filing_complaint_period: model.auto_filing_complaint && model.auto_filing_complaint_period,

    rosreestr_discharge_period_force: model.auto_rosreestr_discharge_period_force
      ? model.rosreestr_discharge_period_force
      : null,
    court_complaint_overdue: model.auto_filing_complaint
      ? model.court_complaint_overdue
      : null,
    ...(
      model.auto_update_debtor_data
        ? {
          auto_update_debtor_data_period: model.auto_update_debtor_data_period,
          judicial_auto_update_debtor_data_config: model.auto_update_debtor_data_config ? Object.fromEntries(
            model.auto_update_debtor_data_config.map(
              (key) => ([key, true]),
            ),
          ) : {},
        }
        : {}
    ),
    judicial_auto_update_debtor_data_arguments: {
      force: model.judicial_auto_update_debtor_data_arguments,
    },
    send_electronic_court_decisions_notify_emails:
      model.send_electronic_court_decisions_notify_emails.length
        ? model.send_electronic_court_decisions_notify_emails : null,
    auto_rosreestr_config: {
      ignore_norights: model.ignore_norights,
    },
    pik_sale_documents_period: model.pik_sale_documents_period ? model.pik_sale_documents_period : null,
    show_administrative_district_filter: model.show_administrative_district_filter,
    send_joint_ownership_letters_separately: model.send_joint_ownership_letters_separately,
    send_solidary_debtors_letters_separately: model.send_solidary_debtors_letters_separately,
    court_order_period_after_canceled_judgment: model.court_order_period_after_canceled_judgment,
  } as unknown as CompanySettingsJudicial;
};
