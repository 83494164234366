import { computed, Ref } from 'vue';
import { Debtor } from '@/hooks/useDebtors';
import { ProductionType } from '@/hooks/useConstructor';
import { useLocalI18n } from '@/hooks/useLocalI18n';
import {
  Accruals,
  ActiveServiceTab, AllServices,
  FullFinanceData, PaidUps, Saldo,
} from '@/components/dialog/dialogs/debtor/tabs/finance/types';
import { SettingEnabledKey, useSettingEnabled } from '@/hooks/ais/useSettingEnabled';
import { useProtectedDefaultCompany } from '@/hooks/useProtectedDefaultCompany';

export const useSummaries = (
  debtor: Ref<Debtor>,
  productionType: Ref<ProductionType>,
  fullData: Ref<FullFinanceData>,
  serviceFilter: Ref<ActiveServiceTab>,
) => {
  const { t } = useLocalI18n('debtor.finance');
  const { companyId } = useProtectedDefaultCompany();
  const pikEnabled = useSettingEnabled(companyId, SettingEnabledKey.pik);
  const borEnabled = useSettingEnabled(companyId, SettingEnabledKey.bor);

  function recalculateSum(value: string | number, key = '') {
    if (serviceFilter.value === AllServices) {
      return value;
    }
    if (key === Accruals || key === PaidUps) {
      const records = fullData.value[key];
      if (!records?.length) {
        return value;
      }
      let sum = 0;
      records.forEach((record: any) => {
        const keys = Object.keys(record);
        const filteredKeys = keys.filter((key) => key === Saldo || key.includes(serviceFilter.value));
        filteredKeys.forEach((key) => {
          const value = record[key];
          sum += parseFloat(value);
        });
      });
      return sum;
    }
    return 0;
  }

  const summaries = computed(() => ([
    productionType.value !== 'executive' && {
      key: Accruals,
      label: t('summaries.accruals'),
      value: recalculateSum(debtor.value?.total_accrual, Accruals),
    },
    {
      key: PaidUps,
      label: t('summaries.paid-ups'),
      value: recalculateSum(debtor.value?.total_paid_up, PaidUps),
    },
    {
      key: 'debt',
      label: t('summaries.debt'),
      value: recalculateSum(debtor.value?.total_debt),
    },
    {
      key: 'penalty',
      label: t('summaries.penalty'),
      value: recalculateSum(debtor.value?.total_penalty),
    },
    productionType.value === 'executive' && {
      key: 'fee',
      label: t('summaries.fee'),
      value: recalculateSum(debtor.value?.total_fee),
    },
    productionType.value === 'executive' && {
      key: 'totalDebts',
      label: t('summaries.totalDebts'),
      value: recalculateSum(debtor.value?.total_total_debt),
    },
    (pikEnabled.value || borEnabled.value) && {
      key: 'actualDebt',
      label: t('summaries.actualDebt'),
      value: recalculateSum(debtor.value?.actual_debt),
    },
  ].filter(Boolean)));

  return { summaries };
};
