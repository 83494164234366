import { PretrialClaimSettings } from '@/hooks/usePretrialClaimSettings';

export const getBlankPretrialClaim = (number: number): PretrialClaimSettings => ({
  number,
  enabled: false,
  threshold: 0,
  days: 0,
  pochta_ru: false,
  is_ordered: false,
  email: false,
});
