import { useProtectedInject } from '@/hooks/useProtectedInject';
import { PhoneNumberStatusComponentKey } from '@/symbols';
import { useLocalI18n } from '@/hooks/useLocalI18n';
import { h, Ref } from 'vue';
import { ActiveFormFieldType, ActiveFormIonListGroup } from '@/hooks/useActiveForm';
import {
  useDebtorPhoneNumbersApi,
} from '@/components/dialog/dialogs/debtor/tabs/common/tabs/main/useDebtorPhoneNumbersApi';
import { Debtor, Phone } from '@/hooks/useDebtors';
import { ProductionType } from '@/hooks/useConstructor';
import { sortPhones } from '@/components/dialog/dialogs/debtor/tabs/common/tabs/main/utils';

type Options = {
  isTenants?: boolean;
  showContactFields?: boolean;
}

export function useDebtorPhoneNumbers(
  debtor: Ref<Debtor>,
  productionType: Ref<ProductionType>,
  addGroupName = true,
  { isTenants, showContactFields }: Options = {
    isTenants: false,
    showContactFields: true,
  },
) {
  const debtorPhoneNumbersApi = useDebtorPhoneNumbersApi();
  const PhoneNumberStatusComponent = useProtectedInject(PhoneNumberStatusComponentKey);
  const { t } = useLocalI18n('debtor.common.main.field');
  const { t: tTenant } = useLocalI18n('debtor.common.tenants');

  const keyName = isTenants
    ? 'tenant_phone_numbers'
    : 'phone_numbers';

  const phoneNumberGroup = {
    tag: 'listGroup',
    key: keyName,
    label: !addGroupName
      ? null
      : isTenants
        ? ' '
        : 'Номера телефонов',
    isDeletable: true,
    isAddable: true,
    withInitValue: true,
    state: ['horizontal'],
    isReadonly: isTenants,
    renderReadonly: (phones: Phone[]) => sortPhones(phones).map(
      ({
        number,
        is_valid,
        full_name,
        contact,
      }) => {
        return h(
          PhoneNumberStatusComponent as any,
          {
            number,
            isValid: is_valid,
            fullName: full_name,
            contact,
          },
        );
      },
    ),
  } as ActiveFormIonListGroup;

  const contactTypeFields = showContactFields ? [
    {
      key: 'full_name',
      label: t('full_name'),
      type: ActiveFormFieldType.input,
      state: ['primary'],
      options: {
        placeholder: t('full_name'),
        isDisabled: true,
      },
      ionFieldGroup: phoneNumberGroup,
    },
    {
      key: 'contact',
      label: tTenant('column.contact'),
      type: ActiveFormFieldType.select,
      state: ['primary'],
      style: { flex: 0.7 },
      options: {
        placeholder: tTenant('column.contact'),
        isDisabled: true,
        options: [
          { value: null, label: tTenant('contact.none') },
          { value: 'owner', label: tTenant('contact.owner') },
          { value: 'contact_person', label: tTenant('contact.contact_person') },
        ],
      },
      ionFieldGroup: phoneNumberGroup,
    },
  ] : [];

  const fields = [
    {
      key: 'number',
      label: t('phone_number.number'),
      type: ActiveFormFieldType.input,
      state: ['primary'],
      style: showContactFields ? { flex: 0.7 } : {},
      options: {
        placeholder: t('phone_number.number'),
      },
      ionFieldGroup: phoneNumberGroup,
    },
    {
      key: 'is_valid',
      label: t('phone_number.is_valid'),
      type: ActiveFormFieldType.select,
      state: ['primary'],
      style: showContactFields ? { flex: 0.7 } : {},
      options: {
        placeholder: t('phone_number.is_valid'),
        options: [
          {
            label: 'Неизвестно',
            value: null,
          }, {
            label: 'Актуальный',
            value: true,
          }, {
            label: 'Неактуальный',
            value: false,
          },
        ],
      },
      ionFieldGroup: phoneNumberGroup,
    },
    ...contactTypeFields,
  ];

  async function submitPhones(
    initPhones: Phone[],
    phones: Phone[],
    debtor_tenant_profile?: number,
  ) {
    const debtorId = !debtor_tenant_profile ? (debtor.value?.debtor_main_profile?.id as number) : undefined;

    const deletePromises = initPhones
      .filter(
        (n) => !phones.find(
          (number) => number.id === n.id,
        ),
      ).map((number) => debtorPhoneNumbersApi.delete(
        number.id as number,
        productionType.value,
        debtor.value?.debtor.pk,
      ));

    const ids = debtor_tenant_profile ? {
      debtor_tenant_profile,
      debtor_main_profile: null,
    } : {
      debtor_tenant_profile: null,
      debtor_main_profile: (debtor.value?.debtor_main_profile?.id as number),
    };

    const createPromises = phones
      .filter((number) => !number.id && number.number)
      .map((number) => debtorPhoneNumbersApi.create({
        ...ids,
        number: number.number,
        is_valid: number.is_valid,
        debtor_main_profile: debtorId,
        debtor_tenant_profile: debtor_tenant_profile ?? null,
        debtor_id: debtor.value?.debtor.pk,
        production_type: productionType.value,
      }));

    const updatePromises = phones
      .filter(
        (number) => number.id && number.number,
      ).map(
        (number) => debtorPhoneNumbersApi.update({
          ...ids,
          number: number.number,
          id: number.id as number,
          is_valid: number.is_valid,
          debtor_main_profile: (debtor.value?.debtor_main_profile?.id as number),
          debtor_id: debtor.value?.debtor.pk,
          production_type: productionType.value,
        }),
      );

    await Promise.all([
      ...deletePromises,
      ...updatePromises,
      ...createPromises,
    ] as Promise<unknown>[]);
  }

  return {
    fields,
    submitPhones,
  };
}
