import { Module } from 'vuex';
import { StoreState } from '@/store';
import { ExchangeImportApiRequest, ExchangeImportPartialRequest } from '@/pages/exchange/import/useExchangeImport';
import { ApiCommand, ApiRequest, ApiResponse } from '@/store/modules/api';
import { ImportTypeKey, isDirectProductionImport } from '@/pages/exchange/import/importTypes';
import { ProductionType } from '@/hooks/useConstructor';

// eslint-disable-next-line @typescript-eslint/ban-types
export type DataFileState = {};

type DataFileModule = Module<DataFileState, StoreState>;

export const namespaced = true;

const UUIDS_MAP = {
  [ImportTypeKey.instruction]: null,
  [ImportTypeKey.pretrial]: null,
  [ImportTypeKey.judicial]: null,
  [ImportTypeKey.executive]: null,
  [ImportTypeKey.paymentOrder]: '0574d4db-7cfa-42f0-be4e-0029302e8bf1',
  [ImportTypeKey.judgment]: '193ee6a2-f1c7-4437-a804-7f00ee29ae10',
  [ImportTypeKey.housebook]: 'a2fc3e07-048a-42a9-ab30-55635c5ddc54',
  [ImportTypeKey.oosProtocol]: 'a9fc9e07-048a-42a9-ab30-55635c5ddc54',
  [ImportTypeKey.photos]: '544baa17-db6f-419a-bd3d-a9d10a63a662',
  [ImportTypeKey.loanAgreement]: 'b2131256-9470-4851-a1d5-a07cfae9757a',
  [ImportTypeKey.contract]: '41a88b1c-f044-4213-8869-6eeac2f4ab6c',
  [ImportTypeKey.egrn]: '7cbe37b4-e79c-4757-9d33-623ffd4ab02c',
  [ImportTypeKey.pochta]: 'c7d6507c-22b7-478a-b01c-154f96b923f3',
  [ImportTypeKey.invoice]: '8fff237a-93cd-4dc2-be86-975b466207be',
  [ImportTypeKey.fee_return_certificate]: 'b393f08b-1b48-4ed4-aada-4273a7359601',
};

export const actions: DataFileModule['actions'] = {

  async uploadDataOrDataFile({ dispatch, rootGetters }, payload: ExchangeImportPartialRequest) {

    const task_uuid = UUIDS_MAP[payload.dataType];
    const isProductionImport = isDirectProductionImport(payload.dataType);

    const { status, response } = (await dispatch('api/request', {
      command: !task_uuid
        ? ApiCommand.uploadData
        : ApiCommand.uploadDataFile,
      data: {
        module_type: payload.module_type,
        company: payload.companyId,
        last: true,
        file: payload.file.file,
        name: payload.file.name,
        template: payload.template,
        ...(isProductionImport ? {
          module: ({
            [ImportTypeKey.pretrial]: 1,
            [ImportTypeKey.judicial]: 2,
            [ImportTypeKey.executive]: 3,
          } as any)[payload.dataType],
          mode: payload.mode,
          region: payload.regionId,
          year: payload.year,
          month: payload.month,
          payload: {
            template_id: payload.template_id,
          },
        } : {
          last: payload.last,
          uuid: payload.uuid,
          region: payload.regionId,
          production_type: payload.production_type ?? ProductionType.judicial,
          task_uuid,
          payload: {
            ...(payload.production_type ? { production_type: payload.production_type } : {}),
            ...(['egrn', 'paymentOrder'].includes(payload.dataType) ? payload.searchByFileName && { search_by_filename: payload.searchByFileName } : {}),
            ...(payload.dataType === 'paymentOrder' ? {
              ...(payload.enableDuplicatesSearch && { search_duplicates: payload.enableDuplicatesSearch }),
              ...(payload.ignoreFeeAmount && { ignore_fee_amount: payload.ignoreFeeAmount }),
              ...(payload.ignoreFullName && { ignore_full_name: payload.ignoreFullName }),
              ...(payload.ignoreStatuses && { ignore_statuses: payload.ignoreStatuses }),
              ...(payload.ignorePersonalAccount && { ignore_personal_account: payload.ignorePersonalAccount }),
              ...(payload.ignoreDetails && { ignore_details: payload.ignoreDetails }),
              ...(payload.repaid && { repaid: payload.repaid }),
              ...(payload.neuroModelTag && { model_tag: payload.neuroModelTag }),
              ...(payload.returnCertificate && { return_certificate: payload.returnCertificate }),
            } : {}),
            ...(payload.dataType === 'oosProtocol' ? {
              ...(payload.useFileNameAddress && { use_filename_address: payload.useFileNameAddress }),
            } : {}),
          },
        }),
      },
    } as ApiRequest<ExchangeImportApiRequest<(typeof isProductionImport) extends true ? true : false>>['data'], {
      root: true,
    })) as ApiResponse;

    return {
      status,
      response,
    };
  },
};
