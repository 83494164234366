import { PretrialAutomationModel } from '../../@x/pretrial-automation-model';
import { getBlankPretrialClaim } from '../claim/create-blank-pretrial-claim';

export const createEmptyFormModel = (): PretrialAutomationModel => ({
  auto_create_sms_notifications: true,
  auto_create_voice_notifications: true,
  auto_create_email_notifications: true,
  auto_court_transfer: false,
  actual_debt: false,
  court_days_overdue_enabled: false,
  court_days_overdue: null,
  court_threshold_enabled: false,
  court_threshold: 0,
  debt_notifications_enabled: false,
  debt_notification_threshold: 0,
  debt_notification_failed_attempts: 1,
  voice_send_sms_if_unavailable: true,
  debt_notifications_period: null,
  debt_notifications_period_sms: null,
  debt_notifications_period_email: null,
  debt_notifications: ['sms', 'voice', 'email'],
  enabled_debt_notifications: ['sms', 'voice'],
  pretrial_claim_enabled: false,
  pretrial_claim_period: null,
  pretrial_claim_send_type: null,
  pretrial_claim_threshold: 0,
  pretrial_claims: [
    getBlankPretrialClaim(1),
    getBlankPretrialClaim(2),
    getBlankPretrialClaim(3),
  ],
  transfer_to_operator_number: '',
  kvint_campaign_id: '',
});
