import { Ref } from 'vue';
import { AutoTabKey, JudicialAutomationModel } from '@/components/automation/judicial/types';
import { ActiveFormFieldType } from '@/hooks/useActiveForm';
import { fieldGroup } from '@/components/automation/judicial/tabs/utils';
import { Employee } from '@/hooks/useEmployees';

export function getRosreestrFields(
  model: Ref<JudicialAutomationModel>,
  employees: Ref<Employee[]>,
) {
  return [
    {
      key: 'auto_rosreestr_discharge',
      field: 'auto_rosreestr_discharge',
      type: ActiveFormFieldType.checkbox,
      options: {
        isDisabled: !model.value.judicial_automation_enabled,
      },
      onUpdateModelValue: (model: JudicialAutomationModel, value: boolean) => {
        if (!value) {
          model.rosreestr_discharge_period = null;
        } else {
          model.rosreestr_discharge_period = {
            minute: '0',
            hour: '10',
            day_of_week: '*',
            day_of_month: '1',
            month_of_year: '1,2,3,4,5,6,7,8,9,10,11,12',
          };
        }
      },
    },
    {
      key: 'rosreestr_discharge_period',
      type: ActiveFormFieldType.schedule,
      state: ['primary'],
      options: {
        state: ['primary'],
        availableTabs: ['everyday', 'daysOfWeek', 'daysOfMonth', 'monthsDays', 'monthsOfYear'],
        isDisabled: !model.value.auto_rosreestr_discharge,
      },
    },
    {
      key: 'auto_rosreestr_discharge_period_force',
      field: 'auto_rosreestr_discharge_period_force',
      type: ActiveFormFieldType.checkbox,
      options: {
        isDisabled: !model.value.judicial_automation_enabled,
      },
      onUpdateModelValue: (model: JudicialAutomationModel, value: boolean) => {
        if (!value) {
          model.rosreestr_discharge_period_force = null;
        } else {
          model.rosreestr_discharge_period_force = {
            minute: '0',
            hour: '10',
            day_of_week: '*',
            day_of_month: '1',
            month_of_year: '1,2,3,4,5,6,7,8,9,10,11,12',
          };
        }
      },
    },
    {
      key: 'rosreestr_discharge_period_force',
      type: ActiveFormFieldType.schedule,
      state: ['primary'],
      options: {
        state: ['primary'],
        availableTabs: ['everyday', 'daysOfWeek', 'daysOfMonth', 'monthsDays', 'monthsOfYear'],
        isDisabled: !model.value.auto_rosreestr_discharge_period_force,
      },
    },
    {
      key: 'rosreestr_characteristics',
      type: ActiveFormFieldType.checkbox,
    },
    {
      key: 'rosreestr_movement',
      type: ActiveFormFieldType.checkbox,
    },
    {
      key: 'unknown_owner_rosreestr_movement',
      type: ActiveFormFieldType.checkbox,
    },
    {
      key: 'debt_previous_rosreestr_movement',
      type: ActiveFormFieldType.checkbox,
    },
    {
      key: 'auto_attach_extracts_from_egrn',
      type: ActiveFormFieldType.checkbox,
    },
    {
      key: 'ignore_norights',
      type: ActiveFormFieldType.checkbox,
    },
    {
      key: 'period_of_ownership_in_debt_collection',
      type: ActiveFormFieldType.checkbox,
    },
    {
      key: 'rosreestr_user',
      type: ActiveFormFieldType.select,
      options: {
        options: employees.value,
        placeholder: 'Выберите пользователя',
        valueField: 'id',
        displayField: ['first_name', 'last_name'],
        multiple: false,
      }
    },
  ].map((field) => ({
    ...field,
    ionFieldGroup: fieldGroup(AutoTabKey.rosreestr),
  }));
}
