import { ActiveFormField, ActiveFormFieldType } from '@/hooks/useActiveForm';
import { Ref } from 'vue';
import { Employee } from '@/hooks/useEmployees';
import { OnQueryEmployee, PretrialAutomationModel } from '../../@x/pretrial-automation-model';
import { AutoTabKey, VoiceTabKey } from '../../@x/tabs';
import { useVoiceInforming } from './voice-informing';
import { useVoicePaymentControl } from './voice-payment-control';
import { ICheckboxState } from '@/components/checkbox/useCheckbox';
import { VoiceSettingsModel } from '@/hooks/useCompanies';

export const useVoice = (
  settingsModel: Ref<PretrialAutomationModel>,
  voiceModel: Record<VoiceTabKey, Ref<VoiceSettingsModel>>,
  rootT: (s: string) => string,
  options: Ref<Array<{label: string; value: string}>>,
  kvintOptions: Ref<Array<{label: string; value: string}>>,
  onQueryEmployee: OnQueryEmployee,
  employees: Ref<Employee[]>,
) => ({
  voiceNotificationsEnabled: {
    key: 'auto_create_voice_notifications',
    field: 'auto_create_voice_notifications',
    label: '',
    type: ActiveFormFieldType.checkbox,
    state: ['primary'],
    options: {
      placeholder: null,
      allowEmpty: false,
      state: [ICheckboxState.primary, ICheckboxState.horizontal],
      options: [
        { value: true, label: rootT('other.yes') },
        { value: false, label: rootT('other.no') },
      ],
    },
    ionFieldGroup: {
      tag: 'fieldGroup',
      key: AutoTabKey.voice,
    },
  } as ActiveFormField<PretrialAutomationModel>,
  default: VoiceTabKey.Informing,
  tabs: {
    [VoiceTabKey.Informing]: useVoiceInforming(settingsModel, voiceModel[VoiceTabKey.Informing], rootT, options, kvintOptions, onQueryEmployee, employees),
    [VoiceTabKey.PaymentControl]: useVoicePaymentControl(settingsModel, voiceModel[VoiceTabKey.PaymentControl], rootT, options, kvintOptions, onQueryEmployee, employees),
  },
});
