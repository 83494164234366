import { VoiceSettingsModel } from '@/hooks/useCompanies';
import { CompanySettingsPretrial, PretrialAutomationModel } from '../../@x/pretrial-automation-model';
import { timeToScheduleTime } from '../../lib/schedule-time';
import { createEmptyFormModel } from './create-empty-form-model';
import { createEmptyVoiceSettingsModel } from '../voice/create-empty-voice-settings-model';

export const toFormModel = (settings: CompanySettingsPretrial): PretrialAutomationModel => {
  const emptyModel = createEmptyFormModel();
  const model: Partial<PretrialAutomationModel> = {};

  for (const key in emptyModel) {
    const k = key as keyof PretrialAutomationModel;

    if (k in settings) {
      model[k] = settings[k as keyof CompanySettingsPretrial];
    }
  }

  return {
    ...model,
    court_days_overdue_enabled: !!settings.court_days_overdue,
    court_threshold_enabled: !!settings.court_threshold,
  } as PretrialAutomationModel;
};

export const toVoiceFormModel = (settings: VoiceSettingsModel): VoiceSettingsModel => {
  const emptyModel = createEmptyVoiceSettingsModel(settings.company, settings.type);
  const model: Partial<VoiceSettingsModel> = { };

  for (const key in emptyModel) {
    const k = key as keyof VoiceSettingsModel;

    if (k in settings) {
      model[k] = settings[k as keyof VoiceSettingsModel] as any;
    }
  }

  return {
    ...model,
    id: settings.id,
    company: settings.company,
    type: settings.type,
    assigned_user: settings.assigned_user,
    promised_payment_days: settings.promised_payment_days ?? 0,
    weekday_period_from: settings.weekday_period_from ? timeToScheduleTime(settings.weekday_period_from) : null,
    weekday_period_to: settings.weekday_period_to ? timeToScheduleTime(settings.weekday_period_to) : null,
    weekend_or_holiday_period_from: settings.weekend_or_holiday_period_from ? timeToScheduleTime(settings.weekend_or_holiday_period_from) : null,
    weekend_or_holiday_period_to: settings.weekend_or_holiday_period_to ? timeToScheduleTime(settings.weekend_or_holiday_period_to) : null,
  } as VoiceSettingsModel;
};
