import { useLocalI18n } from '@/hooks/useLocalI18n';
import {
  computed, h, onMounted, Ref, ref,
} from 'vue';
import { IDialogComponent, useDialog } from '@/hooks/useDialog';
import { useCourts } from '@/hooks/useCourts';
import {
  ActionType,
  ActiveTableAction,
  FetchFn,
  useActiveTable,
} from '@/components/activeTable/useActiveTable';
import StatusList from '@/components/dialog/dialogs/debtor/tabs/courts/StatusList.vue';
import {
  formatDateTime, formatDate, parseDateRU, dateToApiDate, parseIfDate,
} from '@/utils/date';
import { SignalType, useSignal } from '@/hooks/useSignal';
import {
  useFetchCourtsCases,
} from '@/components/dialog/dialogs/debtor/tabs/courts/useFetchCourtsCases';
import {
  CourtCase, CourtTypeTabKey, CourtCaseTracker,
} from '@/components/dialog/dialogs/debtor/tabs/courts/types';
import { useFilters } from '@/components/dialog/dialogs/debtor/tabs/courts/useFilters';
import { useColumns } from '@/components/dialog/dialogs/debtor/tabs/courts/useColumns';
import { getRandomString } from '@/utils/string';
import { useErrors } from '@/hooks/useErrors';
import { usePreventLeaveTrigger } from '@/hooks/usePreventLeave';
import { useToast } from '@/hooks/useToast';
import { useInjectDebtorDialog } from '@/components/dialog/dialogs/debtor/useInjectDebtorDialog';
import { useProtectedDefaultCompany } from '@/hooks/useProtectedDefaultCompany';
import { useCustomLocales } from '@/components/dialog/dialogs/debtor/useCustomLocales';

type Props = {
  activeTab: Ref<CourtTypeTabKey>;
  fetch: Ref<FetchFn<CourtCase> | undefined>;
}

export const useCourtsTable = ({ activeTab, fetch }: Props) => {
  const archive = !!fetch.value;
  const { t } = useLocalI18n('debtor.courts');
  const isLoading = ref<boolean>(false);
  const { filters } = useFilters(activeTab);
  const { showToast, showPureSuccessToast, showPureDangerToast } = useToast();
  const { errorsMap, setErrors } = useErrors<any>();
  const leaveController = usePreventLeaveTrigger();
  const { debtor } = useInjectDebtorDialog();
  const {
    companyId,
  } = useProtectedDefaultCompany();

  const {
    createCourtCase,
    updateCourtCase,
    createCourtCaseTracker,
    updateCourtCaseTracker,
    removeCourtCase,
  } = useCourts();

  const { showDialog, confirmDialog } = useDialog();

  const onRowClick = async (row: any) => {
    if (row.record.id === editingItem.value) {
      return;
    }

    if (row.record.type === 'court_case') {
      await showDialog({
        component: IDialogComponent.courtCaseDetails,
        addInRoute: false,
        payload: {
          courtCaseId: row.record.id,
        },
      });
    }
  };
  const editingItem = ref<CourtCase['id'] | null>(null);

  const add = async () => {
    const id = `new-${getRandomString()}`;
    const newCase = ({
      id,
      case_number: null,
      writ_number: null,
      filing_date: null,
      effective_decision_date: null,
      case_consideration_date: null,
      debt_period: [null, null],
      payment_document_number: null,
      payment_document_date: null,
      court_place: null,
      total_debt: null,
      debt: null,
      penalty: null,
      fee: null,
      paid_debt: null,
      paid_penalty: null,
      paid_fee: null,
      sittings: [],
      current_status: null,
      tracker: {
        id,
        court_order: null,
        current_status: null,
        company: companyId.value,
        debtor: debtor.value.debtor.pk,
        history: [],
        date_from: null,
        date_to: null,
      },
      debtor: debtor.value.debtor.pk,
    } as Pick<CourtCase, 'case_number'
      | 'writ_number'
      | 'filing_date'
      | 'effective_decision_date'
      | 'case_consideration_date'
      | 'debt_period'
      | 'payment_document_number'
      | 'payment_document_date'
      | 'court_place'
      | 'total_debt'
      | 'debt'
      | 'penalty'
      | 'fee'
      | 'paid_debt'
      | 'paid_penalty'
      | 'paid_fee'
      | 'sittings'
      | 'tracker'
    > as CourtCase);

    await saveOne();
    editingItem.value = id as unknown as CourtCase['id'];
    records.value.push(newCase);
    return newCase;
  };

  const saveToApiDate = (date: string | Date) => {
    return parseIfDate(date as string) ? date : dateToApiDate(date as Date);
  };

  const prepareModel = (model: CourtCase) => {
    const { id, tracker, ...restModel } = model;
    const prepModel: CourtCase = {
      ...restModel,
    };
    const deletableModelProps = ['expandRow', 'current_status', 'index', 'debt_period', 'payment_document_number', 'payment_document_date'];

    delete tracker.court_order;

    const prepModelTracker: CourtCaseTracker = {
      ...tracker,
      court_type: tracker.court_type || {
        magistrate: 'mirsud',
        regional: 'rajsud',
        arbitration: 'arbitr',
      }[activeTab.value],
      current_status: prepModel.current_status,
      filing_date: prepModel.filing_date,
      date_from: saveToApiDate(prepModel.debt_period[0]) ?? null,
      date_to: saveToApiDate(prepModel.debt_period[1]) ?? null,
      payment_document_date: saveToApiDate(prepModel.payment_document_date) ?? null,
      payment_document_number: prepModel.payment_document_number,
    };

    deletableModelProps.forEach((p) => delete prepModel[p]);

    return {
      courtCase: prepModel,
      courtTracker: prepModelTracker,
    };
  };

  const reset = async () => {
    leaveController.reset();
    editingItem.value = null;
  };

  const saveOne = async () => {
    if (!editingItem.value) {
      return;
    }
    const record = records.value.find(({ id }) => id === editingItem.value)!;

    const { courtCase, courtTracker } = prepareModel(record);
    if (!courtTracker.current_status) {
      showPureDangerToast({
        message: 'Необходимо выбрать статус дела',
      });
      return;
    }

    const { status, response } = await (record.id > 0
      ? updateCourtCaseTracker(courtTracker, courtTracker.id)
      : createCourtCaseTracker(courtTracker));

    if (!status) {
      return {
        response,
      };
    }

    const { status: caseStatus, response: caseResponse } = await (record.id > 0
      ? updateCourtCase({ ...courtCase, tracker: response.id }, record.id)
      : createCourtCase({ ...courtCase, tracker: response.id }));

    if (!caseStatus) {
      return {
        caseResponse,
      };
    }

    if (status && caseStatus) {
      record.tracker.id = response?.id;
      record.id = caseResponse?.id;
      await reset();
      await showPureSuccessToast({
        duration: 4000,
        params: { message: 'Данные сохранены' },
      });
    }
  };

  const {
    records,
    columns,
    actions,
    limit,
    page,
    total,
    fetchData: refetch,
  } = useActiveTable<CourtCase, CourtCase, 'id'>({
    keyField: 'id',
    defaultLimit: ref(100),
    fetch: fetch.value ?? useFetchCourtsCases(isLoading, archive),
    filters: filters(archive),
    columns: useColumns(computed(() => !!editingItem.value)),
    actions: computed(() => ([
      {
        key: 'edit',
        icon: 'pencil',
        label: 'Редактировать',
        types: [ActionType.record],
        async handler({ selectedItems: [itemId] }: { selectedItems: number[] }) {
          await saveOne();
          editingItem.value = itemId;
        },
        check: ({ record }: CourtCase) => editingItem.value !== record.id,
      },
      leaveController.isDirty && {
        key: 'done',
        icon: 'check',
        label: 'Сохранить',
        types: [ActionType.record],
        async handler() {
          await saveOne();
        },
        check: ({ record }: CourtCase) => editingItem.value === record.id,
      },
      {
        key: 'remove',
        icon: 'trash',
        label: 'Удалить',
        types: [ActionType.record],
        handler: async ({ selectedItems }: { selectedItems: number[] }) => {
          const recordId = selectedItems[0] as number;
          if (!recordId) {
            return;
          }
          const record = records.value.find((rec) => rec.id === recordId);
          const confirmDialogResult = await confirmDialog({
            key: 'remove_court_case',
            title: 'Подтверждение удаления',
            message: 'Удалить запись судебного дела?',
            confirmLabel: 'Подтвердить',
            cancelLabel: 'Отмена',
          });
          confirmDialogResult.closeDialog();
          if (!confirmDialogResult.result) {
            return;
          }
          const response = await removeCourtCase(record.id as number);
          if (response.status) {
            await refetch();
          }
        },
        check: ({ record }: CourtCase) => record.id > 0,
      },
    ].map((action, i) => ({
      ...action,
      id: `modal_debtor_courts_tab_delete_action_${i}`,
    }))) as Array<ActiveTableAction<any, any>>),
  });

  const { subscribeUntilUnmount } = useSignal();
  subscribeUntilUnmount(SignalType.debtorCourtCasesUpdated, refetch);

  const showRecordLog = async (record: any) => {
    await showDialog({
      component: IDialogComponent.content,
      addInRoute: false,
      payload: {
        title: t('column.status_history'),
        content: h(StatusList, {
          items: record.log?.map(
            ({ date, doc_found, state }: { date: string; doc_found: string ;state: string }) => ({
              label: formatDate(parseDateRU(date)),
              description: [doc_found, state].join(' '),
            }),
          ) || [],
        }),
      },
    });
  };

  return {
    t,
    onRowClick,
    isLoading,
    activeTab,
    records,
    columns,
    actions,
    limit,
    page,
    total,
    formatDateTime,
    showRecordLog,
    add,
    editingItem,
    errorsMap,
    leaveController,
  };
};
