export const enum AutoTabKey {
  commonP = 'commonP',
  text = 'text',
  voice = 'voice',
  claim = 'claim',
}

export const enum VoiceTabKey {
  Informing = 'voiceInforming',
  PaymentControl = 'voicePaymentControl'
}
