import { watchEffect, computed, Ref } from 'vue';
import { ActionType, ActiveTableAction } from '@/components/activeTable/useActiveTable';
import { SignalType, useSignal } from '@/hooks/useSignal';
import { getAuthProtectedFileUrl, openAuthProtectedFile } from '@/utils/fileUrl';
import { IDialogComponent, useDialog } from '@/hooks/useDialog';
import {
  useFeePaymentModel,
} from '@/components/dialog/dialogs/debtor/tabs/documents/useFeePaymentModel';
import {
  useDebtorCourtDecisionModel,
} from '@/components/dialog/dialogs/debtor/tabs/documents/useDebtorCourtDecisionModel';
import {
  useOssProtocolModel,
} from '@/components/dialog/dialogs/debtor/tabs/documents/useOssProtocolModel';
import {
  usePreloadedChargesModel,
} from '@/components/dialog/dialogs/debtor/tabs/documents/usePreloadedChargesModel';
import {
  usePreloadedPennyModel,
} from '@/components/dialog/dialogs/debtor/tabs/documents/usePreloadedPennyModel';
import {
  useEditDebtorCourtDecisionModel,
} from '@/components/dialog/dialogs/debtor/tabs/documents/useEditDebtorCourtDecision';
import { useInjectDebtorDialog } from '@/components/dialog/dialogs/debtor/useInjectDebtorDialog';
import { useDefaultCompanyPermissions } from '@/hooks/useDefaultCompanyPermissions';
import { ApiCommand } from '@/store/modules/api';
import { RemoveDocumentApiCommand, useDocumentsApi } from '@/hooks/useDocumentsApi';
import { useClaims } from '@/components/dialog/dialogs/debtor/tabs/documents/useClaims';
import {
  useClaimsApi,
} from '@/components/dialog/dialogs/debtor/tabs/documents/useClaimsApi';
import {
  useLoanAgreements,
} from '@/components/dialog/dialogs/debtor/tabs/documents/useLoanAgreements';
import {
  useDebtorPochtaModel,
} from '@/components/dialog/dialogs/debtor/tabs/documents/useDebtorPochtaModel';
import { isNumber } from '@/utils/number';
import {
  useDebtorIdentityModel,
} from '@/components/dialog/dialogs/debtor/tabs/documents/useDebtorIdenityModel';
import { useFeeReturnCertificate } from './useFeeReturnCertificate';

type ActiveTabRemoveDocumentKey = 'common'|'egrn_data'|'egrn_rights'|'my_documents'|'housebook'
  |'protocol_ooc'| 'guarantee_letter' | 'act'|'pochta'|'fee'|'preloaded_charges'|'preloaded_penny'
  |'invoice'|'settlement_statements'|'technical_passport'|'inspection_report'|'identity'
  |'inn_identity'|'bc_identity';

export const activeTabRemoveDocumentApiCommandMap = {
  common: ApiCommand.removeGeneralDocument,
  egrn_data: ApiCommand.removeEgrnDocument,
  egrn_rights: ApiCommand.removeEgrnTransferOfRights,
  my_documents: ApiCommand.removeMyDocument,
  protocol_ooc: ApiCommand.removeUniqueDebtorsDocument,
  guarantee_letter: ApiCommand.removeUniqueDebtorsDocument,
  preloaded_charges: ApiCommand.removeUniqueDebtorsDocument,
  preloaded_penny: ApiCommand.removeUniqueDebtorsDocument,
  act: ApiCommand.removeUniqueDebtorsDocument,
  invoice: ApiCommand.removeUniqueDebtorsDocument,
  settlement_statements: ApiCommand.removeUniqueDebtorsDocument,
  technical_passport: ApiCommand.removeUniqueDebtorsDocument,
  inspection_report: ApiCommand.removeUniqueDebtorsDocument,
  fee: ApiCommand.deleteFeePayment,
  pochta: ApiCommand.removeDebtorPochtaTrack,
  housebook: ApiCommand.removeHouseBookDocument,
  judgments: ApiCommand.removeDebtorCourtDecision,
  agreements: ApiCommand.deleteDebtorAgreement,
  claims: ApiCommand.removeDebtorClaim,
  loan_agreement: ApiCommand.removeUniqueDebtorsDocument,
  identity: ApiCommand.deleteIdentityDocument,
  inn_identity: ApiCommand.deleteInnIdentityDocument,
  bc_identity: ApiCommand.deleteBCIdentityDocument,
  sms: '',
} as Record<ActiveTabRemoveDocumentKey, RemoveDocumentApiCommand>;

export function useActions(
  activeTab: Ref,
  getRecords: () => Ref,
  companyId: Ref,
  getRefetchDocuments: any,
  t: (s: string) => string,
  selectFiles: any,
  customLocales: Ref<any>,
) {
  const { debtor, productionType } = useInjectDebtorDialog();
  const { hasWritePermission } = useDefaultCompanyPermissions(productionType);

  const {
    showDialog,
    confirmDialog,
  } = useDialog();

  const { dispatchSignal } = useSignal();
  const apiEndpoints = useDocumentsApi();

  const {
    openCreateDialog: showCreateFeePaymentDialog,
    openUpdateDialog: showUpdateFeePaymentDialog,
  } = useFeePaymentModel(debtor);

  const {
    openCreateDialog: showCreateDebtorPochtaTrackDialog,
    openUpdateDialog: showUpdateDebtorPochtaTrackDialog,
  } = useDebtorPochtaModel(
    computed(() => debtor.value?.debtor.pk),
    productionType,
  );

  const {
    openCreateDialog: showCreateDebtorIdentityDocumentDialog,
    openUpdateDialog: showUpdateDebtorIdentityDocumentDialog,
  } = useDebtorIdentityModel(
    computed(() => debtor.value?.debtor.pk),
    productionType,
    activeTab,
  );

  const {
    openCreateDialog: showCreateClaimDialog,
    openUpdateDialog: showUpdateClaimDialog,
  } = useClaims(
    {
      create: 'Добавление претензии',
      edit: 'Редактирование претензии',
      api: useClaimsApi,
      confirmationFile: true,
    },
  );

  const {
    openCreateDialog: showCreateFeeReturnCertificateDialog,
    openUpdateDialog: showUpdateFeeReturnCertificateDialog,
  } = useFeeReturnCertificate(
    {
      create: 'Добавление определения/справки на возврат ГП',
      edit: 'Редактирование определения/справки на возврат ГП',
    },
  );

  const {
    openCreateDialog: showCreateLaDialog,
    openUpdateDialog: showUpdateLaDialog,
  } = useLoanAgreements(
    {
      create: 'Добавление кредитного договора',
      edit: 'Редактирование кредитного договора',
    },
  );

  const {
    openCreateDialog: showCreatePreloadedChargesDialog,
    openUpdateDialog: showUpdatePreloadedChargesDialog,
  } = usePreloadedChargesModel();

  const { showDialog: showCreateOosProtocolDialog } = useOssProtocolModel();
  const { showDialog: showCreatePreloadedPennyDialog } = usePreloadedPennyModel();

  const courtDecisionStatusOptions = computed<{ label: string; value: string }[]>(() => Object.entries(customLocales.value.ru['debtor.court_decision.decisions'])
    .map(([key, value]) => ({
      label: value as string,
      value: key as string,
    })));

  const { showDialog: showDebtorCourtDecisionDialog } = useDebtorCourtDecisionModel(
    computed(() => debtor.value?.debtor.pk as unknown as string),
    productionType,
    courtDecisionStatusOptions,
  );
  const { showDialog: showEditCourtDecisionDialog } = useEditDebtorCourtDecisionModel(
    computed(() => debtor.value?.debtor.pk as unknown as string),
    productionType,
    courtDecisionStatusOptions,
  );

  const showEditAgreementDialog = ({ selectedItems }: { selectedItems: number[] }) => {
    return showDialog({
      component: IDialogComponent.debtorAgreement,
      addInRoute: false,
      payload: {
        companyId: companyId.value as number,
        debtorId: debtor.value?.debtor?.pk as number,
        agreementId: selectedItems[0],
      },
    });
  };

  const removeDocumentIsAvailable = computed(
    () => Object.keys(activeTabRemoveDocumentApiCommandMap).includes(activeTab.value),
  );

  function uploadAction(handler: () => Promise<any>) {
    return hasWritePermission.value && {
      key: 'upload',
      icon: 'upload',
      types: [ActionType.side],
      label: t('actions.housebook.upload'),
      handler: async () => {
        const response = await handler();
        if (response.status) {
          getRefetchDocuments()();
          await documentsUpdated();
        }
      },
    };
  }

  function editAction<Id>(handler: (id: Id) => Promise<any>) {
    return hasWritePermission.value && {
      key: 'edit',
      icon: 'pencil',
      types: [ActionType.record],
      label: t('actions.judgments.edit'),
      handler: async ({ selectedItems: [id] }: { selectedItems: Id[] }) => {
        const response = await handler(id);
        if (response.status) {
          getRefetchDocuments()();
          await documentsUpdated();
        }
      },
    };
  }

  return computed(() => (!debtor.value ? [] : (([
    hasWritePermission.value && activeTab.value === 'judgments' && {
      key: 'upload',
      icon: 'upload',
      types: [ActionType.side],
      label: t('actions.judgments.upload'),
      handler: async () => {
        const response = await showDebtorCourtDecisionDialog();
        if (response.status) {
          getRefetchDocuments()();
          await documentsUpdated();
        }
      },
    },
    hasWritePermission.value && activeTab.value === 'judgments' && {
      key: 'edit',
      icon: 'pencil',
      types: [ActionType.record],
      label: t('actions.judgments.edit'),
      handler: async ({ selectedItems: [id] }) => {
        const response = await showEditCourtDecisionDialog(id);
        if (response.status) {
          getRefetchDocuments()();
          await documentsUpdated();
        }
      },
    },
    hasWritePermission.value && activeTab.value === 'pochta' && {
      key: 'upload',
      icon: 'add',
      types: [ActionType.side],
      label: t('actions.pochta.upload'),
      // @ts-ignore
      handler: () => showCreateDebtorPochtaTrackDialog().then((result) => {
        if (result.status) {
          getRefetchDocuments()();
          documentsUpdated();
        }
      }),
    },
    hasWritePermission.value && activeTab.value === 'pochta' && {
      key: 'edit',
      icon: 'pencil',
      types: [ActionType.record],
      label: t('actions.pochta.upload'),
      handler: async ({ selectedItems: [id] }) => {
        await showUpdateDebtorPochtaTrackDialog(id);
      },
    },
    hasWritePermission.value && activeTab.value === 'identity' && uploadAction(showCreateDebtorIdentityDocumentDialog),
    hasWritePermission.value && activeTab.value === 'identity' && editAction<number>(showUpdateDebtorIdentityDocumentDialog),
    hasWritePermission.value && activeTab.value === 'bc_identity' && uploadAction(showCreateDebtorIdentityDocumentDialog),
    hasWritePermission.value && activeTab.value === 'bc_identity' && editAction<number>(showUpdateDebtorIdentityDocumentDialog),
    hasWritePermission.value && activeTab.value === 'inn_identity' && uploadAction(showCreateDebtorIdentityDocumentDialog),
    hasWritePermission.value && activeTab.value === 'inn_identity' && editAction<number>(showUpdateDebtorIdentityDocumentDialog),
    hasWritePermission.value && activeTab.value === 'housebook' && {
      key: 'upload',
      icon: 'upload',
      types: [ActionType.side],
      label: t('actions.housebook.upload'),
      handler: selectFiles,
    },

    activeTab.value === 'claims' && uploadAction(showCreateClaimDialog),
    hasWritePermission.value && activeTab.value === 'claims' && {
      key: 'edit',
      icon: 'pencil',
      types: [ActionType.record],
      label: t('actions.judgments.edit'),
      handler: async ({ selectedItems: [id] }) => {
        const response = await showUpdateClaimDialog(id);
        if (response.status) {
          getRefetchDocuments()();
          await documentsUpdated();
        }
      },
    },
    hasWritePermission.value && isNumber(activeTab.value) && {
      key: 'upload',
      icon: 'upload',
      types: [ActionType.side],
      label: t('actions.housebook.upload'),
      handler: selectFiles,
    },

    hasWritePermission.value && activeTab.value === 'fee_return_certificate' && uploadAction(showCreateFeeReturnCertificateDialog),
    hasWritePermission.value && activeTab.value === 'fee_return_certificate' && editAction<number>(showUpdateFeeReturnCertificateDialog),

    activeTab.value === 'loan_agreement' && uploadAction(showCreateLaDialog),
    activeTab.value === 'loan_agreement' && editAction<number>(showUpdateLaDialog),

    hasWritePermission.value && activeTab.value === 'fee' && {
      key: 'upload',
      icon: 'upload',
      types: [ActionType.side],
      label: t('actions.housebook.upload'),
      // @ts-ignore
      handler: () => showCreateFeePaymentDialog().then((result) => {
        if (result.status) {
          getRefetchDocuments()();
          documentsUpdated();
        }
      }),
    },
    hasWritePermission.value && activeTab.value === 'fee' && {
      key: 'edit',
      icon: 'pencil',
      types: [ActionType.record],
      label: 'Редактировать',
      handler: ({ selectedItems: [id] }) => showUpdateFeePaymentDialog(id).then((result) => {
        if (result.status) {
          getRefetchDocuments()();
          documentsUpdated();
        }
      }),
    },
    hasWritePermission.value && activeTab.value === 'preloaded_charges' && {
      key: 'edit',
      icon: 'pencil',
      types: [ActionType.record],
      label: 'Редактировать',
      handler: ({ selectedItems: [id] }) => showUpdatePreloadedChargesDialog(id).then((result) => {
        if (result.status) {
          getRefetchDocuments()();
          documentsUpdated();
        }
      }),
    },
    hasWritePermission.value
    && ['protocol_ooc'].includes(activeTab.value) && {
      key: 'upload',
      icon: 'upload',
      types: [ActionType.side],
      label: t('actions.protocol_ooc.upload'),
      handler: () => showCreateOosProtocolDialog().then((r) => {
        if (r.status) {
          getRefetchDocuments()();
          documentsUpdated();
        }
      }),
    },
    hasWritePermission.value
    && ['preloaded_charges'].includes(activeTab.value) && {
      key: 'upload',
      icon: 'upload',
      types: [ActionType.side],
      label: t('actions.preloaded_charges.upload'),
      handler: () => showCreatePreloadedChargesDialog().then((r) => {
        if (r.status) {
          getRefetchDocuments()();
          documentsUpdated();
        }
      }),
    },
    hasWritePermission.value
    && ['preloaded_penny'].includes(activeTab.value) && {
      key: 'upload',
      icon: 'upload',
      types: [ActionType.side],
      label: t('actions.preloaded_penny.upload'),
      handler: () => showCreatePreloadedPennyDialog().then((r) => {
        if (r.status) {
          getRefetchDocuments()();
          documentsUpdated();
        }
      }),
    },
    hasWritePermission.value && ['my_documents', 'guarantee_letter', 'act', 'invoice', 'settlement_statements', 'technical_passport', 'inspection_report'].includes(activeTab.value) && {
      key: 'upload',
      icon: 'upload',
      types: [ActionType.side],
      label: t('actions.my_documents.upload'),
      handler: selectFiles,
    },
    activeTab.value !== 'sms' && {
      key: 'preview',
      icon: 'eye',
      types: [ActionType.record],
      label: 'Предпросмотр',
      handler: async ({ selectedItems: [id] }) => {
        const item = getRecords().value.find((item: any) => item.id === id);
        if (!item) {
          return;
        }
        openAuthProtectedFile(item.file, false);
      },
    },
    activeTab.value === 'voice' && {
      key: 'listen',
      icon: 'megaphone',
      types: [ActionType.record],
      label: 'Прослушать',
      handler: async ({ selectedItems: [id] }) => {
        const item = getRecords().value.find((item: any) => item.id === id);
        if (!item) {
          return;
        }
        const { name = t('actions.listen') } = item;
        const url = await getAuthProtectedFileUrl(item.file, false);
        if (!url) {
          return;
        }
        await showDialog({
          component: IDialogComponent.listenFile,
          addInRoute: false,
          payload: {
            name,
            file: url,
          },
        });
      },
    },
    activeTab.value !== 'sms' && {
      key: 'download',
      icon: 'download',
      types: [ActionType.record],
      label: 'Скачать',
      handler: async ({ selectedItems: [id] }) => {
        const item = getRecords().value.find((item: any) => item.id === id);
        if (!item) {
          return;
        }
        openAuthProtectedFile(item.file, true);
      },
    },
    ...(
      hasWritePermission.value && activeTab.value === 'agreements'
        ? [
          {
            key: 'upload',
            icon: 'add',
            types: [ActionType.side],
            label: 'Добавить договор',
            handler: () => showDialog({
              component: IDialogComponent.debtorAgreement,
              addInRoute: false,
              payload: {
                companyId: companyId.value as number,
                debtorId: debtor.value?.debtor?.pk as number,
              },
            }),
          }, {
            key: 'upload',
            icon: 'pencil',
            types: [ActionType.record],
            label: 'Редактировать договор',
            handler: showEditAgreementDialog,
          },
        ]
        : []
    ),
    hasWritePermission.value && (removeDocumentIsAvailable.value || isNumber(activeTab.value)) && {
      key: 'remove',
      icon: 'trash',
      types: [ActionType.record],
      label: 'Удалить',
      handler: async ({ selectedItems: [id] }) => {
        const tab = activeTab.value as ActiveTabRemoveDocumentKey;
        const item = getRecords().value.find((item: any) => item.id === id);
        if (!item) {
          return;
        }
        const { result, closeDialog } = await confirmDialog({
          key: `remove_${activeTab.value}_document`,
          message: t('actions.remove'),
        });
        if (result) {
          if (isNumber(activeTab.value)) {
            await apiEndpoints.deleteDebtorCustomDocument(item.id);
          } else {
            await apiEndpoints.removeDocument(
              activeTabRemoveDocumentApiCommandMap[tab],
              { id: item.id, debtor_id: debtor.value?.debtor?.pk as number },
            );
          }
          await getRefetchDocuments()();
          documentsUpdated();
        }
        closeDialog();
      },
    },
  ] as Array<ActiveTableAction<any, any> | boolean>)
    .filter(Boolean)) as Array<ActiveTableAction<any, any>>).map((action) => ({
    ...action,
    id: `modal_debtor_documents_tab_${activeTab.value}_tab_${action.key as string}_${action.types[0]}_action`,
  })));

  function documentsUpdated() {
    setTimeout(() => dispatchSignal(SignalType.debtorDocumentsUpdated), 0);
  }
}
