import { PeriodicTaskSchedule } from '@/hooks/useSchedule';
import { PretrialAutomationModel } from '../../@x/pretrial-automation-model';
import { scheduleTimeToTime } from '../../lib/schedule-time';
import { VoiceSettingsModel } from '@/hooks/useCompanies';
import { isNil } from 'lodash';

export const toSettings = (model: PretrialAutomationModel) => ({
  ...model,
});

export const toVoiceSettings = (model: VoiceSettingsModel) => {
  const result = { ...model };
  const scheduleFields = [
    'weekday_period_from', 'weekday_period_to',
    'weekend_or_holiday_period_from', 'weekend_or_holiday_period_to',
  ] as const;

  scheduleFields.forEach((schedule) => {
    if (!isNil(model[schedule])) {
      result[schedule] = scheduleTimeToTime(model[schedule] as unknown as PeriodicTaskSchedule);
    } else if (schedule in model) {
      delete result[schedule];
    }
  });

  if (isNil(result.notifications_without_penalties)) {
    delete result.notifications_without_penalties;
  }

  return result;
};
