import { ActiveFormFieldType } from '@/hooks/useActiveForm';
import { Ref } from 'vue';
import { PretrialAutomationModel } from '../../@x/pretrial-automation-model';
import { AutoTabKey } from '../../@x/tabs';
import { ICheckboxState } from '@/components/checkbox/useCheckbox';
import { fieldsUniter } from '../../lib/field-group';

export const useClaim = (
  model: Ref<PretrialAutomationModel>,
  rootT: (s: string) => string,
) => fieldsUniter(AutoTabKey.claim, [
  {
    key: 'auto_create_email_notifications',
    field: 'auto_create_email_notifications',
    label: '',
    type: ActiveFormFieldType.checkbox,
    state: ['primary', 'horizontal'],
    options: {
      placeholder: null,
      allowEmpty: false,
      state: [ICheckboxState.primary, ICheckboxState.horizontal],
      options: [
        { value: true, label: rootT('other.yes') },
        { value: false, label: rootT('other.no') },
      ],
    },
  },
  {
    key: 'debt_notifications_period_email',
    field: 'debt_notifications_period_email',
    type: ActiveFormFieldType.schedule,
    state: ['primary'],
    options: {
      isDisabled: !model.value.auto_create_email_notifications,
      state: ['primary'],
    },
  },
]);
