import {
  Debtor,
  DebtorModel,
  DebtorOrganization,
  DebtorOrganizationModel,
  DebtorPerson, DebtorPersonMainProfile,
  DebtorPersonModel,
  DebtorPersonRegistrationObj,
  Phone,
  prepareSocialLinkToModel,
  prepareSocialLinkToPayload, Tenant,
} from '@/hooks/useDebtors';

export const debtorIsOrganization = (debtor: Debtor):
  debtor is DebtorOrganization => (
  !!debtor?.debtor_main_profile?.organization
);

const getEmptyPhoneNumber = () => ({
  number: '',
  is_valid: null,
} as any);

export const getEmptyPersonModel = () => ({
  status: '',
  personal_account: '',
  full_name: '',
  address: '',
  registration_address: '',
  emails: [],
  phone_numbers: [getEmptyPhoneNumber()],
  tenant_phone_numbers: [getEmptyPhoneNumber()],
  social_networks: [],
  tenantId: 0,
  isOrganization: false,
}) as DebtorPersonModel;

export const getEmptyOrganizationModel = () => ({
  personal_account: '',
  name: '',
  legal_address: '',
  physical_address: '',
  mail_address: '',
  emails: [],
  phone_numbers: [getEmptyPhoneNumber()],
  isOrganization: true,
}) as DebtorOrganizationModel;

export const getEmptyModel = (d: Debtor) => (debtorIsOrganization(d)
  ? getEmptyOrganizationModel()
  : getEmptyPersonModel());

export const getPreparedSocialNetworks = (p: DebtorPerson) => {
  const sn = p?.debtor_main_profile?.social_networks;
  if (!sn) return [];
  return Object.entries(sn).map(
    ([key, value]) => ({ key, value: prepareSocialLinkToModel(value) }),
  );
};

export const registrationObjToString = (
  obj: DebtorPersonRegistrationObj,
) => {
  if (obj.result || obj.postal_code) {
    return [obj.postal_code, obj.result].filter(Boolean).join(', ');
  }
  return obj.source || '';
};

export function mapDebtorPersonToModel(person: DebtorPerson): DebtorPersonModel {
  const profile = person.debtor_main_profile;
  const tenants = person._tenants ?? person.debtor_tenant_profiles;
  const { same: sameTenant } = groupTenants(profile, tenants);
  const phones = getTenantPhones(profile, tenants).map((x) => {
    const tenant = person.debtor_tenant_profiles.find((profile) => profile.id === x.debtor_tenant_profile);

    return {
      ...x,
      full_name: tenant?.full_name,
      contact: tenant?.contact,
    } as Phone;
  });

  const sameTenantPhones = sameTenant.phone_numbers.map((x) => {
    const tenant = person.debtor_tenant_profiles.find((profile) => profile.id === x.debtor_tenant_profile);

    return {
      ...x,
      full_name: tenant?.full_name,
      contact: tenant?.contact,
    } as Phone;
  });

  return {
    status: person?.debtor_main_profile.status ?? '',
    personal_account: person?.debtor.personal_account || '',
    full_name: person?.debtor_main_profile?.full_name || '',
    address: person?.debtor_main_profile?.address || '',
    registration_address: person?.debtor_main_profile?.registration_address_st || '',
    emails: person?.debtor_main_profile?.emails.map((e) => ({ email: e })) as Array<{ email: string }> || [],
    phone_numbers: sameTenantPhones,
    tenant_phone_numbers: phones,
    social_networks: getPreparedSocialNetworks(person),
    tenantId: sameTenant.id,
    isOrganization: false,
  };
}

export const mapOrgToModel = (org: DebtorOrganization): DebtorOrganizationModel => ({
  personal_account: org?.debtor?.personal_account || '',
  name: org?.debtor_main_profile?.organization.name || '',
  legal_address: org?.debtor_main_profile?.organization.legal_address || '',
  physical_address: org?.debtor_main_profile?.organization.physical_address || '',
  mail_address: org?.debtor_main_profile?.organization.mail_address || '',
  emails: org?.debtor_main_profile?.emails.map((e) => ({ email: e })) as Array<{ email: string }> || [],
  phone_numbers: org?.debtor_main_profile?.phone_numbers || [getEmptyPhoneNumber()],
  isOrganization: true,
});

export const mapDebtorToModel = (d: Debtor) => (debtorIsOrganization(d)
  ? mapOrgToModel(d)
  : mapDebtorPersonToModel(d));

export const mapDebtorModelToPayload = (m: DebtorModel) => {
  const { phone_numbers, emails, ...rest } = m;

  return {
    ...rest,
    emails: emails.map((obj) => Object.values(obj)[0]).filter(Boolean) || [],
    social_networks: (m as DebtorPersonModel).social_networks ? Object.fromEntries(
      (m as DebtorPersonModel).social_networks
        .filter(({ key, value }) => !!key && !!value)
        .map(
          ({
            key,
            value,
          }) => ([key, prepareSocialLinkToPayload(value as string)]),
        ),
    ) : [],
  };
};

export function groupTenants(profile: DebtorPersonMainProfile, tenants: Tenant[]) {
  const copy = [...tenants];
  const sameIndex = tenants.findIndex((t) => t.debtor === profile.debtor);
  if (sameIndex === -1) {
    return { same: { phone_numbers: [], id: 0 }, other: tenants };
  }
  const same = tenants[sameIndex] ?? {};
  copy.splice(sameIndex, 1);
  return { same, other: copy };
}

export function getTenantPhones(profile: DebtorPersonMainProfile, tenants: Tenant[]) {
  const { other } = groupTenants(profile, tenants);

  const result = other
    .map((item) => item.phone_numbers)
    .reduce((acc, phones) => ([...acc, ...phones]), []);

  return result;
}
