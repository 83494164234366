import { Module } from 'vuex';
import { EmployeeToDebtorAttach, CreateEmployeeToDebtorAttach } from '@/hooks/useEmployeeToDebtorAttach';
import { StoreState } from '@/store';
import {
  ApiCommand,
  ApiRequest,
  ApiResponse, ListingResponse,
} from '@/store/modules/api';
import { formatListingResponse } from '@/components/activeTable/useActiveTable';

export type EmployeeToDebtorAttachState = {
}

type EmployeeToDebtorAttachModule = Module<EmployeeToDebtorAttachState, StoreState>;

export const namespaced = true;

export const state: EmployeeToDebtorAttachModule['state'] = () => ({
});

export const getters: EmployeeToDebtorAttachModule['getters'] = {
};

export const mutations: EmployeeToDebtorAttachModule['mutations'] = {
};

export const actions: EmployeeToDebtorAttachModule['actions'] = {
  async fetchUsersForDebtor({ commit, dispatch }, id: number) {
    const { status, response } = (await dispatch('api/request', {
      command: ApiCommand.fetchUsersForDebtor,
      params: {
        debtor_data: id,
        limit: 1000,
        offset: 0,
      },
    } as ApiRequest, { root: true }));
    return { status, response: formatListingResponse(response) } as ApiResponse<ListingResponse<EmployeeToDebtorAttach>>;
  },
  async createEmployeeToDebtorAttach({ commit, dispatch }, model: CreateEmployeeToDebtorAttach) {
    const { status, response } = (await dispatch('api/request', {
      command: ApiCommand.attachUserToDebtor,
      data: model,
    } as ApiRequest<CreateEmployeeToDebtorAttach>, { root: true })) as ApiResponse<EmployeeToDebtorAttach>;

    return {
      status,
      response,
    } as ApiResponse<EmployeeToDebtorAttach>;
  },
  async removeEmployeeToDebtorBond({ commit, dispatch }, { id } : {id: number}) {
    const { status, response } = (await dispatch('api/request', {
      command: ApiCommand.removeUserToDebtorBond,
      params: { id },
    } as ApiRequest, { root: true }));

    return {
      status,
      response,
    };
  },
};
