import { computed, Ref } from 'vue';
import { PretrialAutomationModel } from '../@x/pretrial-automation-model';
import { useLocalI18n } from '@/hooks/useLocalI18n';
import { useI18n } from 'vue-i18n';
import { useFormEmployees } from '../../useEmployees';
import { useCustomLocales } from '@/components/dialog/dialogs/debtor/useCustomLocales';
import { useAsyncState } from '@vueuse/core';
import { useCommon } from './common';
import { useText } from './text';
import { useVoice } from './voice/voice';
import { useCommonP } from './common-p';
import { useClaim } from './claim/claim';
import { VoiceTabKey } from '../@x/tabs';
import { fieldPipeline } from '../lib/field-pipeline';
import { VoiceSettingsModel } from '@/hooks/useCompanies';

type State = {
  value: string;
  label: string;
};

export const useFields = (
  settingsModel: Ref<PretrialAutomationModel>,
  voiceModel: Record<VoiceTabKey, Ref<VoiceSettingsModel>>,
) => {
  const { t } = useLocalI18n('debtor.automatizing');
  const { t: rootT } = useI18n();
  const { onQueryEmployee, employees } = useFormEmployees();
  const { fetchVoiceStatuses } = useCustomLocales();

  const { state } = useAsyncState<State[]>(async () => {
    const options = await fetchVoiceStatuses();

    return options;
  }, []);

  const statusOptions = computed<State[]>(() => state?.value || []);

  const kvintOptions = computed<State[]>(() => ([
    {
      label: 'Уведомление о задолженности',
      value: '66c6d4650c0b2700329763b0',
    },
    {
      label: 'Уведомление о задолженности (собственники, перевод на оператора)',
      value: '66fbbc02153abde89e968201',
    },
  ]));

  const fields = computed(() => {
    const common = useCommon();
    const text = useText(settingsModel, rootT);
    const voice = useVoice(settingsModel, voiceModel, rootT, statusOptions, kvintOptions, onQueryEmployee, employees);
    const commonP = useCommonP(settingsModel, rootT);
    const claim = useClaim(settingsModel, rootT);

    const allFields = fieldPipeline([
      ...common,
      ...text,
      voice.voiceNotificationsEnabled,
      ...commonP,
      ...claim,
    ], t);

    const chapters = {
      voice: {
        default: voice.default,
        fields: fieldPipeline([
          ...voice.tabs[VoiceTabKey.Informing],
          ...voice.tabs[VoiceTabKey.PaymentControl],
        ], t),
      },
    };

    return {
      fields: allFields,
      chapters,
    };
  });

  return fields;
};
