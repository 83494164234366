import { Ref } from 'vue';
import { ActiveFormFieldType } from '@/hooks/useActiveForm';
import { OnQueryEmployee, PretrialAutomationModel } from '../../@x/pretrial-automation-model';
import { VoiceTabKey } from '../../@x/tabs';
import { ICheckboxState } from '@/components/checkbox/useCheckbox';
import { fieldsUniter } from '../../lib/field-group';
import { VoiceSettingsModel } from '@/hooks/useCompanies';
import { Employee } from '@/hooks/useEmployees';
import { ITextInputType } from '@/components/textInput/useTextInput';

export const useVoicePaymentControl = (
  settingsModel: Ref<PretrialAutomationModel>,
  voiceModel: Ref<VoiceSettingsModel>,
  rootT: (s: string) => string,
  options: Ref<Array<{label: string; value: string}>>,
  kvintOptions: Ref<Array<{label: string; value: string}>>,
  onQueryEmployee: OnQueryEmployee,
  employees: Ref<Employee[]>,
) => fieldsUniter(VoiceTabKey.PaymentControl, [

  {
    key: 'timezone_by_address',
    field: 'timezone_by_address',
    label: '',
    type: ActiveFormFieldType.checkbox,
    state: ['primary'],
    options: {
      isDisabled: !settingsModel.value.auto_create_voice_notifications,
      placeholder: null,
      allowEmpty: false,
      state: [ICheckboxState.primary, ICheckboxState.horizontal],
      options: [
        { value: true, label: rootT('other.yes') },
        { value: false, label: rootT('other.no') },
      ],
    },
  },
  {
    key: 'promised_payment_days',
    field: 'promised_payment_days',
    label: 'Дней от даты обещанного платежа',
    type: ActiveFormFieldType.range,
    state: ['primary'],
    options: {
      min: 0,
      max: 30,
      tooltipPosition: 'bottom',
      withManualInput: true,
      ignoreCheckManualLimits: true,
      isDisabled: !settingsModel.value.auto_create_voice_notifications,
    },
  },
  {
    key: 'debt_notifications_period_voice',
    type: ActiveFormFieldType.schedule,
    state: ['primary'],
    options: {
      state: ['primary'],
      isDisabled: !settingsModel.value.auto_create_voice_notifications,
    },
  },
  {
    key: 'week_days_label',
    type: 'label',
    options: { },
  },
  {
    key: 'weekend_days_label',
    type: 'label',
    options: { },
  },
  {
    key: 'notifications_by_period',
    field: 'notifications_by_period',
    label: '',
    type: ActiveFormFieldType.checkbox,
    state: ['primary'],
    options: {
      isDisabled: !settingsModel.value.auto_create_voice_notifications,
      placeholder: null,
      allowEmpty: false,
      state: [ICheckboxState.primary, ICheckboxState.horizontal],
      options: [
        { value: true, label: rootT('other.yes') },
        { value: false, label: rootT('other.no') },
      ],
    },
  },
  {
    key: 'weekday_period_from',
    field: 'weekday_period_from',
    type: ActiveFormFieldType.schedule,
    options: {
      state: [ICheckboxState.primary, ICheckboxState.horizontal],
      isDisabled: !settingsModel.value.auto_create_voice_notifications || !voiceModel.value.notifications_by_period,
      availableTabs: [],
      justTime: true,
    },
  },
  {
    key: 'weekday_period_to',
    field: 'weekday_period_to',
    type: ActiveFormFieldType.schedule,
    options: {
      state: ['primary', 'horizontal'],
      isDisabled: !settingsModel.value.auto_create_voice_notifications || !voiceModel.value.notifications_by_period,
      availableTabs: [],
      justTime: true,
    },
  },
  {
    key: 'weekend_or_holiday_period_from',
    field: 'weekend_or_holiday_period_from',
    type: ActiveFormFieldType.schedule,
    options: {
      state: ['primary', 'horizontal'],
      isDisabled: !settingsModel.value.auto_create_voice_notifications || !voiceModel.value.notifications_by_period,
      availableTabs: [],
      justTime: true,
    },
  },
  {
    key: 'weekend_or_holiday_period_to',
    field: 'weekend_or_holiday_period_to',
    type: ActiveFormFieldType.schedule,
    options: {
      state: ['primary', 'horizontal'],
      isDisabled: !settingsModel.value.auto_create_voice_notifications || !voiceModel.value.notifications_by_period,
      availableTabs: [],
      justTime: true,
    },
  },
  {
    key: 'enabling_statuses',
    field: 'enabling_statuses',
    type: ActiveFormFieldType.select,
    options: {
      multiple: true,
      isDisabled: !settingsModel.value.auto_create_voice_notifications,
      options: options.value,
    },
  },
  {
    key: 'dialing_redial_interval',
    field: 'dialing_redial_interval',
    type: ActiveFormFieldType.input,
    options: {
      isDisabled: !settingsModel.value.auto_create_voice_notifications,
      type: ITextInputType.number,
    },
  },
  {
    key: 'max_dialing_attempts',
    field: 'max_dialing_attempts',
    type: ActiveFormFieldType.input,
    options: {
      isDisabled: !settingsModel.value.auto_create_voice_notifications,
      type: ITextInputType.number,
    },
  },
  {
    key: 'request_recall_dialing_redial_interval',
    field: 'request_recall_dialing_redial_interval',
    type: ActiveFormFieldType.input,
    options: {
      isDisabled: !settingsModel.value.auto_create_voice_notifications,
      type: ITextInputType.number,
    },
  },
  {
    key: 'auto_exclude_numbers',
    field: 'auto_exclude_numbers',
    type: ActiveFormFieldType.checkbox,
    label: '',
    state: ['primary'],
    options: {
      isDisabled: !settingsModel.value.auto_create_voice_notifications,
      placeholder: null,
      allowEmpty: false,
      state: [ICheckboxState.primary, ICheckboxState.horizontal],
      options: [
        { value: true, label: rootT('other.yes') },
        { value: false, label: rootT('other.no') },
      ],
    },
  },
  {
    key: 'excluding_all_numbers_statuses',
    field: 'excluding_all_numbers_statuses',
    type: ActiveFormFieldType.select,
    options: {
      isDisabled: !settingsModel.value.auto_create_voice_notifications || !voiceModel.value.auto_exclude_numbers,
      options: options.value,
    },
  },
  {
    key: 'excluding_current_numbers_statuses',
    field: 'excluding_current_numbers_statuses',
    type: ActiveFormFieldType.select,
    options: {
      isDisabled: !settingsModel.value.auto_create_voice_notifications || !voiceModel.value.auto_exclude_numbers,
      options: options.value,
    },
  },
  {
    key: 'assigned_user',
    field: 'assigned_user',
    type: ActiveFormFieldType.select,
    options: {
      multiple: false,
      displayField: ['last_name', 'first_name'],
      valueField: 'id',
      options: employees.value,
      isDisabled: !settingsModel.value.auto_create_voice_notifications,
    },
    defaultValue: [],
    onQuery: onQueryEmployee,
  },
  {
    key: 'actual_debt',
    field: 'actual_debt',
    label: '',
    options: {
      isDisabled: !settingsModel.value.auto_create_voice_notifications,
    },
    type: ActiveFormFieldType.checkbox,
  },
  {
    key: 'consider_accruals',
    field: 'consider_accruals',
    label: '',
    type: ActiveFormFieldType.checkbox,
    state: ['primary'],
    options: {
      isDisabled: !settingsModel.value.auto_create_voice_notifications,
      placeholder: null,
      allowEmpty: false,
      state: [ICheckboxState.primary, ICheckboxState.horizontal],
      options: [
        { value: true, label: rootT('other.yes') },
        { value: false, label: rootT('other.no') },
      ],
    },
  },
  {
    key: 'transfer_to_operator_number',
    field: 'transfer_to_operator_number',
    type: ActiveFormFieldType.input,
    options: {
      isDisabled: !settingsModel.value.auto_create_voice_notifications,
    },
  },
  {
    key: 'kvint_campaign_id',
    field: 'kvint_campaign_id',
    type: ActiveFormFieldType.select,
    options: {
      multiple: false,
      allowEmpty: false,
      options: kvintOptions.value,
      isDisabled: !settingsModel.value.auto_create_voice_notifications,
    },
  },
]);
