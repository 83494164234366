import { useLocalI18n } from '@/hooks/useLocalI18n';
import { useInjectDebtorDialog } from '@/components/dialog/dialogs/debtor/useInjectDebtorDialog';
import {
  FetchEstateEncumbranceInfoResponse,
  FetchEstateRightInfoResponse,
  FetchStandardizedDebtorAddressesResponse,
  RosreestrEstateObjectCharacteristic as Characteristic, RosreestrEstateObjectMovement as Movement,
  useRosreestr,
  RosreestrEstateObjectOwner,
} from '@/hooks/useRosreestr';
import {
  isPassportNumber,
} from '@/components/dialog/dialogs/debtor/tabs/common/tabs/tenants/utils';
import { useDefaultCompanyPermissions } from '@/hooks/useDefaultCompanyPermissions';
import { computed, ref, watch } from 'vue';
import {
  ActiveTableColumnFormat, useActiveTable, ActionType, ActiveTableAction,
} from '@/components/activeTable/useActiveTable';
import { usePreventLeaveTrigger } from '@/hooks/usePreventLeave';
import { ActiveFormFieldType } from '@/hooks/useActiveForm';
import { dateToApiDate, formatDate } from '@/utils/date';
import { useDefaultCompany } from '@/hooks/useDefaultCompany';
import { IsIonicApp } from '@/symbols';
import { useProtectedInject } from '@/hooks/useProtectedInject';
import { useErrors } from '@/hooks/useErrors';
import { getRandomString } from '@/utils/string';
import { useDebtorCommonOwnersSave } from '../../useDebtorCommonOwnersSave';
import { IToastLevel, useToast } from '@/hooks/useToast';
import { IDialogComponent, useDialog } from '@/hooks/useDialog';
import { isNil } from 'lodash';

export const useDebtorCommonOwnersTab = () => {
  const { t } = useLocalI18n('debtor.common.owners');
  const { debtor, productionType } = useInjectDebtorDialog();
  const { companyId } = useDefaultCompany();
  const { hasWritePermission } = useDefaultCompanyPermissions(productionType);
  const isIonicApp = useProtectedInject(IsIonicApp);
  const { errorsMap, clearErrors, setErrors } = useErrors<any>();
  const { confirmDialog, showDialog } = useDialog();

  const {
    fetchEstateObjectCharacteristics,
    fetchEstateObjectMovements,
    fetchEstatesRightInfo,
    fetchEstatesEncumbranceInfo,
    fetchStandardizedDebtorAddresses,
  } = useRosreestr();
  const leaveController = usePreventLeaveTrigger();
  const {
    showPureSuccessToast, showPureDangerToast, showToast, closeToastById,
  } = useToast();
  const {
    saveCharacteristic, removeCharacteristic, saveMovement, removeMovement,
  } = useDebtorCommonOwnersSave();

  const isCadnumLoaded = ref(false);
  const cadnum = ref<FetchStandardizedDebtorAddressesResponse>();
  const rightInfo = ref<FetchEstateRightInfoResponse>();
  const encumbranceInfo = ref<FetchEstateEncumbranceInfoResponse>();
  const characteristicEditingItem = ref<Characteristic['id'] | null>(null);
  const movementEditingItem = ref<Movement['id'] | null>(null);
  const newItems = ref<string[]>([]);

  const registeredOwnershipTypesOptions = ref([
    { label: 'Собственность', value: 'Собственность' },
    { label: 'Совместная собственность', value: 'Совместная собственность' },
    { label: 'Долевая собственность', value: 'Долевая собственность' },
    { label: 'Оперативное управление', value: 'Оперативное управление' },
    { label: 'Информация отсутсвует', value: 'Информация отсутсвует' },
  ]);

  const fetchCadnum = async () => {
    if (debtor.value?.debtor_main_profile.id) {
      const { status, response } = await fetchStandardizedDebtorAddresses({
        id: debtor.value.debtor_main_profile.id,
      });
      if (!status) {
        return;
      }
      cadnum.value = response;

      isCadnumLoaded.value = true;
    }
  };

  const fetchRightInfo = async () => {
    if (!debtor.value?.debtor_main_profile?.cadnum) {
      await fetchCadnum();
    }
    if (companyId.value) {
      const { status, response } = await fetchEstatesRightInfo({
        company_id: companyId.value,
        cadnum: debtor.value?.debtor_main_profile?.cadnum ? debtor.value?.debtor_main_profile?.cadnum : cadnum.value?.cadnum,
      });
      if (!status) {
        return;
      }
      if (response) {
        rightInfo.value = response;
      }
    }
  };

  const fetchEncumbranceInfo = async () => {
    if (!debtor.value?.debtor_main_profile?.cadnum) {
      await fetchCadnum();
    }
    if (companyId.value) {
      const { status, response } = await fetchEstatesEncumbranceInfo({
        company_id: companyId.value,
        cadnum: debtor.value?.debtor_main_profile?.cadnum ? debtor.value?.debtor_main_profile?.cadnum : cadnum.value?.cadnum,
      });
      if (!status) {
        return;
      }
      if (response) {
        encumbranceInfo.value = response;
      }
    }
  };

  const saveOne = async (isCharacteristic = false, silent = false, shouldDelete = false) => {
    const editingItem = isCharacteristic ? characteristicEditingItem : movementEditingItem;
    if (!editingItem.value) {
      return;
    }

    const { id } = await showToast({
      level: IToastLevel.info,
      label: 'Сохранение...',
    });

    clearErrors();
    const records: any = isCharacteristic ? characteristicRecords.value : movementRecords.value;
    const action: any = isCharacteristic ? saveCharacteristic : saveMovement;
    const refetch: any = isCharacteristic ? fetchCharacteristics : fetchMovements;
    const record = records.find(({ id }: { id: number }) => id === editingItem.value)!;
    const newItemIndex = newItems.value.indexOf(record.id);

    const { errors, response: owner } = await action(record as any, debtor.value?.debtor?.pk, shouldDelete, newItemIndex > -1);

    if (errors.length === 0) {
      newItems.value.splice(newItemIndex, 1);

      await reset();
      await closeToastById(id);

      if (!silent) {
        await showPureSuccessToast({
          duration: 1000,
          params: { message: 'Данные сохранены' },
        });
      }

      if (newItemIndex > -1) await refetch();
    } else {
      await closeToastById(id);

      setErrors(errors);
    }

    return owner;
  };

  const add = async (isCharacteristic = true) => {
    const id = `new-${getRandomString()}`;

    newItems.value.push(id);

    const owner = ({
      id,
      owner_name: '',
      birth_place: '',
      num_of_passport: null,
      passport_issued_by: null,
      inn: null,
      registered_ownership_type: 'Информация отсутсвует',
      fraction_in_ownership: null,
      ownership_registration_number: null,
      birth_date: null,
      end_date: null,
      date_of_passport_issue: null,
      ownership_registration_date: null,
    } as Pick<RosreestrEstateObjectOwner,
        'owner_name'
        | 'birth_place'
        | 'num_of_passport'
        | 'passport_issued_by'
        | 'inn'
        | 'registered_ownership_type'
        | 'fraction_in_ownership'
        | 'ownership_registration_number'
        | 'birth_date'
        | 'end_date'
        | 'date_of_passport_issue'
        | 'ownership_registration_date'
        > as RosreestrEstateObjectOwner);

    const editingItem = isCharacteristic ? characteristicEditingItem : movementEditingItem;
    editingItem.value = id as unknown as RosreestrEstateObjectOwner['id'];

    const recordsToUse = isCharacteristic ? characteristicRecordsNorm : movementRecords;
    // @ts-ignore
    recordsToUse.value.push(owner);
    return owner;
  };

  const reset = async () => {
    leaveController.reset();
    characteristicEditingItem.value = null;
    movementEditingItem.value = null;
  };

  watch(debtor, () => {
    isCadnumLoaded.value = false;
    fetchCadnum();
    fetchRightInfo();
    fetchEncumbranceInfo();
  }, { immediate: true });

  const organizationColumns = ref([
    { key: 'owner_name', width: 2, is_show: true },
    { key: 'ogrn', field: 'organization.ogrn', is_show: true },
    { key: 'inn', field: 'organization.inn', is_show: true },
    { key: 'kpp', field: 'organization.kpp', is_show: true },
    {
      key: 'organization.ogrn_data_reg',
      field: 'organization.ogrn_data_reg',
      format: ActiveTableColumnFormat.date,
      is_show: true,
    },
    { key: 'full_name', field: 'full_name', is_show: true },
    { key: 'registered_ownership_type', width: 1.5, is_show: true },
    { key: 'fraction_in_ownership', is_show: true },
    { key: 'ownership_registration_date', format: ActiveTableColumnFormat.date, is_show: true },
    { key: 'ownership_registration_number', width: 2, is_show: true },
  ].map((column) => ({
    field: column.key,
    ...column,
    label: t(`column.${column.key.split('.').pop()}`),
  })));

  const columns = ref([
    { key: 'owner_name', width: 2, is_show: true },
    { key: 'birth_date', format: ActiveTableColumnFormat.date, is_show: true },
    { key: 'birth_place', is_show: true },
    { key: 'num_of_passport', is_show: true },
    { key: 'date_of_passport_issue', format: ActiveTableColumnFormat.date, is_show: true },
    { key: 'passport_issued_by', is_show: true },
    { key: 'inn', is_show: true },
    { key: 'registered_ownership_type', width: 1.5, is_show: true },
    { key: 'fraction_in_ownership', is_show: true },
    { key: 'ownership_registration_date', format: ActiveTableColumnFormat.date, is_show: true },
    { key: 'ownership_registration_number', width: 2, is_show: true },
  ].map((column) => ({
    ...column,
    field: column.key,
    label: t(`column.${column.key.split('.').pop()}`),
  })));

  const movementInitialColumns = ref([
    { key: 'owner_name', width: 2, is_show: true },
    { key: 'birth_date', format: ActiveTableColumnFormat.date, is_show: true },
    { key: 'birth_place', is_show: true },
    { key: 'num_of_passport', is_show: true },
    { key: 'date_of_passport_issue', format: ActiveTableColumnFormat.date, is_show: true },
    { key: 'passport_issued_by', is_show: true },
    { key: 'inn', is_show: true },
    { key: 'registered_ownership_type', width: 1.5, is_show: true },
    { key: 'fraction_in_ownership', is_show: true },
    { key: 'end_date', format: ActiveTableColumnFormat.date, is_show: true },
    { key: 'ownership_registration_date', format: ActiveTableColumnFormat.date, is_show: true },
    { key: 'ownership_registration_number', width: 2, is_show: true },
  ].map((column) => ({
    ...column,
    field: column.key,
    label: t(`column.${column.key.split('.').pop()}`),
  })));

  const requiredKeysFromTenant = [
    { key: 'owner_name', tenant_key: 'full_name' }, // ФИО
    { key: 'birth_date', tenant_key: 'birth_date' }, // Дата рождения
    { key: 'birth_place', tenant_key: 'birth_place' }, // Место рождения
    { key: 'num_of_passport', tenant_key: 'num_of_passport' }, // Серия и № паспорта
    { key: 'inn', tenant_key: 'inn' }, // ИНН
    { key: 'date_of_passport_issue', tenant_key: 'date_of_passport_issue' }, // Дата выдачи
    { key: 'passport_issued_by', tenant_key: 'passport_issued_by' }, // Кем выдан паспорт
    { key: 'passport_is_valid', tenant_key: 'passport_is_valid' },
  ];

  const fixCharacteristicInfo = (characteristic: Characteristic) => {
    const sameTenant = debtor.value.debtor_tenant_profiles.find((tenant) => tenant.full_name === characteristic.owner_name);

    if (!sameTenant) return characteristic;

    requiredKeysFromTenant.forEach((tenantKey) => {
      // @ts-ignore
      if (!characteristic[tenantKey.key]) {
        // @ts-ignore
        characteristic[tenantKey.key] = sameTenant[tenantKey.tenant_key];
      }
    });

    return characteristic;
  };

  const filters = computed(() => ([
    {
      key: 'debtor_id',
      field: 'debtor_id',
      type: ActiveFormFieldType.input,
      defaultValue: debtor.value?.debtor?.pk,
    },
    {
      key: 'active',
      field: 'active',
      type: ActiveFormFieldType.input,
      defaultValue: true,
    },
  ]));

  const {
    columns: characteristicColumns,
    records: characteristicRecords,
    isLoaded: isCharacteristicLoaded,
    fetchData: fetchCharacteristics,
  } = useActiveTable<Characteristic, Characteristic, 'id'>({
    keyField: 'id',
    fetch: async ({ params, signal }) => {
      if (!params.filters?.debtor_id) {
        return {
          count: 0,
          results: [],
        };
      }

      const requestPayload = { ...params, signal };

      if (requestPayload.filters && 'active' in requestPayload.filters) {
        delete requestPayload.filters.active;
      }

      // @ts-ignore
      const { response } = await fetchEstateObjectCharacteristics({ ...params, signal });

      return {
        ...response,
        results: response.results.map((result) => fixCharacteristicInfo(result)).filter(
          (r) => String(r.registered_ownership_type).toLowerCase() !== 'информация отсутсвует',
        ),
      };
    },
    columns: computed(() => columns.value),
    filters,
  });

  const {
    columns: movementColumns,
    records: movementRecords,
    fetchData: fetchMovements,
  } = useActiveTable<Movement, Movement, 'id'>({
    keyField: 'id',
    fetch: async ({ params, signal }) => {
      if (!params.filters?.debtor_id) {
        return {
          count: 0,
          results: [],
        };
      }
      const { response } = await fetchEstateObjectMovements({ ...params, signal });

      return {
        ...response,
        results: response.results.map((result: any) => fixCharacteristicInfo(result as any)) as any,
      };
    },
    columns: movementInitialColumns,
    filters,
  });

  const movementsRowHeaderIds = computed(() => (
    movementRecords.value.filter(({ date_from, id, date_to }, index) => {
      const uniqIndex = movementRecords.value.findIndex(
        (el, i) => {
          const dateToAreEqual = date_to && el.date_to && dateToApiDate(date_to) === dateToApiDate(el.date_to);
          const dateFromAreEqual = date_from && el.date_from && dateToApiDate(date_from) === dateToApiDate(el.date_from);
          return dateToAreEqual && dateFromAreEqual;
        },
      );
      return !!date_from && uniqIndex === index;
    }).map(({ id }) => id)
  ));

  const characteristicRecordsNorm = computed(
    () => characteristicRecords.value,
  );

  const characteristicsRowHeaderIds = computed(
    () => (characteristicRecordsNorm.value?.length
      ? [characteristicRecordsNorm.value[0]?.id]
      : []),
  );

  const characteristicsRow = computed(
    () => {
      if (!characteristicsRowHeaderIds.value.length) return null;
      return characteristicRecordsNorm.value[0];
    },
  );

  const characteristicActions = computed(() => ((([
    !isIonicApp && hasWritePermission.value && {
      key: 'edit',
      icon: 'pencil',
      label: 'Редактировать',
      types: [ActionType.record],
      async handler({ selectedItems: [itemId] }) {
        if (!isNil(characteristicEditingItem.value)) {
          await saveOne(true);
        }

        movementEditingItem.value = null;
        characteristicEditingItem.value = itemId;
      },
      check: ({ record: { id } }) => characteristicEditingItem.value !== id,
    },
    !isIonicApp && hasWritePermission.value && {
      key: 'done',
      icon: 'check',
      label: 'Сохранить',
      types: [ActionType.record],
      async handler() {
        await saveOne(true);
      },
      check: ({ record: { id } }) => characteristicEditingItem.value === id,
    },
    !isIonicApp && hasWritePermission.value && {
      key: 'remove',
      icon: 'close',
      label: 'Удалить',
      types: [ActionType.record],
      async handler({ selectedItems: [itemId] }) {
        const {
          result,
          closeDialog,
        } = await confirmDialog({
          title: t('actions.removeConfirmation'),
        });
        characteristicEditingItem.value = itemId;
        closeDialog();
        if (!result) {
          characteristicEditingItem.value = null;
          return;
        }

        if (characteristicEditingItem?.value?.toString().includes('new')) {
          await showPureSuccessToast({
            duration: 1000,
            params: { message: 'Собственник удален' },
          });
          const index = characteristicRecordsNorm.value.findIndex(({ id }) => id === itemId);
          characteristicRecordsNorm.value.splice(index, 1);
          characteristicEditingItem.value = null;
          await reset();
          return;
        }

        const { response } = await removeCharacteristic(characteristicEditingItem.value || 0);

        if (!response.status) {
          return showPureDangerToast({ params: { message: 'Ошибка при удалении собственника' } });
        }
        await showPureSuccessToast({
          duration: 1000,
          params: { message: 'Собственник удален' },
        });
        const index = characteristicRecordsNorm.value.findIndex(({ id }) => id === itemId);
        characteristicRecordsNorm.value.splice(index, 1);
        characteristicEditingItem.value = null;
        await reset();
      },
      // check: ({ record: { owner_name } }) => debtor.value.debtor_main_profile.full_name !== owner_name,
    },
  ] as Array<ActiveTableAction<any, any> | boolean>)
    .filter(Boolean) as Array<ActiveTableAction<any, any>>)
    .map((action) => ({
      ...action,
      id: `modal_debtor_common_tab_tenants_tab_${action.key}_${action.types[0]}_action`,
    }) as ActiveTableAction<any, any>)));

  const movementActions = computed(() => ((([
    !isIonicApp && hasWritePermission.value && {
      key: 'edit',
      icon: 'pencil',
      label: 'Редактировать',
      types: [ActionType.record],
      async handler({ selectedItems: [itemId] }) {
        if (!isNil(movementEditingItem.value)) {
          await saveOne();
        }

        characteristicEditingItem.value = null;
        movementEditingItem.value = itemId;
      },
      check: ({ record: { id } }) => movementEditingItem.value !== id,
    },
    !isIonicApp && hasWritePermission.value && {
      key: 'done',
      icon: 'check',
      label: 'Сохранить',
      types: [ActionType.record],
      async handler() {
        await saveOne();
      },
      check: ({ record: { id } }) => movementEditingItem.value === id,
    },
    !isIonicApp && hasWritePermission.value && {
      key: 'remove',
      icon: 'close',
      label: 'Удалить',
      types: [ActionType.record],
      async handler({ selectedItems: [itemId] }) {
        const {
          result,
          closeDialog,
        } = await confirmDialog({
          title: t('actions.removeConfirmation'),
        });
        movementEditingItem.value = itemId;
        closeDialog();
        if (!result) {
          movementEditingItem.value = null;
          return;
        }

        if (movementEditingItem?.value?.toString().includes('new')) {
          await showPureSuccessToast({
            duration: 1000,
            params: { message: 'Собственник удален' },
          });
          const index = movementRecords.value.findIndex(({ id }) => id === itemId);
          movementRecords.value.splice(index, 1);
          movementEditingItem.value = null;
          await reset();
          return;
        }

        const { response } = await removeMovement(movementEditingItem.value || 0);

        if (!response.status) {
          return showPureDangerToast({ params: { message: 'Ошибка при удалении собственника' } });
        }
        await showPureSuccessToast({
          duration: 1000,
          params: { message: 'Собственник удален' },
        });
        const index = movementRecords.value.findIndex(({ id }) => id === itemId);
        movementRecords.value.splice(index, 1);
        movementEditingItem.value = null;
        await reset();
      },
    },
  ] as Array<ActiveTableAction<any, any> | boolean>)
    .filter(Boolean) as Array<ActiveTableAction<any, any>>)
    .map((action) => ({
      ...action,
      id: `modal_debtor_common_tab_tenants_tab_${action.key}_${action.types[0]}_action`,
    }) as ActiveTableAction<any, any>)));

  const showCharacteristicCustomDialog = async () => {
    await showDialog({
      component: IDialogComponent.draggableTableColumns,
      payload: {
        columns: columns.value,
      },
      addInRoute: false,
    });
  };

  const showMovementCustomDialog = async () => {
    await showDialog({
      component: IDialogComponent.draggableTableColumns,
      payload: {
        columns: movementInitialColumns as unknown as any,
      },
      addInRoute: false,
    });
  };

  const characteristicVisibleColumns = computed(() => {
    return columns.value.filter((column) => column.is_show) || [];
  });

  const movementVisibleColumns = computed(() => {
    return movementInitialColumns.value.filter((column) => column.is_show) || [];
  });

  return {
    t,

    isCharacteristicLoaded,
    characteristicsRowHeaderIds,
    characteristicColumns,
    characteristicVisibleColumns,
    showCharacteristicCustomDialog,
    showMovementCustomDialog,
    characteristicRecords,
    characteristicRecordsNorm,
    characteristicActions,
    characteristicsRow,

    movementsRowHeaderIds,
    movementColumns,
    movementVisibleColumns,
    movementRecords,
    movementActions,

    formatDate,
    isPassportNumber,
    add,
    cadnum,
    isCadnumLoaded,
    debtor,

    rightInfo,
    encumbranceInfo,

    errorsMap,
    leaveController,

    characteristicEditingItem,
    movementEditingItem,

    hasWritePermission,
    registeredOwnershipTypesOptions,
  };
};
