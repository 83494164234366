import { computed, Ref } from 'vue';
import { DictDebtorSubstatus, DictType, useDicts } from '@/hooks/useDicts';
import { ProductionType } from '@/hooks/useConstructor';
import { useStore } from 'vuex';

type Type = {
  production_type: ProductionType;
  label: string;
  value: string;
};

export const FEES_NOT_PAID = 'fees_not_paid';
export const STATEMENT_NOT_ORDERED = 'statement_not_ordered';
export const STATEMENT_MISSING = 'statement_missing';
export const FEE_NOT_ORDERED = 'fee_not_ordered';

export function useStatusOptions(
  module: Ref<ProductionType>,
) {

  const store = useStore();
  const { getDict } = useDicts();

  const statusOptions = computed(() => {
    const companySubstatuses: DictDebtorSubstatus[] = store.getters['dicts/dicts'][DictType.companyDebtorSubstatuses];
    const substatuses: DictDebtorSubstatus[] = store.getters['dicts/dicts'][DictType.debtorSubstatusesForFilters];
    const claimSubstatuses: DictDebtorSubstatus[] = store.getters['dicts/dicts'][DictType.complaintTypes];
    const userStatuses: DictDebtorSubstatus[] = store.getters['dicts/dicts'][DictType.userDebtorStatuses];
    const mainProfileStatuses: DictDebtorSubstatus[] = store.getters['dicts/dicts'][DictType.debtorMainProfileStatuses];
    const resultSubstatuses = [...substatuses, ...claimSubstatuses];
    const dictionary = getDict<Type>(DictType.debtorStatusesForFilters).value;

    // Создаем новый массив для фильтрованного словаря
    const filteredDictionary = [];
    const seenValues = new Set();

    for (const item of dictionary) {
      let value = item.value;
      let label = item.label;

      // Преобразуем debtor_status в my_status
      if (value === 'my_status' || value === 'debtor_status' || value === 'user_status') {
        value = 'my_status';
        label = 'Пользовательский статус';
      }
      // Добавляем только если такого значения еще нет
      if (!seenValues.has(value)) {
        seenValues.add(value);
        filteredDictionary.push({
          ...item,
          value,
          label,
        });
      }
    }

    // Объединяем все пользовательские статусы в одну группу
    const allUserStatuses = [
      ...userStatuses.filter(
        (status) => !status.production_type || status.production_type === module.value,
      ),
      ...mainProfileStatuses.filter(
        (status) => !status.production_type || status.production_type === module.value,
      ),
    ];

    // Удаляем дубликаты статусов по значению
    const uniqueUserStatuses = allUserStatuses.reduce<any[]>((acc, current) => {
      const x = acc.find((item) => item.value === current.value);
      if (!x) {
        return acc.concat([current]);
      }
      return acc;
    }, []);

    const result = filteredDictionary
      .filter(({ production_type }) => !production_type || module.value === production_type)
      .reduce((acc, { label, value }) => {
        const st = { label, value } as any;
        let statusSubstatuses = resultSubstatuses.filter(({ status }) => status === value);
        if (value === 'my_status') {
          // Используем уникальные отфильтрованные статусы
          statusSubstatuses = uniqueUserStatuses;
        } else if (value === 'other') {
          statusSubstatuses = companySubstatuses.filter(({ status }) => status === 'other');
        }
        if (statusSubstatuses.length) {
          st.children = statusSubstatuses.reduce((acc, substatus) => {
            acc.push({
              ...substatus,
              label: substatus.label === 'Выписка из ЕГРН готова' ? 'Выписка из ЕГРН получена' : substatus.label,
            });

            // Добавление статусов на клиенте, которых нет в словаре сервера
            if (substatus.value === 'fees_paid') {
              acc.push({
                value: FEES_NOT_PAID,
                label: 'Пошлина не оплачена',
                info: 'Пошлина не оплачена',
                status: 'in_progress',
                group: 'court',
                production_type: ProductionType.judicial,
              });
              acc.push({
                value: FEE_NOT_ORDERED,
                label: 'Пошлина не была заказана',
                info: 'Пошлина не была заказана',
                status: 'in_progress',
                group: 'court',
                production_type: ProductionType.judicial,
              });
            }

            if (substatus.value === 'statement_ordered') {
              acc.push({
                value: STATEMENT_NOT_ORDERED,
                label: 'Выписка из ЕГРН не заказана',
                info: 'Выписка из ЕГРН не заказана',
                status: 'in_progress',
                group: 'statement',
                production_type: ProductionType.judicial,
              });
              acc.push({
                value: STATEMENT_MISSING,
                label: 'Выписка из ЕГРН отсутствует',
                info: 'Выписка из ЕГРН отсутствует',
                status: 'in_progress',
                group: 'statement',
                production_type: ProductionType.judicial,
              });
            }

            return acc;
          }, [] as DictDebtorSubstatus[]);
        }
        acc.push(st);
        return acc;
      }, [] as {
        label: string;
        value: string;
        children: { label: string; value: string }[];
      }[]);
    return result;
  });

  return { statusOptions };
}
