import { useModelDialog } from '@/hooks/useModelDialog';
import { Tenant, useDebtors } from '@/hooks/useDebtors';
import { useLocalI18n } from '@/hooks/useLocalI18n';
import { ActiveFormField, ActiveFormFieldType } from '@/hooks/useActiveForm';
import { computed } from 'vue';
import { DictType, useDicts } from '@/hooks/useDicts';
import { registrationOptions } from '@/components/dialog/dialogs/debtor/tabs/common/tabs/tenants/utils';
import { useInjectDebtorDialog } from '@/components/dialog/dialogs/debtor/useInjectDebtorDialog';
import { dateToApiDate } from '@/utils/date';

export const useTenantModelDialog = () => {
  const { t } = useLocalI18n('debtor.common.tenants');
  const { debtor, productionType } = useInjectDebtorDialog();

  const {
    getDict,
  } = useDicts();

  const relationshipsOptions = computed(() => (
    getDict(DictType.tenantRelationships).value
  ));

  const {
    createTenant,
    updateTenant,
  } = useDebtors();

  const fields = ([
    {
      key: 'full_name',
      type: ActiveFormFieldType.input,
    },
    {
      key: 'birth_date',
      type: ActiveFormFieldType.date,
      options: {
        serializeValue: true,
      },
    },
    {
      key: 'birth_place',
      type: ActiveFormFieldType.input,
    },
    {
      key: 'registration_address_st',
      type: ActiveFormFieldType.input,
    },
    {
      key: 'num_of_passport',
      type: ActiveFormFieldType.input,
    },
    {
      key: 'inn',
      type: ActiveFormFieldType.input,
    },
    {
      key: 'snils',
      type: ActiveFormFieldType.input,
    },
    {
      key: 'date_of_passport_issue',
      type: ActiveFormFieldType.date,
      options: {
        serializeValue: true,
      },
    },
    {
      key: 'passport_issued_by',
      type: ActiveFormFieldType.input,
    },
    {
      key: 'registration',
      type: ActiveFormFieldType.select,
      options: {
        options: registrationOptions,
      },
    },
    {
      key: 'registration_date',
      type: ActiveFormFieldType.date,
      options: {
        serializeValue: true,
      },
    },
    {
      key: 'relationships',
      type: ActiveFormFieldType.select,
      defaultValue: [],
      options: {
        options: relationshipsOptions,
      },
    },
  ]).map((field) => ({
    label: t(`column.${field.key}`),
    field: field.key,
    ...field,
    key: field.key as string,
  })) as ActiveFormField<Tenant>[];

  const mapFormToApiPayload = ({ sources, ...tenant }: Tenant) => ({
    ...tenant,
    birth_date: tenant.birth_date ? dateToApiDate(new Date(tenant.birth_date) as Date) : null,
    date_of_passport_issue: tenant.date_of_passport_issue ? dateToApiDate(new Date(tenant.date_of_passport_issue) as Date) : null,
    registration_date: tenant.registration_date ? dateToApiDate(new Date(tenant.registration_date) as Date) : null,
    deregistration_date: tenant.deregistration_date ? dateToApiDate(new Date(tenant.deregistration_date) as Date) : null,
  }) as Tenant;

  const { showDialog: showCreateTenantDialog } = useModelDialog<
    Tenant,
    Tenant
    >({
      title: 'Добавление жильца',
      fields,
      onModelUpdate: async (payload) => createTenant({
        debtor: debtor.value.debtor.pk,
        productionType: productionType.value,
        model: mapFormToApiPayload(payload),
      }),
    });

  const { showDialog: showUpdateTenantDialog } = useModelDialog<
    Tenant,
    Tenant,
    Tenant
    >({
      getForm: (payload) => Promise.resolve({
        status: true,
        response: ({
          ...payload,
          relationships: payload.relationships ?? [],
        }),
      }),
      title: 'Редактирование жильца',
      fields,
      onModelUpdate: async (payload, initPayload) => updateTenant({
        id: initPayload.id,
        productionType: productionType.value,
        model: mapFormToApiPayload(payload),
      }),
    });

  return {
    showCreateTenantDialog,
    showUpdateTenantDialog,
  };
};
