import { useStore } from 'vuex';
import { ApiResponse, ListingResponse } from '@/store/modules/api';

export type EmployeeToDebtorAttach = {
  id: number;
  user: number;
  debtor: number;
}

export type CreateEmployeeToDebtorAttach = {
  user: number;
  debtor_data: number;
}

export const useEmployeeToDebtorAttach = () => {
  const store = useStore();

  const getUsersForDebtor = async (id: number) : Promise<ApiResponse<ListingResponse<EmployeeToDebtorAttach>>> => {
    const { status, response } = await store.dispatch('employeeToDebtorAttach/fetchUsersForDebtor', id);
    return { status, response };
  };

  const addBond = async (model: CreateEmployeeToDebtorAttach) : Promise<ApiResponse<EmployeeToDebtorAttach>> => {
    const { status, response } = await store.dispatch('employeeToDebtorAttach/createEmployeeToDebtorAttach', model);
    return { status, response };
  };

  const removeBond = async (id: number) :Promise<ApiResponse<null>> => {
    const { status, response } = await store.dispatch('employeeToDebtorAttach/removeEmployeeToDebtorBond', { id });
    return { status, response };
  };

  return {
    getUsersForDebtor,
    addBond,
    removeBond,
  };
};
