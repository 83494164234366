import {
  Ref, ref, SetupContext, watch,
} from 'vue';
import { useLocalI18n } from '@/hooks/useLocalI18n';
import { useCompanySettings } from '@/components/automation/useCompanySettings';
import { useProtectedDefaultCompany } from '@/hooks/useProtectedDefaultCompany';
import {
  CompanySettings, useCompanies, VoiceSettingsModel, VoiceSettingsType,
} from '@/hooks/useCompanies';
import { PeriodicTaskSchedule, useSchedule } from '@/hooks/useSchedule';
import { SourceErrors, useErrors } from '@/hooks/useErrors';
import { envIsDev } from '@/utils/env';
import { ActiveFormField, ActiveFormFieldType } from '@/hooks/useActiveForm';
import { usePreventLeaveTrigger } from '@/hooks/usePreventLeave';
import { IToastLevel, useToast } from '@/hooks/useToast';
import { useVoiceTabs } from './voice/voice-tabs';
import { createEmptyFormModel } from './form-model/create-empty-form-model';
import { toFormModel, toVoiceFormModel } from './form-model/to-form-model';
import { CompanySettingsPretrial, PretrialAutomationModel } from '../@x/pretrial-automation-model';
import { toSettings, toVoiceSettings } from './form-model/to-settings';
import { useTabs } from './tabs';
import { AutoTabKey, VoiceTabKey } from '../@x/tabs';
import { createEmptyVoiceSettingsModel } from './voice/create-empty-voice-settings-model';
import { isNil } from 'lodash';

const voiceTabTypeMap = {
  [VoiceTabKey.Informing]: VoiceSettingsType.Informing,
  [VoiceTabKey.PaymentControl]: VoiceSettingsType.ControlOfPayment,
};

const voiceTypeTabMap = {
  [VoiceSettingsType.Informing]: VoiceTabKey.Informing,
  [VoiceSettingsType.ControlOfPayment]: VoiceTabKey.PaymentControl,
};

export const useAutomationPretrial = (emit: SetupContext['emit'] | any) => {
  const leaveController = usePreventLeaveTrigger();
  const { t } = useLocalI18n('debtor.automatizing');
  const isLoading = ref(true);
  const { showToast } = useToast();
  const { errorsMap, setErrors, clearErrors } = useErrors(true);

  const { companySettings } = useCompanySettings();
  const model = ref(createEmptyFormModel());
  const { companyId } = useProtectedDefaultCompany();
  const {
    updateCompanySettings,
    fetchVoiceSettings,
    createVoiceSettings,
    updateVoiceSettingsById,
  } = useCompanies();
  const scheduleApi = useSchedule();

  const voiceModel: Record<VoiceTabKey, Ref<VoiceSettingsModel>> = {
    [VoiceTabKey.Informing]: ref(createEmptyVoiceSettingsModel(companyId.value, VoiceSettingsType.Informing)),
    [VoiceTabKey.PaymentControl]: ref(createEmptyVoiceSettingsModel(companyId.value, VoiceSettingsType.ControlOfPayment)),
  };

  const tabs = useTabs(model, voiceModel);
  const voiceTabs = useVoiceTabs(model, voiceModel);

  const updateModel = (companySettings: CompanySettingsPretrial) => {
    model.value = toFormModel(companySettings);
  };

  const reset = () => {
    leaveController.reset();
  };

  async function prepareVoiceSettings(activeTab: VoiceTabKey) {
    isLoading.value = true;

    const currentType = voiceTabTypeMap[activeTab];
    const apiResponse = await fetchVoiceSettings(companyId.value, currentType);

    if (!apiResponse.status) {
      await showToast({
        label: 'Ошибка получения настроек голосовых уведомлений, попробуйте еще раз',
        level: IToastLevel.danger,
      });
    } else if (apiResponse.response.results && apiResponse.response.count > 0) {
      apiResponse.response.results.forEach((data) => {
        const tab = voiceTypeTabMap[data.type];

        if (tab) {
          voiceModel[tab].value = toVoiceFormModel(data);

          console.log('DEBUG', data, toVoiceFormModel(data));
        }
      });
    }

    const scheduleResponse = await scheduleApi.task.getList();

    if (!scheduleResponse.status) {
      isLoading.value = false;

      return;
    }

    const taskPeriod = scheduleResponse.response.find((task) => task.name === 'pretrial.auto_create_voice_notifications');

    if (taskPeriod) {
      voiceModel[activeTab].value.debt_notifications_period_voice = (taskPeriod as any).schedule;
    }

    isLoading.value = false;
  }

  watch(() => tabs.activeTab.value, async (activeTab) => {
    if (activeTab === AutoTabKey.voice) {
      await prepareVoiceSettings(voiceTabs.activeTab.value);
    }
  });

  watch(() => voiceTabs.activeTab.value, prepareVoiceSettings);

  watch(() => companySettings.value, async (companySettings) => {
    if (companySettings) {
      isLoading.value = true;
      const settingsCopied = JSON.parse(
        JSON.stringify(companySettings),
      ) as CompanySettingsPretrial;
      const response = await scheduleApi.task.getList();

      if (!response.status) {
        isLoading.value = false;
        return;
      }

      const prepareTaskByName = (
        taskName: string,
        taskField: string,
      ) => {
        const taskPeriod = response.response.find(
          (task) => task.name === taskName,
        );
        if (taskPeriod) {
          // @ts-ignore
          settingsCopied[taskField] = taskPeriod.schedule;
        }
      };

      prepareTaskByName(
        'pretrial.auto_create_sms_notifications',
        'debt_notifications_period_sms',
      );

      prepareTaskByName(
        'pretrial.auto_create_email_notifications',
        'debt_notifications_period_email',
      );

      updateModel({
        ...settingsCopied,
        court_threshold: settingsCopied.court_threshold ? settingsCopied.court_threshold : 0,
      });

      isLoading.value = false;
    }
  });
  const timeRegExp = new RegExp(/\b[0-2]?\d:[0-5]\d\b/);

  const onSubmit = async () => {
    if (isLoading.value) {
      return;
    }
    isLoading.value = true;
    clearErrors();
    const errors: SourceErrors<keyof PretrialAutomationModel> = [];

    if (errors.length) {
      setErrors(errors);
      isLoading.value = false;
      return;
    }

    const voiceTabType = voiceTabTypeMap[voiceTabs.activeTab.value];
    const preparedModel = toSettings(model.value);
    const preparedVoiceModel = toVoiceSettings(voiceModel[voiceTabs.activeTab.value].value);

    const companySettingsResponse = await updateCompanySettings({
      id: companyId.value,
      payload: preparedModel as unknown as Partial<CompanySettings>,
    });

    if (!companySettingsResponse.status) {
      await showToast({
        label: 'Ошибка сохранения, попробуйте еще раз',
        level: IToastLevel.danger,
      });
      isLoading.value = false;
    }

    if (tabs.activeTab.value === AutoTabKey.voice) {
      if (isNil(preparedVoiceModel.id)) {
        const voiceSettingsResponse = await createVoiceSettings(companyId.value, {
          ...preparedVoiceModel,
          company: companyId.value,
          type: voiceTabType,
        });

        if (!voiceSettingsResponse.status) {
          await showToast({
            label: 'Ошибка сохранения, попробуйте еще раз',
            level: IToastLevel.danger,
          });
          isLoading.value = false;
        }
      } else {
        const voiceSettingsResponse = await updateVoiceSettingsById(
          preparedVoiceModel.id,
          companyId.value,
          {
            ...preparedVoiceModel,
            company: companyId.value,
            type: voiceTabType,
          },
        );

        if (!voiceSettingsResponse.status) {
          await showToast({
            label: 'Ошибка сохранения, попробуйте еще раз',
            level: IToastLevel.danger,
          });
          isLoading.value = false;
        }
      }
    }

    const tasksResponse = await scheduleApi.task.getList();
    if (!tasksResponse.status) {
      isLoading.value = false;
      return;
    }

    const processTasks = async (
      taskName: string,
      taskField: string,
      model: PretrialAutomationModel | VoiceSettingsModel,
      extra: Record<string, string> = {},
    ) => {
      // @ts-ignore
      const taskSchedule = model[taskField] as PeriodicTaskSchedule;

      const tasksToDelete = tasksResponse.response.filter(
        (task) => task.name === taskName,
      );
      if (tasksToDelete.length) {
        await Promise.all(
          tasksToDelete.map((task) => scheduleApi.task.delete(task.id)),
        );
      }

      if (taskSchedule) {
        await scheduleApi.task.create({
          name: taskName,
          extra,
          schedule: taskSchedule as PeriodicTaskSchedule,
          ...(taskName === 'pretrial.auto_create_debt_notifications' ? {
            ...((model as PretrialAutomationModel).debt_notification_failed_attempts
              ? { count: (model as PretrialAutomationModel).debt_notification_failed_attempts }
              : {}
            ),
            interval: {
              hours: 3,
              minutes: 0,
            },
            // сделать лимит только на деве
            ...(envIsDev ? {
              limit: {
                hours: 22,
                minutes: 0,
              },
            } : {}),
          } : {}),
        });
      }
    };

    await processTasks(
      'pretrial.auto_create_sms_notifications',
      'debt_notifications_period_sms',
      preparedModel,
    );

    await processTasks(
      'pretrial.auto_create_email_notifications',
      'debt_notifications_period_email',
      preparedModel,
    );

    await processTasks(
      'pretrial.auto_create_voice_notifications',
      'debt_notifications_period_voice',
      preparedVoiceModel,
      { type: voiceTabType },
    );

    leaveController.reset();
    emit('close');
    isLoading.value = false;
  };

  const onUpdatePretrialModelValue = (
    $event: any, fieldConfig: ActiveFormField<PretrialAutomationModel>,
  ) => {
    if (fieldConfig.onUpdateModelValue) {
      fieldConfig.onUpdateModelValue(model.value, $event);
    }
    // @ts-ignore
    model.value[fieldConfig.key] = $event;
    leaveController.markDirty();
  };

  const onUpdateVoiceModelValue = (
    $event: any, fieldConfig: ActiveFormField<VoiceSettingsModel>,
  ) => {
    if (fieldConfig.onUpdateModelValue) {
      fieldConfig.onUpdateModelValue(voiceModel[voiceTabs.activeTab.value].value, $event);
    }
    // @ts-ignore
    model.value[fieldConfig.key] = $event;
    leaveController.markDirty();
  };

  const claimTab = ref(1);

  const claimTabs = [
    {
      key: 0,
      label: 'I',
      id: 'modal_automation_pretrial_claim_tab_0',
    },
    {
      key: 1,
      label: 'II',
      id: 'modal_automation_pretrial_claim_tab_1',
    },
    {
      key: 2,
      label: 'III',
      id: 'modal_automation_pretrial_claim_tab_2',
    },
  ];

  return {
    ...tabs,
    t,
    voiceModel,
    voiceTabs,
    ActiveFormFieldType,
    model,
    reset,
    onSubmit,
    onUpdatePretrialModelValue,
    onUpdateVoiceModelValue,
    leaveController,
    isLoading,
    errorsMap,
    claimTab,
    claimTabs,
  };
};
