import { computed, Ref } from 'vue';
import { ActiveTableColumn, ActiveTableColumnFormat } from '@/components/activeTable/useActiveTable';
import { capitalizeFirstLetter } from '@/utils/string';
import { useLocalI18n } from '@/hooks/useLocalI18n';
import { Debtor } from '@/hooks/useDebtors';
import { FeePayment, ActiveTabKey } from '@/components/dialog/dialogs/debtor/tabs/documents/types';
import {
  DebtorPochtaTrack,
} from '@/hooks/useDocumentsApi';

const Format = ActiveTableColumnFormat;

function getHousebookColumns() {
  return [
    { key: 'id', width: '150px' },
    { key: 'file', width: 'minmax(300px, 1.5fr)' },
    { key: 'document_formation_date', format: Format.dateTime, label: 'Дата загрузки' },
  ];
}

function getClaimsColumns() {
  return [
    { key: 'id', width: '150px' },
    { key: 'file', width: 'minmax(300px, 1.5fr)' },
    { key: 'confirmation_file', width: 'minmax(300px, 1.5fr)', label: 'Файл подтверждения' },
    { key: 'is_loaded_manually', label: 'Загружен вручную' },
    { key: 'created_at', format: Format.dateTime, label: 'Дата загрузки' },
  ];
}

function getEgrnColumns() {
  return [
    { key: 'id', width: '150px' },
    { key: 'file', width: 'minmax(300px, 1.5fr)' },
    { key: 'created_at', format: Format.dateTime, label: 'Дата запроса' },
    { key: 'status_tracking' },
    { key: 'status_history' },
  ];
}

function getFeeColumns(debtor: Ref<Debtor>) {
  return [
    { key: 'id', width: '150px' },
    { key: 'status', width: '150px', label: 'Статус' },
    { key: 'file', width: 'minmax(200px, 1.5fr)' },
    { key: 'document_number' },
    { key: 'document_date', format: Format.date },
    {
      key: 'tenant_name',
      getValue: (item: FeePayment) => getTenantFio(debtor.value, item),
    },
    {
      key: 'owner_name',
      getValue: (item: FeePayment) => item.estate_owner_name,
    },
    { key: 'document_amount', format: Format.money, label: 'Сумма пошлины' },
  ];
}

function getJudgementsColumns() {
  return [
    { key: 'id', width: '150px' },
    { key: 'decision' },
    { key: 'debt_period' },
    { key: 'file' },
    { key: 'debtor_name' },
    { key: 'amount', format: Format.money },
    { key: 'penalties', format: Format.money },
    { key: 'duty', format: Format.money },
    { key: 'total_amount', format: Format.money },
    { key: 'case_number' },
    { key: 'decision_date', format: Format.date },
  ];
}

function getSmsAndVoiceColumns(activeTab: ActiveTabKey) {
  const onlySms = [
    { key: 'service_cost', format: Format.money },
    { key: 'message', width: 2 },
  ];
  const onlyVoice = [
    { key: 'cost', format: Format.money },
    { key: 'duration' },
  ];
  return [
    { key: 'phone_number', format: Format.phone },
    { key: 'operator.name' },
    { key: 'send_at', format: Format.dateTime },
    { key: 'status_at', format: Format.dateTime },
    { key: 'status_text' },
    ...(activeTab === 'sms' ? onlySms : []),
    ...(activeTab === 'voice' ? onlyVoice : []),
  ];
}

function getOssColumns() {
  return [
    { key: 'id', field: 'number', width: '100px' },
    { key: 'service_providers', field: 'service_providers', label: 'ИКУ' },
    { key: 'application_number', field: 'number', label: 'Номер обращения' },
    { key: 'application_date', field: 'date', label: 'Дата обращения' },
    { key: 'application_period', label: 'Период обращения' },
    { key: 'management_company_name', label: 'Наименование УК' },
    {
      key: 'date', field: 'date', width: '150px', format: Format.date,
    },
    { key: 'file', width: 'minmax(300px, 1.5fr)' },
  ];
}

function getIdentityColumns() {
  return [
    { key: 'id', field: 'id', width: '50px' },
    { key: 'file', width: 'minmax(300px, 1.5fr)' },
    {
      key: 'updated_at',
      width: '150px',
      format: Format.dateTime,
      label: 'Дата и время изменения',
    },
    {
      key: 'downloaded_at',
      width: '150px',
      format: Format.dateTime,
      label: 'Дата и время синхронизации',
    },
  ];
}

export function useDocumentsColumns(activeTab: Ref, debtor: Ref<Debtor>) {

  const { t } = useLocalI18n('debtor.documents');
  const statusColumn = !['common', 'agreements', 'my_documents', 'pochta', 'fee', 'fssp'];

  const otherTabsColumns = () => (([
    !['preloaded_charges', 'preloaded_penny', 'agreements']
      .includes(activeTab.value) && { key: 'id', width: '150px' },

    ['preloaded_charges', 'preloaded_penny', 'agreements']
      .includes(activeTab.value) && { key: 'id', field: 'number', width: '150px' },

    ['preloaded_charges', 'preloaded_penny'].includes(activeTab.value) && {
      key: 'date', field: 'date', width: '150px', format: Format.date,
    },
    ['fssp'].includes(activeTab.value) && {
      key: 'order_id',
    },
    ['fssp'].includes(activeTab.value) && {
      key: 'request_name',
      width: 'minmax(300px, 1.5fr)',
    },
    ['fssp'].includes(activeTab.value) && {
      key: 'request_type',
      width: 'minmax(100px, 1fr)',
    },
    ['fssp'].includes(activeTab.value) && {
      key: 'response',
      label: 'Текст ответа',
      width: 'minmax(400px, 2fr)',
    },
    !['fns', 'banks', 'executions'].includes(activeTab.value) && {
      key: 'file',
      width: 'minmax(300px, 1.5fr)',
    },
    ['pochta'].includes(activeTab.value) && {
      key: 'file_f103',
      width: 'minmax(300px, 1.5fr)',
    },
    ['pochta'].includes(activeTab.value) && {
      key: 'estate_object',
      width: 'minmax(200px, 1fr)',
      getValue: (item: DebtorPochtaTrack) => item.estate_object,
    },
    ['common'].includes(activeTab.value) && {
      key: 'document_formation_date',
      format: ActiveTableColumnFormat.dateTime,
    },
    statusColumn && {
      key: 'status',
      format: (status: string|null) => (status ? capitalizeFirstLetter(status) : null),
    },
    ['common'].includes(activeTab.value) && { key: 'status_name' },

    ['photos'].includes(activeTab.value) && {
      key: 'created_at',
      format: Format.dateTime,
    },

    ['fns', 'banks'].includes(activeTab.value) && { key: 'request_id' },
    ['fns'].includes(activeTab.value) && { key: 'document_title' },
    ['fns', 'banks'].includes(activeTab.value) && {
      key: 'request_date',
      format: Format.date,
    },
    ['fns', 'banks'].includes(activeTab.value) && {
      key: 'response_date',
      format: Format.date,
    },
    ['fns'].includes(activeTab.value) && { key: 'tax_inspector_full_name' },
    ['fns', 'banks'].includes(activeTab.value) && { key: 'status_current' },
    ['fns', 'banks'].includes(activeTab.value) && { key: 'status_history' },

    ['banks'].includes(activeTab.value) && { key: 'bank_name' },

    ['executions'].includes(activeTab.value) && {
      key: 'number',
      label: 'Серия и номер',
    },
    ['executions'].includes(activeTab.value) && {
      key: 'date',
      label: 'Дата',
      format: Format.date,
    },
    ['executions'].includes(activeTab.value) && {
      key: 'initiation_date',
      format: Format.date,
    },
    ['executions'].includes(activeTab.value) && {
      key: 'end_date',
      format: Format.date,
    },
    ['executions'].includes(activeTab.value) && { key: 'termination_ground' },
    ['executions'].includes(activeTab.value) && { key: 'bailiff_full_name' },
    ['executions'].includes(activeTab.value) && { key: 'bailiff_email' },
    ['pochta'].includes(activeTab.value) && { key: 'number' },
    ['pochta'].includes(activeTab.value) && { key: 'stage_name' },
    ['pochta'].includes(activeTab.value) && { key: 'pochta_type' },
    ['pochta'].includes(activeTab.value) && {
      key: 'created_at',
      format: Format.dateTime,
    },
    ['fssp'].includes(activeTab.value) && {
      key: 'received_at',
      format: Format.dateTime,
    },
    ['pochta'].includes(activeTab.value) && {
      key: 'status',
      field: 'history.0.status.operation_name',
    },
    ['pochta'].includes(activeTab.value) && {
      key: 'place',
      field: 'history.0.place',
    },
    ['pochta'].includes(activeTab.value) && {
      key: 'weight',
      field: 'weight',
    },
    activeTab.value === 'agreements' && {
      key: 'agreement_created_at',
      field: 'created_at',
      format: Format.date,
    },
    activeTab.value === 'agreements' && {
      key: 'valid_from',
      field: 'valid_from',
      format: Format.date,
    },
    activeTab.value === 'agreements' && {
      key: 'valid_until',
      field: 'valid_until',
      format: Format.date,
    },
  ] as Array<ActiveTableColumn<any> | boolean>)
    .filter(Boolean) as Array<ActiveTableColumn<any>>)
    .map(getMapper(t));

  return computed(() => {
    const currentTab: ActiveTabKey = activeTab.value;
    let columns;
    // eslint-disable-next-line default-case
    switch (currentTab) {
      case 'housebook': columns = getHousebookColumns(); break;
      case 'fee': columns = getFeeColumns(debtor); break;
      case 'claims': columns = getClaimsColumns(); break;
      case 'egrn_data': columns = getEgrnColumns(); break;
      case 'egrn_rights': columns = getEgrnColumns(); break;
      case 'judgments': columns = getJudgementsColumns(); break;
      case 'sms': columns = getSmsAndVoiceColumns(currentTab); break;
      case 'voice': columns = getSmsAndVoiceColumns(currentTab); break;
      case 'protocol_ooc': columns = getOssColumns(); break;
      case 'identity': columns = getIdentityColumns(); break;
      case 'inn_identity': columns = getIdentityColumns(); break;
      case 'bc_identity': columns = getIdentityColumns(); break;
    }
    if (columns) {
      return columns.map(getMapper(t));
    }
    return otherTabsColumns();
  });
}

function getMapper(t: (s: string) => string) {
  return (column: ActiveTableColumn<any>) => ({
    ...column,
    field: column.field || column.key,
    label: column.label ?? t(`column.${String(column.key).split('.').pop()}`),
  });
}

function getTenantFio(debtor: Debtor, payment: FeePayment) {
  if (!payment.debtor_tenant) {
    return undefined;
  }
  const tenant = debtor.debtor_tenant_profiles
    .find((item) => item.id === payment.debtor_tenant);
  return tenant?.full_name;
}
