import { IToastProgressbar, useToast } from '@/hooks/useToast';
import { clearGosuslugiPhone, isAtLeastPartialPhoneDirty } from '@/utils/string';
import { useCompanySettingsSecure } from '@/hooks/useCompanySettingsSecure';
import { useUser } from '@/hooks/useUser';
import { Ref, ref } from 'vue';
import { useLocalI18n } from '@/hooks/useLocalI18n';

import {
  ExtraInputModel,
  Model,
  CompanyOptions,
  IntergrationOptions,
} from '@/pages/exchange/integration/dialogs/integrationGosuslugi/types';

import { useAuthFetch } from '@/pages/exchange/integration/dialogs/integrationGosuslugi/useAuthFetch';
import { useEsiaSocket } from '@/pages/exchange/integration/dialogs/integrationGosuslugi/useEsiaSocket';

export function useOnSave(
  model: Ref<Model>,
  intergrationOptions: Ref<IntergrationOptions>,
  extraInputModel: Ref<ExtraInputModel>,
  companyId: Ref<number>,
  isLoading: Ref<boolean>,
  isAuthFinalStep: Ref<boolean>,
  activeCompany: Ref<string>,
  roleOptions: Ref<CompanyOptions>,
  esiaAuthed: Ref<boolean>,
  esiaId: Ref<string>,
) {
  const { t } = useLocalI18n('exchange.integration.gosuslugi');
  const { showDangerToast } = useToast();
  const { user } = useUser();
  const {
    sendEsiaGeps,
    saveEsiaUserSetting,
  } = useCompanySettingsSecure();

  const { deleteUserAuthData, fetchAuth, fetchRoles } = useAuthFetch(
    companyId,
    user,
    esiaAuthed,
    esiaId,
    activeCompany,
    roleOptions,
    isLoading,
  );

  const progressbars = ref<[IToastProgressbar]>([
    {
      key: 'progress',
      label: '',
      message: 'Прогресс',
      showMessageInsteadOfLabel: true,
      max: 0,
      current: 0,
    },
  ]);

  const { waitEsiaAuth, authTimer } = useEsiaSocket(
    progressbars,
    isLoading,
    extraInputModel,
  );

  const onSave = async () => {
    if (!model.value.username || !model.value.password) {
      await showDangerToast({
        message: t('actions.send.toast.validate'),
      });
      return;
    }
    isLoading.value = true;
    const { status, response } = await saveEsiaUserSetting({
      company_id: companyId.value,
      owner: user.value?.id as number,
      model: {
        username: (() => {
          const { username } = model.value;
          return isAtLeastPartialPhoneDirty(username)
            ? clearGosuslugiPhone(username)
            : username;
        })(),
        password: model.value.password,
      },
    });
    if (!status) {
      isLoading.value = false;
      await showDangerToast({
        message: t('actions.send.toast.error'),
      });
      return;
    }
    const success = await waitEsiaAuth(response.id);
    if (!success) {
      await deleteUserAuthData();
      isLoading.value = false;
      return;
    }
    await fetchAuth(); // may be better
    await fetchRoles();

    if (intergrationOptions.value.gosuslugi_geps && activeCompany.value) {
      const { status } = await sendEsiaGeps({
        company_id: companyId.value,
        owner: user.value?.id as number,
        esia_id: esiaId.value,
        rolename: activeCompany.value,
      });

      if (!status) {
        isLoading.value = false;

        await showDangerToast({
          message: t('actions.send.toast.error_geps'),
        });

        return;
      }
    }
    isLoading.value = false;
  };

  return { onSave, authTimer };
}
