import { RouteRecordRaw } from 'vue-router';

export enum ImportTypeKey {
  instruction = 'instruction',
  pretrial = 'pretrial',
  executive = 'executive',
  judicial = 'judicial',
  paymentOrder = 'paymentOrder',
  judgment = 'judgment', // судебный приказ
  housebook = 'housebook',
  oosProtocol = 'oosProtocol',
  photos = 'photos',
  loanAgreement = 'loanAgreement',
  contract = 'contract',
  egrn = 'egrn',
  pochta = 'pochta',
  invoice = 'invoice',
  returnGP = 'returnGP',
}

export type DebtorsDataTypeKey =
  ImportTypeKey.pretrial
  | ImportTypeKey.judicial
  | ImportTypeKey.executive;

export type DataType<T> = {
  key: string & T;
  label: string;
  to: Partial<RouteRecordRaw>;
}

export const isDirectProductionImport = (importType: ImportTypeKey | string) => [
  ImportTypeKey.pretrial.toString(),
  ImportTypeKey.judicial.toString(),
  ImportTypeKey.executive.toString(),
].includes(importType.toString());
