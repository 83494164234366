import { Module } from 'vuex';
import { markRaw } from 'vue';
import { StoreState } from '@/store';
import {
  formatListingRequest,
  formatListingResponse,
  getDefaultListingRequestSource,
} from '@/components/activeTable/useActiveTable';
import {
  DebtorQuery,
  FetchDebtorModel,
  FetchDebtorResponse,
  FetchDebtorsResponse,
} from '@/pages/debtors/_module/useDebtorsActions';
import {
  ApiCommand,
  ApiRequest,
  ApiResponse,
  ListingRequest,
  ListingResponse,
} from '@/store/modules/api';
import {
  CreateTenantModel,
  CreateTenantResponse,
  CreateWritoeModel,
  CreateWritoeResponse,
  DebtorStatus,
  FetchDebtorStatusEntriesModel,
  FetchDebtorStatusEntriesResponse,
  FetchFsspModel,
  FetchFsspResponse,
  RefreshWritoeModel,
  RefreshWritoeResponse,
  RemoveTenantModel,
  RemoveTenantResponse,
  UpdateDebtorMainProfileModel,
  UpdateDebtorsStatusesEntriesModel,
  UpdateDebtorStatusEntriesModel,
  UpdateDefaultWritoeModel,
  UpdateInnModel,
  UpdateInnResponse,
  UpdateTenantModel,
  UpdateTenantResponse,
} from '@/hooks/useDebtors';
import { ProductionType } from '@/hooks/useConstructor';
import { Dict, DictType } from '@/hooks/useDicts';
import { parseDate } from '@/utils/dateFns';
import { Company } from '@/hooks/useCompanies';
import {
  MoveDebtorsModel,
  MoveDebtorsResponse,
  MoveDebtorsResult,
  MoveDebtorsResultStatus,
} from '@/types/debtor/move-debtors';
import { wait } from '@/utils/common';
import { getDebtorIcons } from '@/store/modules/debtors/getDebtorIcons';

export type DebtorsState = {
}

type DebtorsModule = Module<DebtorsState, StoreState>;

export const namespaced = true;

export type DebtorIcon = {
  key: string;
  icon: string;
  color: string;
  label: string;
}

export const companiesWithBilling = [
  1960,
];

export const actions: DebtorsModule['actions'] = {
  async fetchDebtors(
    { dispatch, rootGetters },
    request = getDefaultListingRequestSource<DebtorQuery>(),
  ) {
    const { status, response } = (await dispatch('api/request', {
      command: ApiCommand.fetchDebtors,
      params: formatListingRequest<ListingRequest<DebtorQuery>>(request),
      signal: request.signal,
    } as ApiRequest, { root: true })) as ApiResponse<FetchDebtorsResponse>;
    if (!status) {
      return {
        status, response,
      };
    }
    const statusesMap = rootGetters['dicts/dictsMap'].debtorStatuses;
    const substatusesMap = rootGetters['dicts/dictsMap'].debtorSubstatuses;
    const companyModuleType = rootGetters['companies/defaultCompany'].type;
    const companyId = rootGetters['companies/defaultCompany'].id;
    const productionType = request.filters.production_type as ProductionType;
    const formattedResponse = formatListingResponse(response, (d) => markRaw(d));
    const companyHasBilling = rootGetters['companies/defaultCompanyHasBilling'] || companiesWithBilling.includes(companyId);
    formattedResponse.results.forEach((debtor) => {
      try {
        const { icons, meta } = getDebtorIcons(
          debtor,
          statusesMap,
          substatusesMap,
          companyModuleType,
          productionType,
          companyHasBilling,
        );
        debtor.icons = icons;
        debtor.meta = meta;
      } catch (e) {
        console.error(e);
      }
    });
    return {
      status,
      response: formattedResponse,
    } as ApiResponse<FetchDebtorsResponse>;
  },
  async fetchDebtor({ dispatch, rootGetters }, request: FetchDebtorModel) {
    const { status, response } = (await dispatch('api/request', {
      command: ApiCommand.fetchDebtor,
      params: {
        production_type: request.productionType,
        company_id: request.companyId,
        id: request.id,
      },
      signal: request.signal,
    } as ApiRequest, { root: true })) as ApiResponse<FetchDebtorResponse>;

    return {
      status,
      response,
    } as ApiResponse<FetchDebtorResponse>;
  },
  async updateDebtorMainProfile({ dispatch, rootGetters }, request: UpdateDebtorMainProfileModel) {
    const companyModuleType = (rootGetters['companies/defaultCompany'] as Company).module;
    const { status, response } = (await dispatch('api/request', {
      command: ApiCommand.updateDebtorMainInfo,
      params: {
        module: companyModuleType,
        id: request.id,
      },
      data: {
        ...request.model,
        production_type: request.productionType,
        debtor: request.id,
      },
    } as ApiRequest, { root: true })) as ApiResponse<FetchDebtorResponse>;

    return {
      status,
      response,
    } as ApiResponse<FetchDebtorResponse>;
  },
  async updateTenant({ dispatch }, request: UpdateTenantModel) {
    const { status, response } = (await dispatch('api/request', {
      command: ApiCommand.updateTenant,
      params: {
        id: request.id,
      },
      data: {
        ...request.model,
        production_type: request.productionType,
      },
    } as ApiRequest, { root: true })) as ApiResponse<UpdateTenantResponse>;

    return {
      status,
      response,
    } as ApiResponse<UpdateTenantResponse>;
  },
  async createTenant({ dispatch }, request: CreateTenantModel) {
    const { status, response } = (await dispatch('api/request', {
      command: ApiCommand.createTenant,
      data: {
        ...request.model,
        production_type: request.productionType,
        debtor: request.debtor,
      },
    } as ApiRequest, { root: true })) as ApiResponse<CreateTenantResponse>;

    return {
      status,
      response,
    } as ApiResponse<CreateTenantResponse>;
  },
  async removeTenant({ dispatch }, request: RemoveTenantModel) {
    const { status, response } = (await dispatch('api/request', {
      command: ApiCommand.removeTenant,
      params: {
        id: request.id,
      },
    } as ApiRequest, { root: true })) as ApiResponse<RemoveTenantResponse>;

    return {
      status,
      response,
    } as ApiResponse<RemoveTenantResponse>;
  },
  async updateInn({ dispatch }, request: UpdateInnModel) {
    const { status, response } = (await dispatch('api/request', {
      command: ApiCommand.updateInn,
      data: request,
    } as ApiRequest, { root: true })) as ApiResponse<UpdateInnResponse>;

    return {
      status,
      response,
    } as ApiResponse<UpdateInnResponse>;
  },
  async createWritoe({ dispatch }, request: CreateWritoeModel) {
    const { status, response } = (await dispatch('api/request', {
      command: ApiCommand.createWritoe,
      params: { debtor_id: request.debtor_id, company_id: request.company_id },
      data: {
        case_number: request.case_number,
        serial_number: request.serial_number,
        court: request.court,
      },
    } as ApiRequest, { root: true })) as ApiResponse<CreateWritoeResponse>;

    return {
      status,
      response,
    } as ApiResponse<CreateWritoeResponse>;
  },
  async refreshWritoe({ dispatch }, request: RefreshWritoeModel) {
    const { status, response } = (await dispatch('api/request', {
      command: ApiCommand.refreshWritoe,
      params: request,
    } as ApiRequest, { root: true })) as ApiResponse<RefreshWritoeResponse>;

    return {
      status,
      response,
    } as ApiResponse<RefreshWritoeResponse>;
  },
  async fetchFssp({ dispatch }, request: FetchFsspModel) {
    const { status, response } = (await dispatch('api/request', {
      command: ApiCommand.fetchFssp,
      params: formatListingRequest(request),
    } as ApiRequest, { root: true })) as ApiResponse<FetchFsspResponse>;

    return {
      status,
      response: formatListingResponse({
        ...response,
        results: response.results.map((item: any) => ({
          ...item,
          production_date: item.production_date
            ? parseDate(item.production_date)
            : null,
          end_date: item.end_date
            ? parseDate(item.end_date)
            : null,
          docdate: item.docdate
            ? parseDate(item.docdate)
            : null,
        })),
      }),
    } as ApiResponse<FetchFsspResponse>;
  },
  async fetchDebtorStatusEntries({
    dispatch,
    rootGetters,
  }, request: FetchDebtorStatusEntriesModel) {
    const statuses = rootGetters['dicts/getDict'](DictType.debtorStatuses) as Dict<{
        value: DebtorStatus['status'];
        label: string;
      }>;
    const { status, response } = (await dispatch('api/request', {
      command: ApiCommand.fetchDebtorStatus,
      params: {
        debtor_id: request.debtorId,
      },
    } as ApiRequest, { root: true })) as ApiResponse<ListingResponse<DebtorStatus>>;

    if (status) {
      const entries = [
        ...response.results,
        ...statuses
          .filter(({ value }) => !response.results.some(({ status: s }) => s === value))
          .map(({ value }) => ({
            status: value,
            production_type: request.productionType,
            substatus: [],
            updated_at: (new Date()).toISOString(),
          })),
      ];
      entries.sort((a, b) => (
        statuses.findIndex(({ value }) => value === a.status)
          > statuses.findIndex(({ value }) => value === b.status) ? 1 : -1
      ));

      return {
        status,
        response: {
          entries,
          activeEntryKey: response.results[0]?.status || null,
        },
      };
    }

    return {
      status,
      response,
    } as any as ApiResponse<FetchDebtorStatusEntriesResponse>;
  },
  async updateDebtorStatusEntries({
    dispatch,
    rootGetters,
  }, request: UpdateDebtorStatusEntriesModel) {
    const responses: Array<{status: boolean}> = await Promise.all(
      (request.activeEntryKey === 'other' ? (
        request.entries.find(({ status }) => status === request.activeEntryKey)?.substatus
          .filter(({ updated_at }) => !updated_at)
          .map(({ substatus, data }) => ({
            new_substatus: substatus,
            new_substatus_label: data,
          })) || []
      ) as any[] : [{}])
        .map(async (payload: any) => dispatch('api/request', {
          command: ApiCommand.createDebtorStatus,
          data: {
            ...payload,
            company_id: rootGetters['companies/defaultCompanyId'],
            production_type: request.productionType,
            debtor: request.id,
            status: request.activeEntryKey,
          },
        } as ApiRequest, { root: true })),
    );

    return {
      status: !responses.some(({ status }) => !status),
      response: null,
    };
  },
  async UpdateDebtorsStatusesEntries({
    dispatch,
    rootGetters,
  }, request: UpdateDebtorsStatusesEntriesModel) {
    const responses: Array<{status: boolean}> = await Promise.all(
      (request.activeEntryKey === 'other' ? (
        request.entries.find(({ status }) => status === request.activeEntryKey)?.substatus
          .filter(({ updated_at }) => !updated_at)
          .map(({ substatus, data }) => ({
            new_substatus: substatus,
            new_substatus_label: data,
          })) || []
      ) as any[] : [{}])
        .map(async (payload: any) => dispatch('api/request', {
          command: ApiCommand.addJudicialStatus,
          data: {
            ...payload,
            company_id: rootGetters['companies/defaultCompanyId'],
            production_type: request.productionType,
            status: request.activeEntryKey,
            filters: request.filters,
          },
        } as ApiRequest, { root: true })),
    );

    return {
      status: !responses.some(({ status }) => !status),
      response: null,
    };
  },
  async setDefaultWritoe({ dispatch }, request: UpdateDefaultWritoeModel) {
    const { status, response } = await dispatch('api/request', {
      command: ApiCommand.setDefaultWritoe,
      params: {
        company_id: request.company_id,
        id: request.id,
      },
      data: {
        default: true,
      },
    }, { root: true });

    return {
      status,
      response,
    };
  },
  async moveDebtors({ dispatch, commit }, request: MoveDebtorsModel): Promise<MoveDebtorsResult> {
    const getResponseErrorMessage = (errorResponse: any): string|undefined => {
      if (!errorResponse) return undefined;
      if (Array.isArray(errorResponse)) {
        return errorResponse.join('\n');
      }
      return errorResponse.toString();
    };

    const { company_id, ...data } = request;
    const { status, response } = await dispatch('api/request', {
      command: ApiCommand.moveDebtors,
      params: {
        company_id,
      },
      data,
    }, { root: true }) as ApiResponse<MoveDebtorsResponse>;

    if (!status) {
      return {
        status: MoveDebtorsResultStatus.error,
        message: getResponseErrorMessage(response as unknown),
      };
    }
    if (!response.movable_ids.length) {
      return {
        status: MoveDebtorsResultStatus.totalConflict,
        conflictingDebtorsIds: response.conflicting_ids,
      };
    }

    // задержка из-за лага бека
    await wait(2000);

    const { status: finalStatus, response: finalResponse } = await dispatch('api/request', {
      command: ApiCommand.confirmMoveDebtors,
      params: {
        move_id: response.id,
        company_id,
      },
      data: {
        to: data.to,
        filters: {},
        ...(request.config ? { config: request.config } : {}),
      },
    }, { root: true }) as ApiResponse<MoveDebtorsResponse>;

    if (!finalStatus) {
      return {
        status: MoveDebtorsResultStatus.error,
        message: getResponseErrorMessage(finalResponse as unknown),
      };
    }
    if (!finalResponse.movable_ids.length) {
      return {
        status: MoveDebtorsResultStatus.totalConflict,
        conflictingDebtorsIds: finalResponse.conflicting_ids,
      };
    }
    if (finalResponse.conflicting_ids.length) {
      return {
        status: MoveDebtorsResultStatus.conflict,
        conflictingDebtorsIds: finalResponse.conflicting_ids,
      };
    }
    return {
      status: MoveDebtorsResultStatus.success,
    };
  },
};
